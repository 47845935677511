import { useEffect, useRef, useState } from "react"
import { IconBulb } from "@tabler/icons-react"
import cn from "classnames"

import styles from "./index.module.css"

import { useAppSelector } from "@/shared/hooks"
import { useScriptEntity } from "@/entities/script"

import { ScriptChildrenElement } from "./ScriptChildrenElement"
import { HelperModal } from "./HelperModal"

const ScriptDialogWidget = () => {
  const scriptCharacter = useAppSelector((state) => state.call.forms?.forms)
  const { script, getScript, resetScript } = useScriptEntity()
  const scrollContainerRef = useRef<HTMLDivElement>(null)
  const [isHelperOpen, setIsOpen] = useState(false)

  const scrollTo = (y: number, smooth: boolean) => {
    if (!scrollContainerRef.current) return
    const scrollHeight = scrollContainerRef.current.scrollHeight
    scrollContainerRef.current.scrollTo({
      top: y === -1 ? scrollHeight : y,
      left: 0,
      ...(smooth && { behavior: "smooth" }),
    })
  }

  const timeoutScroll = (y: number, smooth: boolean) => {
    setTimeout(() => {
      scrollTo(y, smooth)
    })
  }

  useEffect(() => {
    if (scriptCharacter) {
      getScript(scriptCharacter)
    } else {
      resetScript()
      setIsOpen(false)
    }
  }, [scriptCharacter])

  if (!script || !scriptCharacter) return null
  return (
    <div className={cn(styles.Wrapper)}>
      <div
        ref={scrollContainerRef}
        className={cn(styles.Container)}>
        <ScriptChildrenElement
          scriptCharacter={scriptCharacter}
          scrollTo={timeoutScroll}
          children={script.children}
          hideAnswers={true}
          initialVariant={0}
        />
        <div
          onClick={() => setIsOpen((prev) => !prev)}
          className={cn(styles.HelperButton)}>
          <IconBulb />
        </div>
        {isHelperOpen && (
          <HelperModal
            close={() => setIsOpen(false)}
            jsxString={script.dopInformation.webValue}
          />
        )}
      </div>
    </div>
  )
}

export default ScriptDialogWidget
