import { createContext, ReactNode } from "react"

import { useModalsProvider } from "../hooks/useModalsProvider"

interface ModalsProviderProps {
  children: ReactNode
  modals: ReactNode[]
}

export const ModalsContext = createContext<any>(null)

const ModalsProvider = (props: ModalsProviderProps) => {
  const { children, modals } = props

  if (!modals.length) {
    throw new Error("Modals array must be not empty")
  }

  const initialValue = useModalsProvider()

  return (
    <ModalsContext.Provider value={initialValue}>
      {children}
      {modals.map((element, i) => {
        const Modal = () => element
        return <Modal key={i} />
      })}
    </ModalsContext.Provider>
  )
}

export default ModalsProvider
