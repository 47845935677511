export const phoneFormatter = (phone: string) => {
  if (!phone) {
    return ""
  }
  let value = phone.replace(/\D/g, "")
  let formattedInputValue = ""
  let text = ""

  if (!value) {
    return (text = "")
  }

  if (["7", "8", "9"].indexOf(value[0]) > -1) {
    // ru
    if (value[0] === "9") {
      value = "7" + value
    }
    formattedInputValue = "+7"
    if (value.length > 1) {
      formattedInputValue += " (" + value.substring(1, 4)
    }
    if (value.length >= 5) {
      formattedInputValue += ") " + value.substring(4, 7)
    }
    if (value.length >= 8) {
      formattedInputValue += "-" + value.substring(7, 9)
    }
    if (value.length >= 10) {
      formattedInputValue += "-" + value.substring(9, 11)
    }
  } else {
    if (value[0] === "9") {
      value = "7" + value
    }
    let firstSymbols = "+7"
    formattedInputValue = firstSymbols + value[0]
    if (value.length > 1) {
      formattedInputValue += " (" + value.substring(1, 4)
    }
    if (value.length >= 5) {
      formattedInputValue += ") " + value.substring(4, 7)
    }
    if (value.length >= 8) {
      formattedInputValue += "-" + value.substring(7, 9)
    }
    if (value.length >= 10) {
      formattedInputValue += "-" + value.substring(9, 11)
    }
  }
  text = formattedInputValue
  return text
}

export const removeLetters = (value: string) => {
  return value.replace(/\D/g, "")
}
