import styles from "./index.module.css";
import cn from "classnames";

interface DefaultInput {
  style?: any;
  type?: any;
  onChange?: any;
  placeholder?: any;
  title?: any;
  name?: any;
  value?: any;
  error?: any;
}

const DefaultInput = (props: DefaultInput) => {
  const { onChange, placeholder, type, name, value, style, title, error } =
    props;
  return (
    <div className={cn(styles.Wrapper, error && styles.Error)} style={style}>
      <div className={cn(styles.Placeholder)}>{title}</div>
      <input {...{ type, value, onChange, name, placeholder }} />
    </div>
  );
};

export default DefaultInput;
