import cn from "classnames"
import styles from "./index.module.css"
import { IconAlertCircle } from "@tabler/icons-react"

const LostContactRecallContent = ({ modal: { params, closeModal } }: any) => {
  const handleSubmitModal = () => {
    params?.onSubmit()
    closeModal()
  }

  const handleResetModal = () => {
    params?.onReset()
    closeModal()
  }

  const handleDeclineModal = () => {
    params?.onDecline()
    closeModal()
  }

  return (
    <div className={cn(styles.Container)}>
      <div className={cn(styles.IconContainer)}>
        <IconAlertCircle size={30} />
      </div>
      <h2>
        Вы не назначили
        <br />
        перезвон клиенту!
      </h2>
      <p>
        Если вы не назначите перезвон, то <span>больше не свяжетесь</span> с данным клиентом.
      </p>
      <div className={cn(styles.Controls)}>
        <div
          onClick={handleSubmitModal}
          className={cn(styles.Button, styles.ButtonGreen)}>
          <div>Назначить перезвон</div>
        </div>
        <div
          onClick={handleDeclineModal}
          className={cn(styles.Button, styles.ButtonRed)}>
          <div>Не связываться</div>
        </div>
      </div>
      <div
        className={cn(styles.OnReset)}
        onClick={handleResetModal}>
        Завершить и уйти на перерыв
      </div>
    </div>
  )
}

LostContactRecallContent.displayName = "LostContactRecall"

export default LostContactRecallContent
