import styles from "./index.module.css"
import { ChangeEvent, useEffect, useRef } from "react"

interface TextInputProps {
  value: string
  onChange: (text: string) => void
  disabled: boolean
  onEndEditing: Function
}

export const TextInput = (props: TextInputProps) => {
  const { value, onChange, disabled, onEndEditing } = props
  const ref = useRef<HTMLTextAreaElement>(null)

  const handleKeyPress = (event: KeyboardEvent) => {
    if (event.shiftKey && event.key === "Enter") {
      event.preventDefault()
      if (ref.current) {
        onChange(ref.current.value + "\n")
        setTimeout(() => {
          if (!ref.current) return
          ref.current.scrollTo({ top: ref.current.scrollHeight })
        })
      }
      return
    }
    if (event.key === "Enter") {
      if (ref.current) {
        ref.current.blur()
        removeKeyPressEvent()
      }
      onEndEditing()
    }
  }

  const autoHeight = (elem: any) => {
    elem.style.height = "1px"
    elem.style.height = `${elem.scrollHeight}px`
  }

  const onFocusInput = () => {
    removeKeyPressEvent()
    addKeyPressEvent()
  }

  const onBlurInput = () => {}

  const addKeyPressEvent = () => {
    document.addEventListener("keypress", handleKeyPress)
  }

  const removeKeyPressEvent = () => {
    document.removeEventListener("keypress", handleKeyPress)
  }

  const handleChangeValue = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const value = event.target.value
    onChange(value ? value[0].toUpperCase() + value.slice(1, value.length) : value)
  }

  useEffect(() => {
    return removeKeyPressEvent
  }, [])

  useEffect(() => {
    autoHeight(ref.current)
  }, [value])

  return (
    <textarea
      rows={1}
      className={styles.TextArea}
      disabled={disabled}
      ref={ref}
      placeholder="Введите сообщение"
      value={value}
      onFocus={onFocusInput}
      onBlur={onBlurInput}
      onChange={handleChangeValue}
    />
  )
}
