import styles from "./styles.module.css"
import {
  IconAdjustmentsHorizontal,
  IconArrowNarrowRight,
  IconDotsVertical,
  IconMicrophone,
  IconShieldCheckeredFilled,
} from "@tabler/icons-react"

const PermissionsAllowSteps = () => {
  return (
    <div className={styles.Steps}>
      <div className={styles.SingleStep}>
        <IconDotsVertical
          size={12}
          color={"#444"}
        />{" "}
        <div>Настройки</div>
      </div>
      <div className={styles.SingleStep}>
        <IconArrowNarrowRight
          size={14}
          color={"#444"}
        />
      </div>
      <div className={styles.SingleStep}>
        <IconShieldCheckeredFilled
          size={12}
          color={"#444"}
        />{" "}
        <div>Приватность и конфиденциальность</div>
      </div>
      <div className={styles.SingleStep}>
        <IconArrowNarrowRight
          size={14}
          color={"#444"}
        />
      </div>
      <div className={styles.SingleStep}>
        <IconAdjustmentsHorizontal
          size={12}
          color={"#444"}
        />{" "}
        <div>Настройки сайтов</div>
      </div>
      <div className={styles.SingleStep}>
        <IconArrowNarrowRight
          size={14}
          color={"#444"}
        />
      </div>
      <div className={styles.SingleStep}>
        <IconMicrophone
          size={12}
          color={"#444"}
        />{" "}
        <div>Микрофон</div>
      </div>
    </div>
  )
}

export default PermissionsAllowSteps
