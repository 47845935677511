import { useEffect, useState } from "react"
import cn from "classnames"
import styles from "./index.module.css"
import { ExceptionScripts, ScriptChildren } from "@/entities/script"

interface ScriptChildrenProps {
  initialVariant?: number
  hideAnswers?: boolean
  children: Array<ScriptChildren>
  scrollTo: (y: number, smooth: boolean) => void
  scriptCharacter: string
}

export const ScriptChildrenElement = ({
  children,
  initialVariant,
  hideAnswers,
  scrollTo,
  scriptCharacter,
}: ScriptChildrenProps) => {
  const [variant, setVariant] = useState<number | null>(initialVariant !== undefined ? initialVariant : null)

  const removeFontFamily = (text: string) => {
    //@ts-ignore
    let result = text.replaceAll("font-family: 'Times New Roman',serif;", "")
    //@ts-ignore
    result = result.replaceAll("font-family: 'Times New Roman', serif;", "")
    //@ts-ignore
    return result.replaceAll("font-family: Times;")
  }

  const handleVariant = (index: number) => {
    setVariant((prev) => (prev === index ? null : index))
    scrollTo(-1, true)
  }

  useEffect(() => {
    return () => {
      setVariant(null)
    }
  }, [])

  return (
    <div className={cn(styles.ScriptWrapper)}>
      <div className={cn(styles.AnswerRow)}>
        {!hideAnswers &&
          children.map((element, index) => {
            return (
              <div
                key={element.unique}
                onClick={() => handleVariant(index)}
                className={cn(styles.Answer, index === variant && styles.AnswerActive)}>
                {element.answer}
              </div>
            )
          })}
      </div>
      {variant !== null && children[variant]?.webValue && (
        <div
          className={cn(styles.DangerousHtml, ExceptionScripts.MeetingsOne === scriptCharacter && styles.DisableTables)}
          dangerouslySetInnerHTML={{ __html: removeFontFamily(children[variant].webValue) }}></div>
      )}
      {variant !== null && children[variant]?.children && (
        <ScriptChildrenElement
          scriptCharacter={scriptCharacter}
          scrollTo={scrollTo}
          children={children[variant]?.children}
        />
      )}
    </div>
  )
}
