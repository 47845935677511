import { useEffect, useRef } from "react"
import Cookies from "js-cookie"
import { useAppDispatch } from "@/shared/hooks"
import { handleConnection } from "@/shared/store/slices/app"

const ConnectionConfig = {
  Uri: import.meta.env.VITE_CALL_CENTER_WS,
  ReconnectionTimeout: 4000,
}

export enum SocketEvents {
  StartCall = "start_call",
  EndCall = "end_call",
}

interface CallCenterSocket {
  onEvent: (event: any) => void
}

interface CustomWindow extends Window {
  callSocketConnection: any
  callSocketStatusIsConnected: boolean
}

//@ts-ignore
const localWindow: CustomWindow = window

const localPrint = (string: string) => console.log(`%cCC SOCKET STATUS: ${string}`, "color: DeepPink")

export const useCallCenterSocket = ({ onEvent }: CallCenterSocket) => {
  const dispatch = useAppDispatch()
  const reconnectionTimeout = useRef<any | null>(null)

  const onInternetConnectionOnline = () => {
    createSocketConnection()
  }

  const onInternetConnectionOffline = () => {
    killSocketConnection()
  }

  const subscribeToInternetConnection = () => {
    window.addEventListener("online", onInternetConnectionOnline)
    window.addEventListener("offline", onInternetConnectionOffline)
  }

  const killInternetConnectionListeners = () => {
    window.removeEventListener("online", onInternetConnectionOnline)
    window.removeEventListener("offline", onInternetConnectionOffline)
  }

  const killSocketConnection = () => {
    if (localWindow.callSocketConnection) {
      localWindow.callSocketConnection.close()
    }
    dispatchSocket(null)
    localWindow.callSocketStatusIsConnected = false
  }

  const dispatchSocket = (data: any) => {
    localWindow.callSocketConnection = data
  }

  const createSocketConnection = () => {
    clearTimeout(reconnectionTimeout.current)
    reconnectionTimeout.current = null
    if (localWindow.callSocketConnection) {
      return
    }
    if (localWindow.callSocketStatusIsConnected) {
      return
    }
    localPrint("CREATE CONNECT")
    const params = {
      token: Cookies.get("access_token") || localStorage.getItem("access_token") || "",
    }
    const query = new URLSearchParams(params).toString()
    const connection = new WebSocket(ConnectionConfig.Uri + "?" + query)
    connection.onopen = () => {
      dispatch(handleConnection(true))
      localWindow.callSocketStatusIsConnected = true
      localPrint("CONNECTED")
    }

    connection.onmessage = (event: any) => {
      const data = JSON.parse(event.data)
      onEvent(data)
    }

    connection.onerror = () => {
      dispatch(handleConnection(false))
      localPrint("ERROR")
    }

    connection.onclose = () => {
      dispatch(handleConnection(false))
      killSocketConnection()
      localPrint("CLOSED")
      reconnectionTimeout.current = setTimeout(() => {
        createSocketConnection()
      }, ConnectionConfig.ReconnectionTimeout)
    }
    dispatchSocket(connection)
  }

  useEffect(() => {
    createSocketConnection()
    subscribeToInternetConnection()
    return () => {
      killInternetConnectionListeners()
      killSocketConnection()
    }
  }, [])

  return { socket: null }
}
