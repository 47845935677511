import styles from "./index.module.css"
import cn from "classnames"
import { motion } from "framer-motion"
import { LoadingSpinner } from "@/shared/ui/components/loaders"

interface SocketConnectionWidgetProps {
  show: boolean
}

const SocketConnectionWidget = ({ show }: SocketConnectionWidgetProps) => {
  return (
    <motion.div
      initial={"hide"}
      animate={show ? "show" : "hide"}
      variants={animation}
      className={cn(styles.Container)}>
      <div>
        <LoadingSpinner
          style={{ marginBottom: -5 }}
          size={30}
          strokeWidth={5}
          color={"#fff"}
        />
      </div>
      <div className={cn(styles.Message)}>
        <div>Потеряно соединение с сервером</div>
        <div>Подключение...</div>
      </div>
    </motion.div>
  )
}

const animation = {
  show: {
    opacity: 1,
    y: 0,
  },
  hide: {
    opacity: 0,
    y: -100,
  },
}

export default SocketConnectionWidget
