import { useExtensionsLogs } from "@/entities/extensionsLogs"

export const useSendCommentLogModel = (methods: {
  onStart?: () => void
  onError?: () => void
  onSuccess?: (data: any) => void
}) => {
  const { onStart, onError, onSuccess } = methods
  const { createCommentLog } = useExtensionsLogs()

  const onCreateCommentLog = async (id: number, value: string) => {
    onStart && onStart()
    try {
      const result: any = await createCommentLog({ object_id: id }, { text: value, only_for_admin: false })
      onSuccess && onSuccess(getComment({ ...result, text: value }))
    } catch (error) {
      onError && onError()
    }
  }

  return { onCreateCommentLog }
}

const getComment = ({ created_at, id, text }: any) => ({
  id,
  object_id: 1299461,
  data: {
    icon: {
      position: "LEFT",
      name: "quote",
      color: "#fff",
      backgroundColor: "#333",
    },
    card: {
      backgroundColor: "#fff",
      color: "#333",
    },
    title: {
      text: "Комментарий",
      comment: null,
      tag: null,
    },
    body: {
      text,
      comment: null,
      tag: null,
    },
    attachments: {
      img: null,
      audio: null,
      video: null,
      document: null,
      location: null,
    },
  },
  user: {
    id: 1,
    name: "zZz developer",
    email: "example@example.com",
    phone: "79615636347",
    image_url: null,
    created_at: "2023-01-13T11:44:06.179596",
  },
  type: "COMMENT",
  created_at,
})
