import cn from "classnames"
import styles from "./index.module.css"
import { motion } from "framer-motion"

interface HelperModalProps {
  jsxString: string
  close: () => void
}

export const HelperModal = ({ jsxString, close }: HelperModalProps) => {
  return (
    <div className={cn(styles.HelperModalWrapper)}>
      <div
        onClick={close}
        className={cn(styles.HelperModalBackDrop)}></div>
      <motion.div
        initial={{ y: 20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        className={cn(styles.HelperModal)}
        dangerouslySetInnerHTML={{ __html: jsxString }}></motion.div>
    </div>
  )
}
