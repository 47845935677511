import { createSlice } from "@reduxjs/toolkit"

interface InitialState {
  user: null | User
}

interface User {
  id: number
  name: string
  email: string
  phone: string
  image_url: null
  created_at: string
}

const initialState: InitialState = {
  user: null,
}

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, { payload }) => {
      state.user = payload
    },
    resetUser: (state) => {
      state.user = null
    },
  },
})

export default userSlice.reducer
export const { setUser, resetUser } = userSlice.actions
