import { RefObject, useEffect, useRef } from "react"

const initialScrollRect = {
  contentHeight: 0,
  toScrollHeight: 0,
}

export const useChatScrollHandler = (
  scrollRef: RefObject<HTMLDivElement>,
  innerRef: RefObject<HTMLDivElement>,
  onStartReached: () => void,
) => {
  const scrollContainerResizeObserver = useRef<ResizeObserver | null>(null)
  const bounding = useRef(initialScrollRect)

  const scrollTo = (y: number, smooth: boolean) => {
    if (!scrollRef.current) return
    const scrollHeight = scrollRef.current.scrollHeight
    scrollRef.current.scrollTo({
      top: y === -1 ? scrollHeight : y,
      left: 0,
      ...(smooth && { behavior: "smooth" }),
    })
  }

  const scrollContainerResizeObserverCallBack = (entries: Array<ResizeObserverEntry>) => {
    if (!innerRef.current) return
    for (let e of entries) {
      const { contentHeight } = bounding.current
      const newContentHeight = e.contentBoxSize[0].blockSize
      bounding.current.toScrollHeight = newContentHeight - contentHeight
      bounding.current.contentHeight = newContentHeight
      if (scrollRef.current?.scrollTop === 0) {
        scrollTo(bounding.current.toScrollHeight, false)
      }
      if (checkIsOnBottom(100)) {
        scrollTo(-1, true)
      }
    }
  }

  useEffect(() => {
    if (innerRef.current && !scrollContainerResizeObserver.current) {
      scrollContainerResizeObserver.current = new ResizeObserver(scrollContainerResizeObserverCallBack)
      scrollContainerResizeObserver.current.observe(innerRef.current)
    }

    return () => {
      if (scrollContainerResizeObserver.current) {
        scrollContainerResizeObserver.current.disconnect()
        scrollContainerResizeObserver.current = null
      }
    }
  }, [])

  const onScrollHandler = (event: { target: HTMLDivElement }) => {
    if (event.target.scrollTop === 0) {
      !!onStartReached && onStartReached()
    }
  }

  const checkIsOnBottom = (range: number = 10) => {
    if (!scrollRef.current) return false
    const { scrollTop, scrollHeight, offsetHeight } = scrollRef.current
    return Math.abs(scrollTop - (scrollHeight - offsetHeight)) < range
  }

  return { scrollTo, onScrollHandler }
}
