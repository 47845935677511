import styles from "./styles.module.css"
import { IconAsteriskSimple, IconMicrophone } from "@tabler/icons-react"
import { useEffect, useRef, useState } from "react"
import { ModalTemplate } from "@/shared/ui/layout"
import { PermissionsAllowSteps } from "@/widgets/PermissionsAllowStepsWidget"
import { useMediaPermissions } from "@/shared/hooks"
import { requestAudioPermissions } from "@/shared/helpers"

interface PermissionsModal {
  onSuccess?: () => void
  onDecline?: () => void
}

type LocalPermissions = {
  microphone: PermissionState | null
}

const initialPermissionsState = {
  microphone: null,
}

const Permissions = ["microphone"]

const PermissionsModal = (props: PermissionsModal) => {
  const { onSuccess, onDecline } = props
  const [isBeforeDisabled, setIsBeforeDisabled] = useState(false)
  const [permissionsStatus, setPermissionsStatus] = useState<LocalPermissions>({
    ...initialPermissionsState,
  })
  const ref = useRef<any>(null)

  const handleShowModal = () => {
    ref.current.show()
  }

  useEffect(() => {
    validatePermissions().then()
  }, [])

  useMediaPermissions(
    () => window.location.reload(),
    () => window.location.reload(),
  )

  const validatePermissions = async () => {
    let statuses = { ...initialPermissionsState }
    try {
      for (let i = 0; i < Permissions.length; i++) {
        const permission = Permissions[i]
        const { state } = await checkIsPermissionAllowed(permission)
        //@ts-ignore
        statuses[permission] = state
      }
    } catch (e) {
    } finally {
      setPermissionsStatus({ ...statuses })
    }
  }

  useEffect(() => {
    if (!!permissionsStatus.microphone) {
      if (checkStatus(permissionsStatus, "denied")) {
        setIsBeforeDisabled(true)
        handleShowModal()
      } else if (checkStatus(permissionsStatus, "prompt")) {
        setIsBeforeDisabled(false)
        handleShowModal()
        requestAudioPermissions()
          .then(() => {
            !!onSuccess && onSuccess()
          })
          .catch(() => {
            !!onDecline && onDecline()
          })
          .finally(() => {})
      } else {
        !!onSuccess && onSuccess()
      }
    }
  }, [permissionsStatus])

  const checkStatus = (permissionsStatus: LocalPermissions, status: PermissionState) => {
    return [permissionsStatus.microphone].includes(status)
  }

  const checkIsPermissionAllowed = (name: any) => {
    return navigator.permissions.query({ name: name } as any)
  }

  return (
    <ModalTemplate
      closeDisabled
      ref={ref}>
      <div className={styles.PermissionsModalContainer}>
        <div className={styles.IconWrapper}>
          <IconMicrophone
            size={36}
            color={"#777"}
          />
        </div>
        <div className={styles.ModalHeader}>
          <div className={styles.ModalTitle}>
            Чтобы начать общаться с клиентами в сервисе, вам необходимо разрешить доступ к микрофону
          </div>
          <div className={styles.ModalSubTitle}>
            {!isBeforeDisabled ? (
              <span>Разрешите доступ в левом верхнем углу окна</span>
            ) : (
              <span>
                Выполните следующие действия
                <br />
                чтобы продолжить пользование сервисом
              </span>
            )}
          </div>
        </div>
        {isBeforeDisabled && <PermissionsAllowSteps />}
        <div className={styles.Note}>
          <IconAsteriskSimple
            size={12}
            color={"#888"}
          />
          <div>После предоставления доступа страница будет перезагружена, либо перезагрузите её самостоятельно</div>
        </div>
      </div>
    </ModalTemplate>
  )
}

export default PermissionsModal
