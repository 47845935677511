import cn from "classnames"
import styles from "./index.module.css"
import { IconChevronUp } from "@tabler/icons-react"
import { motion } from "framer-motion"

interface MoreButtonProps {
  onClick: () => void
  active: boolean
  count: number
}

export const MoreButton = ({ onClick, active, count }: MoreButtonProps) => {
  return (
    <div
      onClick={onClick}
      className={cn(styles.MoreButton, active && styles.MoreButtonActive)}>
      <div className={cn(styles.Count)}>{count === 0 ? "-" : count}</div>
      <span>Расширенное</span>
      <motion.div
        variants={AnimationVariants}
        animate={active ? "active" : "hidden"}
        className={cn(styles.MoreButtonIcon)}>
        <IconChevronUp />
      </motion.div>
    </div>
  )
}

const AnimationVariants = {
  active: {
    rotateZ: 0,
  },
  hidden: {
    rotateZ: 180,
  },
}
