export enum ERequestKind {
  BAD = "BAD",
  SUCCESS = "SUCCESS",
  INVALID = "INVALID",
}
export interface ERequestData<T> {
  kind: ERequestKind
  data: T
}

export enum ERequestMethods {
  GET = "get",
  POST = "post",
  DELETE = "delete",
  PUT = "put",
  PATCH = "patch",
}

const errorService = (status: any, message: any): { kind: ERequestKind; data: any } => {
  if (status >= 500 || status === 0) {
    console.error({ status, message })
  }
  return responseService(ERequestKind.BAD, { error: message, status: status })
}

const responseService = <T>(kind: ERequestKind, data: T) => ({ kind, data })

export { errorService, responseService }

export interface ApiErrorInterface {
  kind: ERequestKind
  data: {
    error: {
      detail: string
    }
  }
}

export interface IApiErrorService {
  error: any
  status: any
}

export function serializeQueryParams(params: any) {
  const keys = Object.keys(params)
  let options = ""

  keys.forEach((key) => {
    const isParamTypeObject = typeof params[key] === "object"
    const isParamTypeArray = isParamTypeObject && params[key].length >= 0

    if (!isParamTypeObject) {
      options += `${key}=${params[key]}&`
    }

    if (isParamTypeObject && isParamTypeArray) {
      params[key].forEach((element: any) => {
        options += `${key}=${element}&`
      })
    }
  })

  return options ? options.slice(0, -1) : options
}
