import styles from "./index.module.css"
import { useEffect, useRef, useState } from "react"
import cn from "classnames"
import { IconClock } from "@tabler/icons-react"
import { getMMSS } from "@/shared/helpers"
import { motion } from "framer-motion"
import { CallSystemStatuses } from "@/shared/api"
import { useAppSelector } from "@/shared/hooks"

interface TimerWidgetProps {
  status: CallSystemStatuses
}

const TimerWidget = ({ status }: TimerWidgetProps) => {
  const restartWaitingStatusTimeout = useAppSelector((state) => state.call.restartWaitingStatusTimeout)
  const timer = useRef<ReturnType<typeof setInterval> | null>(null)
  const [milliseconds, setMilliseconds] = useState(0)
  const localMilliseconds = useRef<number>(0)

  const widgetWorkStatus = status === CallSystemStatuses.FillingForms

  const startTimer = (milliseconds: number) => {
    setMilliseconds(milliseconds)
    killTimer()
    localMilliseconds.current = milliseconds
    timer.current = setInterval(() => {
      localMilliseconds.current -= 1000
      const newValue = localMilliseconds.current
      if (newValue < 0) {
        killTimer()
      } else {
        setMilliseconds(newValue)
      }
    }, 1000)
  }

  const killTimer = () => {
    if (timer.current) {
      localMilliseconds.current = 0
      clearInterval(timer.current)
      timer.current = null
    }
  }

  useEffect(() => {
    if (widgetWorkStatus) {
      startTimer(restartWaitingStatusTimeout)
    } else {
      killTimer()
    }
  }, [widgetWorkStatus])

  if (!widgetWorkStatus) return null
  return (
    <motion.div
      animate={{
        opacity: 0.3,
      }}
      initial={{
        opacity: 1,
      }}
      exit={{
        opacity: 1,
      }}
      transition={{ repeat: Infinity, duration: 0.8, repeatType: "mirror" }}
      className={cn(styles.Container)}>
      <div className={cn(styles.Icon)}>
        <IconClock />
      </div>
      <div className={cn(styles.Numbers)}>{getMMSS(milliseconds / 1000)}</div>
    </motion.div>
  )
}

export default TimerWidget
