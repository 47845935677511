import { useState } from "react"
import styles from "./index.module.css"
import { useAuth } from "@/entities/auth"
import { toast } from "react-toastify"
import { motion } from "framer-motion"
import cn from "classnames"
import { AppLogo } from "@/shared/ui/layout"
import { DefaultInput } from "@/shared/ui/components/inputs"

const AuthPage = () => {
  const { signIn } = useAuth()
  const [data, setData] = useState({
    email: "",
    password: "",
  })
  const [error, setError] = useState(false)

  const handleOnChange = (key: any, value: string) => {
    setError(false)
    setData((prev) => {
      return {
        ...prev,
        [key]: value,
      }
    })
  }

  const handleOnSubmit = (event: any) => {
    if (!data.email || !data.password) {
      toast.warning("Укажите данные для входа", {
        type: "error",
      })
      return
    }
    event.preventDefault()
    signIn(data)
      .then(() => {
        window.location.reload()
      })
      .catch(() =>
        toast.error("Неправильно введены данные", {
          icon: false,
        }),
      )
  }

  return (
    <motion.div
      initial={{ opacity: 0, y: -10 }}
      animate={{ opacity: 1, y: 0 }}
      className={cn(styles.Wrapper)}>
      <div>
        <AppLogo />
        <h2>С возвращением!</h2>
        <h5>Добро пожаловать! Введите данные для входа</h5>
        <div className={cn(styles.Form)}>
          <DefaultInput
            error={error}
            title={"Email"}
            style={{ marginBottom: 10 }}
            placeholder={"example@mail.com"}
            value={data.email}
            onChange={(event: any) => handleOnChange("email", event.target.value)}
          />
          <DefaultInput
            type={"password"}
            error={error}
            title={"Password"}
            placeholder={"Enter your password"}
            value={data.password}
            onChange={(event: any) => handleOnChange("password", event.target.value)}
          />
        </div>

        <button
          type={"button"}
          style={{ marginTop: 10 }}
          disabled={!data.email || !data.password}
          className={cn(styles.ButtonSubmit)}
          onClick={handleOnSubmit}>
          Войти с помощью email
        </button>
        <div className={cn(styles.HaveNoAccount)}>Нет акккаунта? Свяжитесь с руководителем.</div>
      </div>
    </motion.div>
  )
}

export default AuthPage
