import type { ReactElement } from "react"
import { cloneElement, useMemo } from "react"

import styles from "../styles/index.module.css"
import { motion } from "framer-motion"
import { IconX } from "@tabler/icons-react"

interface ModalDefaultProps {
  children: ReactElement
  params: object | null
  isOpen: boolean
  hasBackDrop?: boolean
  closeModal: () => void
  onModalHide: () => void
  enableClose?: boolean
  changeModal: (displayName: string, params?: object) => void
}

export const ModalDefault = (props: ModalDefaultProps) => {
  const { children, params, isOpen, enableClose, closeModal, onModalHide, changeModal } = props
  const ChildComponent = useMemo(() => {
    const modal = {
      params,
      closeModal,
      changeModal,
    }
    return () => cloneElement(children, { modal })
  }, [params])

  if (!isOpen) return null
  return (
    <div className={styles.Container}>
      <motion.div
        variants={ModalVariants}
        initial={"hide"}
        animate={isOpen ? "show" : "hide"}
        className={styles.Modal}>
        {enableClose && (
          <div
            className={styles.CloseButton}
            onClick={onModalHide}>
            <IconX />
          </div>
        )}
        <ChildComponent />
      </motion.div>
    </div>
  )
}

const ModalVariants = {
  show: {
    y: 0,
    opacity: 1,
  },
  hide: {
    y: 10,
    opacity: 0,
  },
}
