import { useEffect, useRef } from "react"
import { motion } from "framer-motion"
import cn from "classnames"

import styles from "./index.module.css"

import { CallSystemStatuses } from "@/shared/api"
import { useAppSelector } from "@/shared/hooks"

import { ChangeUserCallStatus, useCallSystemModel } from "@/entities/call"

import { ChangeEdgesVolumeWidget } from "@/widgets/ChangeEdgesVolumeWidget"
import { SendToRecallWidget } from "@/widgets/SendToRecallWidget"

import { CallStatusNode } from "./CallStatusNode"
import { CurrentUserInfo } from "./CurrentUserInfo"
import { ButtonsStatusDepended } from "./ButtonsStatusDepended"
import CallStopWatch from "../../CallStopWatch/ui/CallStopWatch.tsx"
import { TimerWidget } from "@/widgets/TimerWidget"
import * as Sentry from "@sentry/react"

interface CallWidget {
  onHeightChanged: (value: number) => void
}

const CallWidget = ({ onHeightChanged }: CallWidget) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const containerResizeObserver = useRef<ResizeObserver | null>(null)
  const formFillingRef = useRef<any>(null)
  const enabledStatus = useAppSelector((state) => state.call.sendToRecallEnabledStatuses)

  const validateIsContactWasSentToRecall = () => {
    if (!formFillingRef.current) return false
    return formFillingRef.current.isRecallWasSent()
  }

  const {
    status,
    loading,
    changeStatus,
    setInitialAudioVolumes,
    playAudioElementSound,
    getAudioElementsVolumes,
    endCall,
    confirmFormFilling,
    // --------------------------------
  } = useCallSystemModel({
    recallCreated: validateIsContactWasSentToRecall,
  })

  const containerResizeObserverCallBack = () => {
    if (!containerRef.current) return
    onHeightChanged(containerRef.current.getBoundingClientRect().height)
  }

  useEffect(() => {
    if (containerRef.current && !containerResizeObserver.current) {
      containerResizeObserver.current = new ResizeObserver(containerResizeObserverCallBack)
      containerResizeObserver.current.observe(containerRef.current)
    }

    return () => {
      if (containerResizeObserver.current) {
        containerResizeObserver.current.disconnect()
        containerResizeObserver.current = null
      }
    }
  }, [])

  const onClientIncome = () => {
    try {
      containerResizeObserverCallBack()
    } catch (e) {
      Sentry.captureException(JSON.stringify(e))
    }
  }

  return (
    <>
      <motion.div
        initial={{ opacity: 0, y: -10 }}
        animate={{ opacity: 1, y: 0 }}
        ref={containerRef}
        className={cn(styles.Container, status === CallSystemStatuses.Waiting && styles.WaitingStatusAnimation)}>
        <div className={cn(styles.Header, loading && styles.Disabled)}>
          <ChangeUserCallStatus
            loading={loading}
            status={status}
            disabled={CallSystemStatuses.CallInProcess === status}
            options={[CallSystemStatuses.Waiting, CallSystemStatuses.NotHere, CallSystemStatuses.Education]}
            onChangeStatus={changeStatus}
          />
          <TimerWidget status={status} />
        </div>
        <CallStopWatch status={status} />
        <div className={cn(styles.Content, loading && styles.Disabled)}>
          <CallStatusNode
            onClientIncome={onClientIncome}
            status={status}
          />
          <ButtonsStatusDepended
            onEndCallClick={endCall}
            onEndFormFillingClick={confirmFormFilling}
            status={status}
            loading={loading}
          />
        </div>
        <SendToRecallWidget
          enabled={enabledStatus.includes(status)}
          ref={formFillingRef}
        />
        <div className={cn(styles.Controls, loading && styles.Disabled)}>
          <CurrentUserInfo />
          <div>
            <ChangeEdgesVolumeWidget
              {...{
                playAudioBell: playAudioElementSound,
                changeInitialAudioVolumes: setInitialAudioVolumes,
                getVolumes: getAudioElementsVolumes,
              }}
            />
          </div>
        </div>
      </motion.div>
    </>
  )
}

export default CallWidget
