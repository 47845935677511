import { AudioBells } from "@/entities/call"
import { generateAudioHtml, GenerateAudioOptions } from "@/shared/helpers"
import { useEffect, useRef } from "react"

const START_CALL_SESSION_BELL_SRC = "https://cdn.ugravity.org/hh-resumes/0000135.mp3"
const END_CALL_SESSION_BELL_SRC = "https://cdn.ugravity.org/hh-resumes/02442.mp3"
const CALL_IN_PROCESS_BELL_SRC = "https://cdn.ugravity.org/hh-resumes/request-call-in-process-s.mp3"

interface SessionAudioBells {
  [AudioBells.Start]: null | HTMLAudioElement
  [AudioBells.End]: null | HTMLAudioElement
  [AudioBells.CallRequest]: null | HTMLAudioElement
}

const initialSessionAudioBells = {
  [AudioBells.Start]: null,
  [AudioBells.End]: null,
  [AudioBells.CallRequest]: null,
}

const initialAudioVolumes = {
  [AudioBells.Start]: 0.05,
  [AudioBells.End]: 0.1,
  [AudioBells.CallRequest]: 0.1,
  ["Stream"]: 1,
}

export const useWebRTCAudioElements = () => {
  const bellsTimeout = useRef<any>(null)
  const sessionAudioBells = useRef<SessionAudioBells>(initialSessionAudioBells)
  const callSessionAudioElement = useRef<null | HTMLAudioElement>(null)

  const validateBells = () => {
    return !(
      !sessionAudioBells.current[AudioBells.Start] ||
      !sessionAudioBells.current[AudioBells.End] ||
      !!sessionAudioBells.current[AudioBells.CallRequest]
    )
  }

  const _audioStorageKey = (key: string) => `audio_element_${key.toLowerCase()}_volume`

  const _initializeBellsAudioElements = () => {
    const sources = {
      [AudioBells.Start]: START_CALL_SESSION_BELL_SRC,
      [AudioBells.End]: END_CALL_SESSION_BELL_SRC,
      [AudioBells.CallRequest]: CALL_IN_PROCESS_BELL_SRC,
    }
    const options: GenerateAudioOptions = {
      autoplay: false,
      muted: false,
    }
    Object.keys(sources).map((key: any) => {
      const volume = localStorage.getItem(_audioStorageKey(key))
      //@ts-ignore
      sessionAudioBells.current[key] = generateAudioHtml({
        ...options,
        volume: volume ? parseFloat(volume) : 1,
        ...(key === AudioBells.CallRequest && { loop: true }),
        //@ts-ignore
        src: sources[key],
      })
    })
  }

  const _playAudioBell = async (direction: AudioBells) => {
    if (!validateBells) return null
    clearTimeout(bellsTimeout.current)
    bellsTimeout.current = null

    // kill another bells
    Object.keys(initialSessionAudioBells).forEach((bell_key) => {
      if (bell_key === direction) return
      _killAudioBell(bell_key as AudioBells)
    })

    // @ts-ignore
    await sessionAudioBells.current[direction]?.play()
    if (direction === AudioBells.CallRequest) return
    bellsTimeout.current = setTimeout(() => {
      _killAudioBell(direction)
    }, 1000)
  }

  const _killAudioBell = (direction: AudioBells) => {
    if (!validateBells) return null
    sessionAudioBells.current[direction]?.pause()
    //@ts-ignore
    sessionAudioBells.current[direction].currentTime = 0.1
  }

  const _storeInitialAudioVolumes = () => {
    Object.keys(initialAudioVolumes).forEach((key: string) => {
      const value = localStorage.getItem(_audioStorageKey(key))
      // @ts-ignore
      localStorage.setItem(_audioStorageKey(key), value || initialAudioVolumes[key])
    })
  }

  const _changeInitialAudioVolumes = (direction: AudioBells | "Stream", value: number) => {
    localStorage.setItem(_audioStorageKey(direction), value.toString())
    //@ts-ignore
    sessionAudioBells.current[direction].volume = value
  }

  const _getVolumes = (range: Array<number>) => {
    const values: any = {}
    Object.keys(initialAudioVolumes).forEach((key) => {
      const storedValue = localStorage.getItem(_audioStorageKey(key))
      //@ts-ignore
      const volume = storedValue ? parseFloat(storedValue) : initialAudioVolumes[key]

      values[key] = (volume * 100) / (100 / range[1])
    })
    return values
  }

  /**
   SESSION AUDIO
   */
  const _initializeSessionAudioElement = () => {
    const options: GenerateAudioOptions = {
      srcObject: null,
      autoplay: true,
      muted: false,
      volume: 1,
    }
    callSessionAudioElement.current = generateAudioHtml(options)
  }

  const _setSessionAudioElementStream = (stream: any) => {
    if (!callSessionAudioElement.current) {
      return null
    }
    callSessionAudioElement.current.srcObject = stream
  }

  useEffect(() => {
    _storeInitialAudioVolumes()
    _initializeSessionAudioElement()
    _initializeBellsAudioElements()
  }, [])

  return {
    setSessionAudioStream: _setSessionAudioElementStream,
    setInitialAudioVolumes: _changeInitialAudioVolumes,
    getAudioElementsVolumes: _getVolumes,
    playSound: _playAudioBell,
    stopSound: _killAudioBell,
  }
}
