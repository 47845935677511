/**
 * @route GET /api/dev/v2/campaign_id={campaign_id}/object
 * */
export enum FieldType {
  STRING = "STRING",
}
export enum EditWidgetType {
  INPUT = "INPUT",
  PRICE = "PRICE",
  DROPDOWN = "DROPDOWN",
  CHECKBOX = "CHECKBOX",
  TAG = "TAG",
  DATE = "DATE",
  PHONE = "PHONE",
}
export enum ViewWidgetType {
  INPUT = "INPUT",
  PRICE = "PRICE",
}
export enum ExtensionsType {
  STAGE = "STAGE",
}
export interface Choice {
  id: number
  field_id: number
  meta_name: string | null
  order: number
  name: string
  icon: string | null
  color: string | null
}
export interface ObjectGroupField {
  id: number
  schema_id: number
  choice: Choice[]
  name: string
  type: FieldType
  default: string
  values_amount: number
  required: boolean
  read_only: boolean
  show: boolean
  info: string
  error?: boolean
  placeholder: string
  icon: string | null
  edit_widget: EditWidgetType
  view_widget: ViewWidgetType
  extensions_type: ExtensionsType | null
  meta_name: string
  values: string[]
  color: string
}
export interface ObjectGroup {
  schema_id: number | null
  icon: string | null
  name: string
  position: string
  fields: ObjectGroupField[]
}
export interface GetObjectParams {
  id: number
}

export interface GetSchemaParams {
  schema_id: number
}

export interface GetRelatedObjectsParams {
  schema_id: number
  view_type: string
  page: number
  limit: number
  is_desc: boolean
}

export interface RelatedObject {
  id: number
  schema_id: number | null
  users: {
    owners: number[]
  }
  fields: Array<Array<ObjectGroupField>>
  extensions: {
    STAGE: {
      __stage__: ObjectGroupField[]
      __approve__: ObjectGroupField[]
    }
    MESSAGE: {
      __teletype_phone_wa__: ObjectGroupField[]
      __pact_message_count_wa__: ObjectGroupField[]
    }
    TELEPHONY: {
      __telephony_phone__: ObjectGroupField[]
    }
  }
}

export interface GetObjectData {
  id: number
  schema_id: number
  exists: boolean
  show_schema_id: boolean
  groups: ObjectGroup[]
  nested_objects: NestedObject[]
  main_element: {
    title: ObjectGroupField
    description: ObjectGroupField
  }
  users: object
  extensions: object
}

type SchemaMetaNames = "__client__" | "__fixation__" | "__task__"

export interface NestedObject {
  schema_id: number
  icon: string | null
  name: string
  meta_name: SchemaMetaNames
  position: string
  fields: null
}

/**
 * @route PUT /api/dev/v2/campaign_id={campaign_id}/object
 * */
export interface UpdateObjectParams {
  id: number
}
export interface UpdateObjectBody {
  data: { field_id: number; values: string[] }[]
}

export interface Schema {
  fields: Array<ObjectGroupField>
  id: 3
  name: string
  icon: string
  path: string
  color: string
  campaign_id: number
  meta_name: SchemaMetaNames
}

export interface CreateObjectData {
  data: Array<{ field_id: number; values: Array<string> }>
}
