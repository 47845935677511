import { ReactNode } from "react"
import cn from "classnames"
import styles from "./index.module.css"

interface ControlButton {
  icon: ReactNode
  onClick?: () => void
  disabled?: boolean
}

export const IconButton = ({ icon, onClick, disabled }: ControlButton) => {
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      className={cn(styles.Button)}>
      {icon}
    </button>
  )
}
