import {
  GetExtensionsLogsData,
  GetMessagesUsedCursorData,
  GetMessagesUsedCursorParams,
  MESSAGE_TYPES,
  MessagesDirection,
  MessageTypesEnum,
} from "@/shared/api/model/extensionsLogs/types.ts"
import { RefObject, useEffect, useRef, useState } from "react"
import { useExtensionsLogs } from "@/entities/extensionsLogs"
import { toast } from "react-toastify"

const SINGLE_ELEMENT_ARITHMETIC_MEAN_HEIGHT = 60
const LONG_PULLING_INTERVAL = 5000

const initialCreatedAt = {
  prev: "",
  next: "",
}

const initialMessageTypes: Array<MessageTypesEnum> = [
  MESSAGE_TYPES.COMMENT,
  MESSAGE_TYPES.TELEPHONY,
  MESSAGE_TYPES.MESSAGE,
]

declare global {
  interface Window {
    logs_timeout_cursor_worker: any
  }
}

window.logs_timeout_cursor_worker = null

export const useMessengerFetcher = (objectId: number, scrollRef: RefObject<HTMLDivElement>) => {
  const { getMessagesCursorUsed } = useExtensionsLogs()
  const firstInitialization = useRef<boolean>(false)
  const [filterMessageType, setFilterMessageType] = useState<Array<MessageTypesEnum>>(initialMessageTypes)
  const filtersCopy = useRef<Array<MessageTypesEnum>>(initialMessageTypes)
  const [messages, setMessages] = useState<Array<GetExtensionsLogsData>>([])
  const [loading, setLoading] = useState(false)
  const createdAt = useRef<typeof initialCreatedAt>(initialCreatedAt)
  const limit = useRef(0)
  const allFetched = useRef(false)

  const validateLimit = () => {
    if (!scrollRef.current) return
    const { height } = scrollRef.current.getBoundingClientRect()
    limit.current = Math.ceil(height / SINGLE_ELEMENT_ARITHMETIC_MEAN_HEIGHT) * 3
  }

  const fetchMessages = async (direction: MessagesDirection | null) => {
    if (!direction) {
      allFetched.current = false
      validateLimit()
    }
    const last_at = MessagesDirection.Up === direction ? createdAt.current.prev : createdAt.current.next
    if (direction && !last_at) {
      return
    }
    if (direction === MessagesDirection.Up || !direction) setLoading(true)
    const params: GetMessagesUsedCursorParams = {
      object_id: objectId,
      limit: limit.current,
      ...(direction && { direction }),
      ...(direction && { last_at }),
    }
    const payload: GetMessagesUsedCursorData = [{ field_id: -1, values: filtersCopy.current }]

    try {
      const data = await getMessagesCursorUsed(params, payload, undefined)
      if (data.length === 0 && MessagesDirection.Up === direction) {
        allFetched.current = true
        return
      }
      const result = [...data].reverse()
      if (!direction) {
        setCreatedAt(result)
        setMessages(result)
      } else {
        switch (direction) {
          case MessagesDirection.Up:
            setMessages((prev) => {
              const newMessagesArray = removeDuplicates([...result, ...prev])
              setCreatedAt(newMessagesArray)
              return newMessagesArray
            })
            break
          case MessagesDirection.Down:
            setMessages((prev) => {
              const newMessagesArray = removeDuplicates([...prev, ...result])
              setCreatedAt(newMessagesArray)
              return newMessagesArray
            })
            break
        }
      }
    } catch (error) {
      toast.error(JSON.stringify(error), { icon: false })
    } finally {
      if (direction === MessagesDirection.Down || !direction) {
        killTimeout()
        createTimeout()
      }
      if (direction === MessagesDirection.Up || !direction) {
        setLoading(false)
      }
    }
  }

  const killTimeout = () => {
    clearTimeout(window.logs_timeout_cursor_worker)
    window.logs_timeout_cursor_worker = null
  }

  const createTimeout = () => {
    window.logs_timeout_cursor_worker = setTimeout(() => {
      fetchMessages(MessagesDirection.Down).finally()
    }, LONG_PULLING_INTERVAL)
  }

  useEffect(() => {
    if (firstInitialization.current) {
      setMessages([])
      setCreatedAt([])
      filtersCopy.current = filterMessageType
      fetchMessages(null).finally()
    }
  }, [filterMessageType])

  useEffect(() => {
    fetchMessages(null).finally()
    firstInitialization.current = true
    return () => {
      killTimeout()
    }
  }, [objectId])

  useEffect(() => {
    return () => {
      killTimeout()
    }
  }, [])

  const setCreatedAt = (array: Array<GetExtensionsLogsData>) => {
    createdAt.current = {
      prev: array[0]?.created_at || "",
      next: array[array.length - 1]?.created_at || "",
    }
  }

  return {
    loading,
    messages,
    filter: filterMessageType,
    allFetched: allFetched.current,
    setFilter: setFilterMessageType,
    pullMessagesCollection: fetchMessages,
  }
}

const removeDuplicates = (array: Array<GetExtensionsLogsData>) => {
  return array.filter((value, index, self) => index === self.findIndex((t) => t.id === value.id))
}
