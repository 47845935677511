import { Provider } from "react-redux"
import { RouterProvider } from "react-router-dom"
import { Bounce, ToastContainer } from "react-toastify"

import { router } from "@/app/router"
import { ModalsProvider } from "@/shared/contexts"

import { store } from "@/shared/store"
import { ConfigProvider } from "antd"
import ruRU from "antd/locale/ru_RU"
import { LostContactRecallModal, NotResponseModal } from "@/pages/Common"
import { ANTD_CONFIG, TOAST_CONFIG } from "@/app/lib/configs"

const modals = [<LostContactRecallModal />, <NotResponseModal />]

const App = () => {
  return (
    <ConfigProvider
      locale={ruRU}
      theme={ANTD_CONFIG}>
      <Provider store={store}>
        <ModalsProvider modals={modals}>
          <RouterProvider router={router} />
        </ModalsProvider>
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          newestOnTop={false}
          style={TOAST_CONFIG}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss={false}
          draggable
          pauseOnHover
          theme={"light"}
          transition={Bounce}
        />
      </Provider>
    </ConfigProvider>
  )
}

export default App
