import { ApiEndpoint } from "@/shared/api/lib/api-service"
import { callApi } from "@/shared/api/lib"
import { ERequestKind, ERequestMethods } from "@/shared/api/lib/api-helpers"
import { CallConfig, CallSystemStatuses } from "@/shared/api"
import { CurrentClient } from "@/entities/call"

const loginEndpoint = new ApiEndpoint(callApi, {})

const SEND_STATUS = `/auth/status`
const ACTIVATE_CONTACT_URI = "/auth/contact-uri"
const CALL_CONFIG = "/project/lead/call"
const SEND_TO_RECALL = "/calls/recall"
const SEND_CALL_PROTOCOL = "/calls/answer-info"
const CLOSE_TASK = "/calls/close-task"
const VERIFY_ACTIVE_START_CALL = "/calls/active-start-call"

export const callCenterApi = {
  sendStatus: (params: { status: CallSystemStatuses }): Promise<{ kind: ERequestKind; data: null }> =>
    loginEndpoint.request(SEND_STATUS, null, ERequestMethods.PATCH, params, null),
  activateContactUri: (params: { contact_uri: string }): Promise<{ kind: ERequestKind; data: null }> =>
    loginEndpoint.request(ACTIVATE_CONTACT_URI, null, ERequestMethods.PATCH, params, null),
  getCallConfig: (): Promise<{ kind: ERequestKind; data: CallConfig }> =>
    loginEndpoint.request(CALL_CONFIG, null, ERequestMethods.GET, null, null),
  sendToRecall: (params: any): Promise<{ kind: ERequestKind; data: CallConfig }> =>
    loginEndpoint.request(SEND_TO_RECALL, null, ERequestMethods.POST, params, null),
  sendVerifyProtocolId: (params: any) =>
    loginEndpoint.request(SEND_CALL_PROTOCOL, null, ERequestMethods.POST, params, null),
  closeTask: (params: { call_id: number }) =>
    loginEndpoint.request(CLOSE_TASK, null, ERequestMethods.PATCH, params, null),
  verifyLostActiveCall: (): Promise<{ data: CurrentClient | null }> =>
    loginEndpoint.request(VERIFY_ACTIVE_START_CALL, null, ERequestMethods.GET, null, null),
}
