import { useRef } from "react"
import { UseModalsProviderHook } from "../types"

export const useModalsProvider = (): UseModalsProviderHook => {
  const modalsRef = useRef<any>({})

  const initializeModal = (displayName: string, ref: any) => {
    modalsRef.current[displayName] = ref
  }

  const openModal = (displayName: string, params?: object, onCloseCallback?: any) => {
    modalsRef.current[displayName].current.show(params, onCloseCallback)
  }

  const closeModal = (displayName: string) => {
    modalsRef.current[displayName].current.hide()
  }

  return { initializeModal, openModal, closeModal }
}
