import { UHomeTypes } from "@/shared/api"
import styles from "./index.module.css"
import cn from "classnames"

interface AreaAndPriceProps {
  flat: UHomeTypes.ComplexFlatPricing
}

export const AreaAndPrice = (props: AreaAndPriceProps) => {
  const { flat } = props
  if (!flat) return <td className={cn(styles.FlatPrice)}>-</td>
  return (
    <td className={cn(styles.FlatPrice)}>
      <div>
        {flat.area.join(" - ")} м<sup>2</sup>
      </div>
      <div>{flat.price.map((value) => (parseInt(value.toString()) / 1000000).toFixed(2)).join(" - ")} млн.</div>
    </td>
  )
}
