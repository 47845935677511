import { Input } from "antd"
import type { InputProps } from "antd"
import type { ChangeEvent, CSSProperties } from "react"

import styles from "./index.module.css"
import cn from "classnames"

interface TextInputProps {
  type?: InputProps["type"]
  placeholder?: string
  size?: InputProps["size"]
  value: string
  disabled?: boolean
  maxLength?: number
  onChange: (value: string) => void
  onBlur?: () => void
  style?: CSSProperties
  error?: boolean
}

const TextInput = (props: TextInputProps) => {
  const { style, type = "string", placeholder, size = "small", value, disabled, maxLength, onChange, onBlur } = props

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (maxLength === undefined || event.target.value.length <= maxLength) {
      onChange(event.target.value)
    }
  }

  return (
    <Input
      style={style}
      size={size}
      type={type}
      value={value}
      onBlur={onBlur}
      disabled={disabled}
      className={cn(styles.input)}
      placeholder={placeholder}
      onChange={handleChange}
    />
  )
}

export default TextInput
