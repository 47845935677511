import dayjs from "dayjs"
import "dayjs/locale/ru"

export const formatDate = (value: string): string => {
  const date = dayjs(value)
  const today = dayjs()
  if (today.date() === date.date() && today.year() === date.year() && today.month() === date.month()) {
    return "Сегодня"
  }
  if (today.date() === date.date() + 1 && today.year() === date.year() && today.month() === date.month()) {
    return "Вчера"
  }
  return date.locale("ru").format("dddd, DD MMMM YYYY")
}
