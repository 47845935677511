import { LogoIcon } from "./CrmLogo"
import styles from "./index.module.css"

interface CrmObjectLinkProps {
  id?: number
  target: string
  href: string
}

const CrmObjectLink = (props: CrmObjectLinkProps) => {
  const { target, id, href } = props
  return (
    <a
      href={href}
      target={target}
      className={styles.Container}>
      <LogoIcon />
      <div>{id}</div>
    </a>
  )
}

export default CrmObjectLink
