export const COLORS_META_NAMES = {
  BLUE100: "#3D75DB",
  BLUE300: "#3848F2",
  BLUE600: "#5635E8",

  VIOLET100: "#7338E8",
  VIOLET300: "#AB3AF2",
  VIOLET600: "#F23ABE",

  RED100: "#E83E3C",
  RED300: "#F25B3F",
  RED600: "#DB7144",

  YELLOW100: "#E8A738",
  YELLOW300: "#F2C13A",
  YELLOW600: "#F2E63A",

  GREEN100: "#5EE843",
  GREEN300: "#46F260",
  GREEN600: "#46F2C1",

  BLACK100: "#161717",
  BLACK300: "#707070",
  BLACK600: "#c5c5c5",

  GRAY100: "#dbdbeb",
  GRAY300: "#aeaec7",
  GRAY600: "#6e7689",
}

export const WIDGETS_DESCRIPTIONS = {
  HISTORY: {
    label: "История",
    title: "Здесь будет отображаться история сделки с клиентом.",
    func: ["Создание комментария", "Фильтр истории по типам"],
  },
  NESTED: {
    label: "Связанные объекты",
    title: "Здесь будут отображаться зависимые данные сделки (клиенты, фиксации, задачи)",
    func: ["Создание нового объекта (клиент, фиксация)", "Выбор главного контакта (нажатие на контакт)"],
  },
  LEAD: {
    label: "Сделка",
    title: "Здесь будут отображаться данные сделки. Данные можно редактировать",
    func: ["Редактирвание данных", "Фильтрация ЖК", "Ссылка на сделку в CRM системе"],
  },
}
