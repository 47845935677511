import { forwardRef, Fragment, useImperativeHandle, useRef, useState } from "react"
import { motion } from "framer-motion"
import cn from "classnames"
import styles from "./index.module.css"

import { LogCard, DateTag } from "@/entities/extensionsLogs"

import { LoadingSpinner } from "@/shared/ui/components/loaders"
import { Select } from "@/shared/ui/components/selects"
import { sameDate } from "@/shared/helpers"
import { MESSAGE_TYPES, MessagesDirection, MessagesTypesTransriptions } from "@/shared/api/model/extensionsLogs/types"

import type { ReturnMethodsSendCommentLog } from "@/features/extensionsLogs/send-comment-log"
import { SendCommentLog } from "@/features/extensionsLogs/send-comment-log"

import { useMessengerFetcher } from "../model/messages-fetch.ts"
import { useChatScrollHandler } from "../model/chat-scroll-handler.ts"
import { TextInput } from "./TextInput"

interface LeadLogsWidgetProps {
  leadId: number
}

const LeadLogsWidget = forwardRef((props: LeadLogsWidgetProps, parentRef: any) => {
  const { leadId } = props
  const [sendNewMessageLoading, setNewMessageLoading] = useState(false)
  const scrollContainerRef = useRef<HTMLDivElement>(null)
  const chatRef = useRef<HTMLDivElement>(null)
  const sendMessageFeatureRef = useRef<ReturnMethodsSendCommentLog | null>(null)
  const inputRef = useRef<HTMLInputElement>(null)
  const [height, setHeight] = useState(400)

  const messageValueRef = useRef("")
  const [messageValue, setMessageValue] = useState("")

  const onStartReached = () => {
    if (allFetched) return
    pullMessagesCollection(MessagesDirection.Up).finally()
  }

  const { scrollTo, onScrollHandler } = useChatScrollHandler(scrollContainerRef, chatRef, onStartReached)
  const { allFetched, filter, messages, setFilter, pullMessagesCollection, loading } = useMessengerFetcher(
    leadId,
    scrollContainerRef,
  )

  useImperativeHandle(
    parentRef,
    () => ({
      calculateHeight: (value: number) => {
        const newHeight = window.innerHeight - value - 133
        setHeight(newHeight)
      },
    }),
    [],
  )

  const onStartSendComment = () => {
    setNewMessageLoading(true)
    setMessageValue("")
    inputRef.current?.blur()
  }

  const onSuccessSendComment = () => {
    pullMessagesCollection(MessagesDirection.Down).finally(() => {
      setNewMessageLoading(false)
      setTimeout(() => {
        scrollTo(-1, true)
        setMessageValue("")
        inputRef.current?.focus()
      })
    })
  }

  const handleChangeValue = (value: string) => {
    messageValueRef.current = value
    setMessageValue(value)
  }

  const handleEndEditing = () => {
    if (!messageValueRef.current) return
    sendMessageFeatureRef.current?.onSendCommentLog()
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className={styles.chat}>
      <div className={cn(styles.chatHeader)}>
        <div>История</div>
        <div className={cn(styles.chatHeaderFilter)}>
          <Select
            size={"middle"}
            placeholder={"Тип лога"}
            value={filter}
            options={Object.keys(MESSAGE_TYPES).map((key) => ({
              label: (MessagesTypesTransriptions as any)[key],
              value: (MESSAGE_TYPES as any)[key],
            }))}
            onChange={setFilter}
          />
        </div>
      </div>
      {loading && <UnderHeaderLoader />}
      <motion.div
        animate={{
          height,
        }}
        ref={scrollContainerRef}
        onScroll={(event: any) => onScrollHandler(event)}
        className={styles.scrollContainer}>
        <div
          ref={chatRef}
          className={styles.inner}>
          {messages.map((log, index) => (
            <Fragment key={log.id}>
              {index === 0 && <DateTag createdAt={log.created_at} />}
              {index > 0 && !sameDate(messages[index - 1].created_at, messages[index].created_at) && (
                <DateTag createdAt={log.created_at} />
              )}
              <LogCard logInfo={log} />
            </Fragment>
          ))}
        </div>
      </motion.div>
      <div className={cn(styles.footer, sendNewMessageLoading && styles.footerDisabled)}>
        <TextInput
          disabled={loading}
          value={messageValue}
          onEndEditing={handleEndEditing}
          onChange={handleChangeValue}
        />
        <SendCommentLog
          loading={sendNewMessageLoading}
          id={leadId}
          ref={sendMessageFeatureRef}
          value={messageValue}
          onStart={onStartSendComment}
          onSuccess={onSuccessSendComment}
        />
      </div>
    </motion.div>
  )
})

const UnderHeaderLoader = () => (
  <div className={cn(styles.scrollContainerLoader)}>
    <LoadingSpinner
      style={{ marginBottom: -5 }}
      size={16}
      color={"#666"}
    />
  </div>
)

export default LeadLogsWidget
