import { ApiEndpoint } from "@/shared/api/lib/api-service"
import { api, PREFIXES_WITH_CAMPAIGN } from "@/shared/api/lib"
import { ERequestData, ERequestMethods } from "@/shared/api/lib/api-helpers"
import type {
  CreateCommentLogBody,
  GetExtensionsLogsData,
  CreateCommentLogData,
  CreateCommentLogParams,
  GetMessagesUsedCursorParams,
  GetMessagesUsedCursorData,
} from "@/shared/api/model/extensionsLogs/types"

const loginEndpoint = new ApiEndpoint(api, {})

const GET_LOGS_CURSOR = `${PREFIXES_WITH_CAMPAIGN}/extensions/logs/cursor`
const CREATE_COMMENT_LOG = `${PREFIXES_WITH_CAMPAIGN}/extensions/log/create-comment`

export const extensionsLogsApi = {
  getLogsByCursor: (
    params: GetMessagesUsedCursorParams,
    data: GetMessagesUsedCursorData,
    signal?: AbortSignal,
  ): Promise<ERequestData<GetExtensionsLogsData[]>> =>
    loginEndpoint.request(GET_LOGS_CURSOR, data, ERequestMethods.POST, params, signal),
  createCommentLog: (
    params: CreateCommentLogParams,
    body: CreateCommentLogBody,
  ): Promise<ERequestData<CreateCommentLogData>> =>
    loginEndpoint.request(CREATE_COMMENT_LOG, body, ERequestMethods.POST, params, null),
}
