import cn from "classnames"
import styles from "./index.module.css"
import { UHomeTypes } from "@/shared/api"
import { getNoun } from "@/shared/helpers"

interface ComplexInfoProps {
  complex: UHomeTypes.ComplexObject | null
}

export const ComplexInfo = (props: ComplexInfoProps) => {
  const { complex } = props

  if (!complex) return null
  return (
    <div className={cn(styles.Content)}>
      <div className={styles.ComplexInfoHeader}>
        {!!complex.developer.logo_large && (
          <img
            src={complex.developer.logo_large}
            alt={complex.developer.name}
          />
        )}
        <div className={styles.ComplexInfoNames}>
          <div>{complex.developer.name}</div>
          <div>ЖК {complex.name}</div>
        </div>
      </div>
      <div className={cn(styles.CityDirection)}>
        <span>{complex.city.name}</span> • {complex.directions.map((item) => item.name).join(", ")}
      </div>
      <div className={cn(styles.FlatsCount)}>
        {complex.flats_count} {getNoun(complex.flats_count, "квартира", "квартиры", "квартир")} в продаже
      </div>
      {!!complex.metro && (
        <div className={styles.ComplexInfoMetro}>
          <div style={{ backgroundColor: `#${complex.metro_color}` }}></div>
          <span>{complex.metro}</span>
        </div>
      )}
      <div className={styles.ComplexInfoAddress}>{complex.address}</div>
      <div className={styles.ComplexInfoBuildingType}>
        {!!complex?.building_type?.length && complex.building_type.map((item, index) => (
          <div key={index}>{UHomeTypes.BuildingTranscriptions[item]}</div>
        ))}
      </div>
      <div
        className={cn(styles.ComplexInfoDescription, !complex.new_description && styles.ComplexInfoDescriptionEmpty)}>
        {complex.new_description ? complex.new_description : "Нет описания"}
      </div>
    </div>
  )
}
