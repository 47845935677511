import styles from "./index.module.css"
import { ObjectsTypes } from "@/shared/api"
import { useMemo } from "react"
import { phoneFormatter } from "@/shared/helpers"
import { IconPhone, IconWalk } from "@tabler/icons-react"
import cn from "classnames"

interface ContactItem extends ObjectsTypes.RelatedObject {
  active?: boolean
}

interface ContactObjectProps {
  onClick: () => void
  item: ContactItem
}

const ContactObject = ({ item, onClick }: ContactObjectProps) => {
  const fieldsCollection = useMemo(() => {
    return item.fields.map((row) => row[0])
  }, [item])

  const contactTitle = fieldsCollection.filter((field) => field.meta_name === "__title__")[0].values
  const contactPhone = fieldsCollection.filter((field) => field.meta_name === "__telephony_phone__")[0].values[0]
  return (
    <div
      onClick={onClick}
      className={cn(styles.Card, !!item?.active && styles.CardActive)}>
      {!!item.active && (
        <div className={styles.ActiveIcon}>
          <IconPhone />
        </div>
      )}
      <div className={styles.Row}>
        <IconWalk size={14} />
        <div className={styles.Name}>{contactTitle}</div>
      </div>
      <div className={styles.Row}>
        <div className={styles.Phone}>{contactPhone && phoneFormatter(contactPhone)}</div>
      </div>
    </div>
  )
}

export default ContactObject
