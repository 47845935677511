import { forwardRef, useImperativeHandle } from "react"
import { TablerIcon } from "@/shared/ui/components/icons"
import { useSendCommentLogModel } from "../model/useSendCommentLogModel"
import styles from "./index.module.css"
import { LoadingSpinner } from "@/shared/ui/components/loaders"

export interface ReturnMethodsSendCommentLog {
  onSendCommentLog: () => void
}

interface SendCommentLogProps {
  id: number
  value: string
  loading: boolean
  onStart?: () => void
  onSuccess?: (data: any) => void
}

const SendCommentLog = forwardRef<ReturnMethodsSendCommentLog, SendCommentLogProps>((props, parentRef) => {
  const { id, value, onStart, loading, onSuccess } = props

  const { onCreateCommentLog } = useSendCommentLogModel({ onStart, onSuccess })

  const fetchCreateCommentLog = async () => {
    try {
      if (value && !loading) {
        await onCreateCommentLog(id, value)
      }
    } catch (error) {}
  }

  useImperativeHandle(
    parentRef,
    () => ({
      onSendCommentLog: () => fetchCreateCommentLog(),
    }),
    [id, value],
  )

  return (
    <div
      className={styles.sendMessageIcon}
      onClick={fetchCreateCommentLog}>
      {loading ? (
        <div style={{ marginBottom: -4 }}>
          <LoadingSpinner
            size={18}
            color={"#888"}
          />
        </div>
      ) : (
        <TablerIcon
          name="send"
          size={20}
          color="#706F71"
        />
      )}
    </div>
  )
})

export default SendCommentLog
