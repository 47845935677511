import { configureStore } from "@reduxjs/toolkit"

import userReducer from "./slices/user/userSlice"
import objectsReducer from "./slices/objects/objectsSlice"
import callReducer from "./slices/call/callSlice"
import uhomeReducer from "./slices/uhome/uhomeSlice"
import appReducer from "./slices/app/appSlice"

export const store = configureStore({
  reducer: {
    user: userReducer,
    objects: objectsReducer,
    call: callReducer,
    uhome: uhomeReducer,
    app: appReducer,
  },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
