import { useEffect, useRef, useState } from "react"
import cn from "classnames"
import { AnimatePresence } from "framer-motion"

import styles from "./index.module.css"

import { uHomeApi, UHomeTypes } from "@/shared/api"
import { useAppSelector } from "@/shared/hooks"
import { ComplexRow } from "@/entities/uhome"
import { ComplexAdditionalInfoModal } from "@/widgets/ComplexAdditionalInfoModal"

interface ComplexSearcherWidgetProps {
  onEndLoading: () => void
  onStartLoading: () => void
}

const a101 = parseInt(import.meta.env.VITE_DEVELOPER_A101, 10)
const samolet = parseInt(import.meta.env.VITE_DEVELOPER_SAMOLET, 10)

const developers = {
  type: "developer",
  value: [samolet, a101],
}

const ComplexSearcherWidget = ({ onEndLoading, onStartLoading }: ComplexSearcherWidgetProps) => {
  const filters = useAppSelector((state) => state.uhome.filter)
  const [data, setData] = useState<UHomeTypes.ComplexObject[]>([])
  const [loading, setLoading] = useState(true)
  const moreInfoModal = useRef<any>(null)

  const checkIsDeveloperSet = () => {
    if (!filters.filter.search_field?.length) return false
    return !!filters.filter.search_field?.filter((scope) => scope.type === "developer")?.[0]
  }

  const fetchFlats = async () => {
    if (Object.keys(filters.filter).length === 1) return
    setLoading(true)
    onStartLoading()
    let payload = { ...filters }
    const searchFieldInitial = filters.filter.search_field
    if (!checkIsDeveloperSet()) {
      payload = {
        ...payload,
        filter: {
          ...payload.filter,
          search_field: searchFieldInitial ? searchFieldInitial.concat([developers]) : [developers],
        },
      }
    }
    try {
      const result = await uHomeApi.getComplexes(payload)
      setData(result.data)
    } catch (e) {
      console.error(e)
    } finally {
      setLoading(false)
      onEndLoading()
    }
  }

  const handleOpenMoreInfoModal = (data: UHomeTypes.ComplexObject) => {
    if (!moreInfoModal.current) return
    moreInfoModal.current.show(data)
  }

  useEffect(() => {
    fetchFlats().finally()
  }, [filters])

  if (loading)
    return (
      <div className={cn(styles.Container)}>
        <div className={styles.InfoMessage}>Ищем комплексы, подходящие для данного клиента</div>
      </div>
    )
  if (!loading && !data.length)
    return (
      <div className={cn(styles.Container)}>
        <div className={styles.InfoMessage}>Ничего не нашли для данного клиента</div>
      </div>
    )
  return (
    <div className={cn(styles.Container)}>
      <div className={cn(styles.Table)}>
        <table
          cellSpacing={0}
          cellPadding={0}>
          <tbody>
            <tr>
              <th>Название, кол-во</th>
              <th>Студии</th>
              <th>1-комнатные</th>
              <th>2-комнатные</th>
              <th>3-комнатные</th>
              <th>4-комнатные</th>
              <th>Метро</th>
            </tr>
            {!loading &&
              !!data.length &&
              data.map((data) => (
                <ComplexRow
                  onOpenMore={() => handleOpenMoreInfoModal(data)}
                  key={data.id}
                  complex={data}
                />
              ))}
          </tbody>
        </table>
      </div>
      <AnimatePresence>
        <ComplexAdditionalInfoModal ref={moreInfoModal} />
      </AnimatePresence>
    </div>
  )
}

export default ComplexSearcherWidget
