import { ModalDefaultLayout } from "@/shared/contexts"
import NotResponseContent from "./NotResponseContent"

const NotResponse = () => {
  return (
    <ModalDefaultLayout>
      <NotResponseContent />
    </ModalDefaultLayout>
  )
}

export default NotResponse
