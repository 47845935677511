import { useState } from "react"
import axios from "axios"
import { ScriptData } from "@/entities/script"

export const useModel = () => {
  const [script, setScript] = useState<ScriptData | null>(null)

  const fetchScriptData = (character: string) => {
    axios
      .get("https://forms.caltat.com/api/nodes/" + character)
      .then((result) => {
        if (result.status !== 200) {
          throw new Error("Error")
        }
        setScript(result.data)
      })
      .catch((error) => {
        console.warn(error)
      })
  }

  const resetScript = () => {
    setScript(null)
  }

  return { getScript: fetchScriptData, script, resetScript }
}
