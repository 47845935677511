import { Fragment, useEffect, useState } from "react"
import { CrmObjectLink, useObjects } from "@/entities/object"
import { useAppDispatch, useAppSelector } from "@/shared/hooks"
import TableRowField from "./TableRowField"
import styles from "./index.module.css"
import { ObjectsTypes } from "@/shared/api"
import { CurrentClient } from "@/entities/call"
import { generateInitialFilterPayload, idsCollection } from "@/entities/uhome"
import { motion } from "framer-motion"
import { setGroups } from "@/shared/store/slices/objects"
import { fillInitialFilterKeys } from "@/shared/store/slices/uhome"

interface LeadObjectEditorWidgetProps {
  leadId: number
  contact?: CurrentClient
}

const MetaNamesTranscriptions = {
  __default_group__: "Информация",
}

const LeadObjectEditorWidget = (props: LeadObjectEditorWidgetProps) => {
  const { leadId, contact } = props
  const { getObject } = useObjects()
  const dispatch = useAppDispatch()
  const [loading, setLoading] = useState(true)
  const objectGroups = useAppSelector((store) => store.objects.groups)

  useEffect(() => {
    if (!loading) {
      setLoading(true)
    }
    getObject({ id: leadId })
      .then((data) => {
        dispatch(setGroups(data as any))
        dispatch(fillInitialFilterKeys(generateInitialFilterPayload(data.objects)))
      })
      .finally(() => setLoading(false))
      .catch((e) => console.error(e))
  }, [leadId])

  const getLink = () =>
    contact
      ? import.meta.env.VITE_API_HOST + `/${contact.schema_id ? contact.schema_id : 1188190}/${contact.crm_object_id}`
      : "#"

  if (loading) return null

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className={styles.Wrapper}>
      <CrmObjectLink
        href={getLink()}
        id={contact?.crm_object_id}
        target={"_blank"}
      />
      {objectGroups.map((group, index) => (
        <Fragment key={index}>
          <div className={styles.TableWrapper}>
            <h2 className={styles.TableHeader}>
              {(MetaNamesTranscriptions as any)[group.name] ? (MetaNamesTranscriptions as any)[group.name] : group.name}
            </h2>
            <table>
              <tbody className={styles.TableBody}>
                {group.fields.map((field) => {
                  const filterKey = (idsCollection as any)[field.id]
                  return (
                    <TableRowField
                      filterKey={filterKey}
                      key={field.id}
                      field={field}
                      direction={field.edit_widget === ObjectsTypes.EditWidgetType.TAG ? "vertically" : "horizontally"}
                    />
                  )
                })}
              </tbody>
            </table>
          </div>
          {index !== objectGroups.length - 1 && (
            <div style={{ width: "100%", height: 1, backgroundColor: "#E7E6F3" }} />
          )}
        </Fragment>
      ))}
    </motion.div>
  )
}

export default LeadObjectEditorWidget
