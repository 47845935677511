import styles from "./index.module.css"
import cn from "classnames"
import { StatusConfig } from "@/entities/call"
import { CallSystemStatuses } from "@/shared/api"
import { useEffect, useState } from "react"
import { IconChevronDown } from "@tabler/icons-react"
import { AnimatePresence, motion } from "framer-motion"
import { LoadingSpinner } from "@/shared/ui/components/loaders"

interface ChangeUserCallStatus {
  loading: boolean
  disabled: boolean
  status: CallSystemStatuses
  options: Array<CallSystemStatuses>
  onChangeStatus: (value: CallSystemStatuses) => void
}

const ChangeUserCallStatus = ({ options, status, onChangeStatus, loading, disabled }: ChangeUserCallStatus) => {
  const currentValue = StatusConfig[status]
  const [isOpen, setIsOpen] = useState(false)

  const onChangeElement = (value: CallSystemStatuses) => {
    setIsOpen(false)
    onChangeStatus(value)
  }

  useEffect(() => {
    if (loading || disabled) {
      setIsOpen(false)
    }
  }, [loading, disabled])

  return (
    <div className={cn(styles.Container, (disabled || loading) && styles.Disabled)}>
      <div
        onClick={() => (loading || disabled ? undefined : setIsOpen((prev) => !prev))}
        className={cn(styles.Value)}>
        <div className={cn(styles.ValueContainer)}>
          <div
            className={cn(styles.StatusColorCircle)}
            style={{ background: currentValue.color }}></div>
          <div>{currentValue.name}</div>
        </div>
        <div className={cn(styles.Chevron, isOpen && styles.ChevronRotated, !loading && styles.Svg)}>
          {loading ? (
            <LoadingSpinner
              style={{ marginBottom: -2 }}
              size={12}
              color={"#888"}
            />
          ) : (
            <IconChevronDown />
          )}
        </div>
      </div>
      <AnimatePresence>
        {isOpen && (
          <>
            <motion.div
              animate={{ opacity: 1 }}
              initial={{ opacity: 0 }}
              exit={{ opacity: 0 }}
              className={cn(styles.BackDrop)}
              onClick={() => setIsOpen(false)}></motion.div>
            <motion.div
              animate={{ opacity: 1, y: 0 }}
              initial={{ opacity: 0, y: 20 }}
              exit={{ opacity: 0, y: 20 }}
              className={cn(styles.Options)}>
              {options.map((option, index) => {
                const status = StatusConfig[option]
                return (
                  <div
                    key={index}
                    onClick={() => onChangeElement(option)}
                    className={cn(styles.OptionsItem)}>
                    <div
                      className={cn(styles.StatusColorCircle)}
                      style={{ background: status.color }}></div>
                    <div>{status.name}</div>
                  </div>
                )
              })}
            </motion.div>
          </>
        )}
      </AnimatePresence>
    </div>
  )
}

export default ChangeUserCallStatus
