import styles from "./index.module.css"
import cn from "classnames"
import { IconClockHour3 } from "@tabler/icons-react"
import { motion } from "framer-motion"
import { CallSystemStatuses } from "@/shared/api"
import { useAppSelector } from "@/shared/hooks"
import { useEffect, useRef, useState } from "react"
import { getMMSS } from "@/shared/helpers"

const STEP = 1000

const CallStopWatch = ({ status }: { status: CallSystemStatuses }) => {
  const call = useAppSelector((state) => state.call.client)
  const currentInterval = useRef<any>(null)
  const [ms, setMS] = useState(STEP)
  const msRef = useRef<number>(STEP)

  useEffect(() => {
    if (call) {
      switch (status) {
        case CallSystemStatuses.CallInProcess:
          startStopWatch()
          break
        case CallSystemStatuses.FillingForms:
          clearStopWatch()
          break
        default:
          console.log("another actions")
      }
    }
  }, [status])

  const startStopWatch = () => {
    clearStopWatch()
    setMS(STEP)
    msRef.current = STEP
    currentInterval.current = setInterval(() => {
      const newMS = (msRef.current += STEP)
      setMS(newMS)
    }, STEP)
  }

  const clearStopWatch = () => {
    clearInterval(currentInterval.current)
    currentInterval.current = null
  }

  if (![CallSystemStatuses.CallInProcess, CallSystemStatuses.FillingForms].includes(status)) return null
  return (
    <motion.div
      initial={{ opacity: 0, height: 0 }}
      animate={{ opacity: 1, height: 34 }}
      className={cn(styles.Container)}>
      <div className={cn(styles.Label)}>
        <IconClockHour3 />
        <span>Длительность текущего диалога</span>
      </div>
      <div className={cn(styles.Time, status === CallSystemStatuses.FillingForms && styles.Yellow)}>
        <span>{getMMSS(ms / 1000)}</span>
      </div>
    </motion.div>
  )
}

export default CallStopWatch
