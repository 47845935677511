import { CallSystemStatuses } from "@/shared/api"
import { StopActionButton } from "./StopActionButton"
import { EndCallButton } from "./EndCallButton"

interface ButtonsStatusDependedProps {
  status: CallSystemStatuses
  loading: boolean
  onEndCallClick: () => void
  onEndFormFillingClick: () => void
}

export const ButtonsStatusDepended = (props: ButtonsStatusDependedProps) => {
  const { status, loading, onEndCallClick, onEndFormFillingClick } = props
  if ([CallSystemStatuses.CallInProcess].includes(status)) {
    return (
      <EndCallButton
        disabled={loading}
        onClick={onEndCallClick}
      />
    )
  }
  if (CallSystemStatuses.FillingForms === status) {
    return (
      <StopActionButton
        disabled={loading}
        onClick={onEndFormFillingClick}
      />
    )
  }
  return null
}
