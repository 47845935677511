import { useState } from "react"
import styles from "./index.module.css"
import { useObjects } from "@/entities/object"
import cn from "classnames"
import { formatPrice, phoneFormatter } from "@/shared/helpers"
import { setFilter } from "@/shared/store/slices/uhome"
import { ObjectsTypes } from "@/shared/api"
import { useAppDispatch, useAppSelector } from "@/shared/hooks"

import { Select, SelectSearch } from "@/shared/ui/components/selects"
import { TextInput } from "@/shared/ui/components/inputs"
import { Checkbox } from "@/shared/ui/components/checkboxes"
import { removeLetters } from "@/shared/helpers/remove-letters.ts"
import { TagList } from "@/shared/ui/components/tags"
import { DateSelect } from "@/shared/ui/components/date"
import { FieldType } from "@/shared/api/model/objects/types.ts"

interface EditFieldProps {
  filedInfo: ObjectsTypes.ObjectGroupField
  filterKey: string
}

const numberFormatter = (value: string) => {
  return value ? (value[0] === "0" ? [removeLetters(value.slice(1))] : [removeLetters(value)]) : [""]
}

export const getFieldByType = ({
  id,
  read_only,
  values_amount,
  choice,
  placeholder,
  edit_widget,
  onUpdate,
  values,
  type,
  onChange,
}: ObjectsTypes.ObjectGroupField & {
  onChange: (arr: string[]) => void
  onUpdate: (id: number, values: string[]) => void
}) => {
  switch (edit_widget) {
    case ObjectsTypes.EditWidgetType.INPUT:
      return (
        <TextInput
          placeholder={placeholder ? placeholder : type === FieldType.STRING ? "Текст" : "Число"}
          value={values[0]}
          disabled={read_only}
          onChange={(value) => onChange(type === FieldType.STRING ? [value] : numberFormatter(value))}
          onBlur={() => onUpdate(id, values)}
        />
      )
    case ObjectsTypes.EditWidgetType.PHONE:
      return (
        <TextInput
          placeholder={placeholder || "Номер телефона"}
          value={phoneFormatter(values[0])}
          disabled={read_only}
          onChange={(value) => onChange([removeLetters(value).slice(0, 11)])}
          onBlur={() => onUpdate(id, values)}
        />
      )
    case ObjectsTypes.EditWidgetType.PRICE:
      return (
        <TextInput
          placeholder={placeholder || "Число"}
          maxLength={10}
          value={formatPrice(values[0])}
          onChange={(value) => onChange(numberFormatter(value))}
          onBlur={() => onUpdate(id, values)}
        />
      )
    case ObjectsTypes.EditWidgetType.DROPDOWN:
      if (values_amount === 1) {
        return (
          <SelectSearch
            values={values}
            onSelect={(value: Array<string>) => {
              onChange(value)
              onUpdate(id, value)
            }}
            disabled={read_only}
            placeholder={placeholder ? placeholder : "Выбрать"}
            options={choice ? choice.map((item) => ({ id: item.id, value: item.name })) : []}
          />
        )
      } else {
        return (
          <Select
            value={values}
            onChange={(value) => {
              onChange(value)
              onUpdate(id, value)
            }}
            disabled={read_only}
            placeholder={placeholder ? placeholder : "Выбрать"}
            options={choice.map((item) => ({ label: item.name, value: item.name }))}
          />
        )
      }
    case ObjectsTypes.EditWidgetType.CHECKBOX:
      return (
        <Checkbox
          disabled={read_only}
          checked={values[0] === "true"}
          onChange={(v) => {
            onChange([String(v)])
            onUpdate(id, [String(v)])
          }}
        />
      )
    case ObjectsTypes.EditWidgetType.TAG:
      return (
        <TagList
          tags={values}
          onChange={(value) => {
            onChange(value)
            onUpdate(id, value)
          }}
        />
      )
    case ObjectsTypes.EditWidgetType.DATE:
      return (
        <DateSelect
          value={values[0] ? values[0] : null}
          onChange={(value) => {
            onChange([value])
            onUpdate(id, [value])
          }}
        />
      )
    default:
      return null
  }
}

const EditField = (props: EditFieldProps) => {
  const { filedInfo, filterKey } = props
  const dispatch = useAppDispatch()
  const { updateObject } = useObjects()
  const id = useAppSelector((store) => store.objects.id)
  const [value, setValue] = useState<string[]>(filedInfo.values)

  const handleChangeValue = (v: string | string[]) => {
    setValue(Array.isArray(v) ? v : [v])
  }

  const fetchUpdate = (fieldId: number, values: string[]) => {
    if (id !== null) {
      const payload = { field_id: fieldId, values }
      if (filterKey) dispatch(setFilter({ key: filterKey, data: payload }))
      updateObject({ id }, { data: [payload] }).finally()
    }
  }

  if (filedInfo.read_only) {
    return (
      <div className={cn(styles.EditFieldWrapper, styles.ReadOnly)}>
        {filedInfo.values.length ? filedInfo.values.join(", ") : "-"}
      </div>
    )
  }
  return (
    <div className={cn(styles.EditFieldWrapper)}>
      {getFieldByType({ ...filedInfo, values: value, onUpdate: fetchUpdate, onChange: handleChangeValue })}
    </div>
  )
}

export default EditField
