import styles from "./index.module.css"
import { ReactNode } from "react"
import { useClickOutside } from "@/shared/hooks"
import { AnimatePresence, motion } from "framer-motion"
import cn from "classnames"

interface ModalWithEmitterProps {
  children: ReactNode
  emitter: ReactNode
  bottom?: boolean
}

const ModalWithEmitter = (props: ModalWithEmitterProps) => {
  const { children, emitter, bottom } = props
  const { isVisible, setIsVisible, ref } = useClickOutside(false)

  return (
    <div
      className={styles.Container}
      ref={ref}>
      <div onClick={() => setIsVisible((prev) => !prev)}>{emitter}</div>
      <AnimatePresence>
        {isVisible && (
          <motion.div
            initial={Animate.hide}
            animate={Animate.show}
            exit={Animate.hide}
            className={cn(styles.Modal, !!bottom && styles.ModalFromBottom)}>
            {children}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}

const Animate = {
  hide: { opacity: 0, x: -10 },
  show: { opacity: 1, x: 0 },
}

export default ModalWithEmitter
