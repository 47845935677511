import styles from "./index.module.css"
import { Select as AntSelect } from "antd"

interface SelectSearchProps {
  values: Array<string>
  onSelect: (value: Array<string>) => void
  disabled: boolean
  placeholder: string
  options: Array<any>
}

const SelectSearch = (props: SelectSearchProps) => {
  const { values, disabled, options, onSelect, placeholder } = props

  const onChange = (value: string) => {
    onSelect([value])
  }

  const filterOption = (input: string, option?: { id: number; value: string }) =>
    (option?.value ?? "").toLowerCase().includes(input.toLowerCase())

  return (
    <AntSelect
      size={"small"}
      disabled={disabled}
      className={styles.selectSearch}
      showSearch
      value={values[0]}
      placeholder={placeholder}
      optionFilterProp="children"
      onChange={onChange}
      filterOption={filterOption}
      options={Array.isArray(options) ? options : []}
    />
  )
}

export default SelectSearch
