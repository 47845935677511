import { createSlice } from "@reduxjs/toolkit"
import { crmFieldValueToUhomeFilterMiddleware } from "@/entities/uhome"
import { UHomeTypes } from "@/shared/api"

interface ObjectsState {
  filter: UHomeTypes.ComplexesFilterPayload
}

const initialState: ObjectsState = {
  filter: {
    filter: {
      page: 1,
    },
    limit: false,
  },
}

const uhomeSlice = createSlice({
  name: "uhome",
  initialState,
  reducers: {
    fillInitialFilterKeys: (state, { payload }) => {
      state.filter.filter = payload
    },
    setFilter: (state, { payload }) => {
      state.filter.filter = crmFieldValueToUhomeFilterMiddleware(state.filter.filter, payload.key, payload.data)
    },
  },
})

export default uhomeSlice.reducer

export const { setFilter, fillInitialFilterKeys } = uhomeSlice.actions
