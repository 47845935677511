import { Select as AntdSelect } from "antd"

import styles from "./index.module.css"

type SelectOption = {
  label: string
  value: string
}

interface SelectProps {
  size?: "small" | "middle" | "large"
  value: string[]
  disabled?: boolean
  placeholder?: string
  options: SelectOption[]
  onChange: (v: string[]) => void
  onBlur?: () => void
}

const Select = (props: SelectProps) => {
  const { value, size, disabled, placeholder, options, onChange, onBlur } = props

  return (
    <AntdSelect
      size={size ? size : "small"}
      value={value}
      mode="multiple"
      onBlur={onBlur}
      options={options}
      disabled={disabled}
      onChange={onChange}
      maxTagCount="responsive"
      placeholder={placeholder}
      className={styles.select}
    />
  )
}

export default Select
