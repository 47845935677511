import styles from "./index.module.css"
import cn from "classnames"
import { CLIENT_WITH_SCHEMA_1_MOCK, CLIENT_WITH_SCHEMA_2_MOCK, FORM_MOCK } from "@/shared/store/slices/call/mock.ts"
import { useDispatch } from "react-redux"
import { _devSetClient, _devSetForm, _devSetInitialSystemStatus } from "@/shared/store/slices/call/callSlice.ts"
import { CallSystemStatuses } from "@/shared/api"
import { useState } from "react"

const ITEMS_I = [
  {
    label: "Set client [_1_] sch_id",
    values: {
      true: CLIENT_WITH_SCHEMA_1_MOCK,
      false: null,
    },
    active: false,
    method: _devSetClient,
  },
  {
    label: "Set client [_1188190_] sch_id",
    values: {
      true: CLIENT_WITH_SCHEMA_2_MOCK,
      false: null,
    },
    active: false,
    method: _devSetClient,
  },
  {
    label: "Set system status (Call In Process)",
    values: {
      true: CallSystemStatuses.CallInProcess,
      false: CallSystemStatuses.NotHere,
    },
    active: false,
    method: _devSetInitialSystemStatus,
  },
  {
    label: "Set system status (Filling Forms)",
    values: {
      true: CallSystemStatuses.FillingForms,
      false: CallSystemStatuses.NotHere,
    },
    active: false,
    method: _devSetInitialSystemStatus,
  },
  {
    label: "Set script FORM",
    values: {
      true: FORM_MOCK,
      false: null,
    },
    active: false,
    method: _devSetForm,
  },
]

const DevModeTools = () => {
  const dispatch = useDispatch()
  const [items, setItems] = useState(Object.assign({}, ITEMS_I))

  const dispatchEvent = (item: any, key: any) => {
    const active = !item.active
    setItems((prev) => ({ ...prev, [key]: { ...prev[key], active } }))
    dispatch(item.method(item.values[JSON.stringify(active)]))
  }

  return (
    <div className={cn(styles.Menu)}>
      <div>
        {Object.values(items).map((item, index) => {
          return (
            <div
              onClick={() => dispatchEvent(item, index)}
              key={index}
              className={cn(styles.MenuItem, item.active && styles.MenuItemActive)}>
              <div></div>
              <div>{item.label}</div>
            </div>
          )
        })}
      </div>
      <div className={cn(styles.Footer)}>
        <div>Caltat Dev (c) 2024</div>
        <div>@tpetrew</div>
      </div>
    </div>
  )
}

export default DevModeTools
