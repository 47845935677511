import { createSlice } from "@reduxjs/toolkit"

interface AppState {
  isSocketConnected: boolean
}

const initialState: AppState = {
  isSocketConnected: true,
}

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    handleConnection: (state, { payload }) => {
      state.isSocketConnected = payload
    },
  },
})

export default appSlice.reducer

export const { handleConnection } = appSlice.actions
