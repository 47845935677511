import styles from "./index.module.css"
import { ObjectsTypes } from "@/shared/api"
import { useEffect, useMemo, useState } from "react"
import { phoneFormatter } from "@/shared/helpers"
import { IconWalk } from "@tabler/icons-react"

const initialState = {
  title: "__title__",
  surname: "__fixation_surname__",
  phone: "__fixation_client__",
  developer: "__fixation_developer__",
  house: "__fixation_jk__",
  answer: "__fixation_answer__",
}

const FixationObject = ({ item }: { item: ObjectsTypes.RelatedObject }) => {
  const [data, setData] = useState(initialState)

  const fieldsCollection = useMemo(() => {
    return item.fields.map((row) => row[0])
  }, [item])

  const getValueByMetaName = (meta_name: string) =>
    fieldsCollection.filter((field) => field.meta_name === meta_name)[0].values[0]

  useEffect(() => {
    if (fieldsCollection) {
      setData(throwValues())
    }
  }, [fieldsCollection])

  const throwValues = () => {
    let values = {}
    Object.keys(initialState).forEach((key) => {
      //@ts-ignore
      values[key] = getValueByMetaName(initialState[key])
    })
    return values as any
  }

  return (
    <div className={styles.Card}>
      <div className={styles.Row}>
        <IconWalk size={14} />
        <div className={styles.Name}>
          {data?.title} {data?.surname}
        </div>
      </div>
      <div className={styles.Phone}>{data?.phone && phoneFormatter(data?.phone)}</div>
      <div>
        {data?.developer} {data?.house}
      </div>
      <div>{data?.answer}</div>
    </div>
  )
}

export default FixationObject
