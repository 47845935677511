export type GenerateAudioOptions = {
  volume?: number
  autoplay?: boolean
  src?: string
  muted?: boolean
  loop?: boolean
  srcObject?: MediaStream | null
}

export const generateAudioHtml = (options: GenerateAudioOptions) => {
  const audio = document.createElement("audio")
  Object.assign(audio, options)
  return audio
}
