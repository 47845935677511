import { ApiEndpoint } from "@/shared/api/lib/api-service"
import { api, PREFIXES_WITHOUT_CAMPAIGN } from "@/shared/api/lib"
import { ERequestData, ERequestMethods } from "@/shared/api/lib/api-helpers"

import type { SignInBody, SignInData } from "./types"

const loginEndpoint = new ApiEndpoint(api, {})

const LOGIN = `${PREFIXES_WITHOUT_CAMPAIGN}/auth/login`

export const authApi = {
  signIn: (body: SignInBody): Promise<ERequestData<SignInData>> =>
    loginEndpoint.request(LOGIN, body, ERequestMethods.POST, null, null),
}
