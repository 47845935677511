import ReactDOM from "react-dom/client"
import * as Sentry from "@sentry/react"
import "react-toastify/dist/ReactToastify.css"
import "@/shared/root/reset.css"
import "@/shared/root/root.css"
import { App, throwCurrentBuildStatus } from "@/app"
import { IS_DEV_MODE } from "@/shared/helpers"

throwCurrentBuildStatus()

if (!IS_DEV_MODE) {
  Sentry.init({
    dsn: "https://d1afd5556c424f0dbb4b1907348260c0@sentry.caltat.com/59",
    integrations: [new Sentry.BrowserTracing()],
    tracesSampleRate: 1.0,
  })
}

ReactDOM.createRoot(document.getElementById("root")!).render(<App />)
