import { UHomeTypes, ObjectsTypes } from "@/shared/api"
import { removeLetters } from "@/shared/helpers/remove-letters.ts"

const UHOME_FIELD_KEYS = {
  CEILING_HEIGHT_START: "ceiling_height-start",
  CEILING_HEIGHT_END: "ceiling_height-end",
  FLOOR_START: "floor-start",
  PRICE_START: "price-start",
  AREA_START: "area-start",
  PRICE_END: "price-end",
  FLOOR_END: "floor-end",
  DEVELOPER: "developer",
  DIRECTION: "direction",
  AREA_END: "area-end",
  ROOMS: "rooms",
  CITY: "city",
}

export const UHOME_CRM_FIELDS_BRIDGE = {
  [UHOME_FIELD_KEYS.CEILING_HEIGHT_START]: [1188361, 1188349],
  [UHOME_FIELD_KEYS.CEILING_HEIGHT_END]: [1188362, 1188350],
  [UHOME_FIELD_KEYS.FLOOR_START]: [1188357, 1188345],
  [UHOME_FIELD_KEYS.PRICE_START]: [1188352, 1188340],
  [UHOME_FIELD_KEYS.AREA_START]: [1188359, 1188347],
  [UHOME_FIELD_KEYS.FLOOR_END]: [1188346, 1188358],
  [UHOME_FIELD_KEYS.PRICE_END]: [1188341, 1188353],
  [UHOME_FIELD_KEYS.DEVELOPER]: [1188343, 1188355],
  [UHOME_FIELD_KEYS.AREA_END]: [1188348, 1188360],
  [UHOME_FIELD_KEYS.DIRECTION]: [53, 1188311],
  [UHOME_FIELD_KEYS.ROOMS]: [43, 1188302],
  [UHOME_FIELD_KEYS.CITY]: [42, 1188301],
}

const a101 = parseInt(import.meta.env.VITE_DEVELOPER_A101, 10)
const samolet = parseInt(import.meta.env.VITE_DEVELOPER_SAMOLET, 10)

// key to values (there every value is unique) => value to key
const transformFromKVtoVK = () => {
  return Object.entries(UHOME_CRM_FIELDS_BRIDGE).reduce((acc, [field_name, field_ids_collection]) => {
    const up: any = {}
    field_ids_collection.forEach((field_id) => {
      up[field_id] = field_name
    })
    return { ...acc, ...up }
  }, {})
}

export const idsCollection = transformFromKVtoVK()

export const generateInitialFilterPayload = (groups: ObjectsTypes.ObjectGroup[]) => {
  let result: UHomeTypes.ComplexesFilterPayload["filter"] = {
    page: 1,
  }
  const keys = Object.keys(idsCollection)
  groups.forEach((group) => {
    group.fields.forEach((field) => {
      if (keys.includes(field.id.toString())) {
        result = crmFieldValueToUhomeFilterMiddleware(result, (idsCollection as any)[field.id], {
          field_id: field.id,
          values: field.values,
        })
      }
    })
  })
  return result
}

export const crmFieldValueToUhomeFilterMiddleware = (
  state: UHomeTypes.ComplexesFilterPayload["filter"],
  filterKey: string,
  data: { field_id: number; values: Array<string> },
) => {
  const result: UHomeTypes.ComplexesFilterPayload["filter"] = {
    ...state,
    page: 1,
  }
  const values = data.values
  const fieldKey = filterKey.replace("-start", "").replace("-end", "") as any
  const direction = filterKey.replace(fieldKey + "-", "") as "start" | "end"
  let search_field = result.search_field?.length ? result.search_field : []
  if ([UHOME_FIELD_KEYS.DIRECTION, UHOME_FIELD_KEYS.DEVELOPER, UHOME_FIELD_KEYS.CITY].includes(fieldKey)) {
    search_field = search_field.filter((scope) => scope.type !== fieldKey)
  }
  result.search_field = search_field
  switch (filterKey) {
    case UHOME_FIELD_KEYS.DEVELOPER:
      if (values.length) {
        result.search_field.push({
          type: UHOME_FIELD_KEYS.DEVELOPER,
          value: values.map((value) => getDeveloperIdByName(value)),
        })
      }
      break
    case UHOME_FIELD_KEYS.CITY:
      if (values.length) {
        result.search_field.push({
          type: UHOME_FIELD_KEYS.CITY,
          value: values,
        })
      }
      break
    case UHOME_FIELD_KEYS.DIRECTION:
      if (values.length) {
        result.search_field.push({
          type: UHOME_FIELD_KEYS.DIRECTION,
          value: values,
        })
      }
      break
    case UHOME_FIELD_KEYS.ROOMS:
      result.rooms = prettifyRooms(values)
      break
    default:
      const newValue = throwDoubleValue(
        (state as any)[fieldKey],
        direction,
        values[0],
        fieldKey === "ceiling_height" ? 100 : undefined,
      )
      if (newValue === null) {
        //@ts-ignore
        delete result[fieldKey]
      } else {
        //@ts-ignore
        result[fieldKey] = newValue
      }

      break
  }
  // console.log(result)
  return result
}

const throwDoubleValue = (
  prevValue: Array<string> | undefined,
  direction: "start" | "end",
  value: string,
  divider?: number,
) => {
  const maxValue = "999999999"
  const minValue = "0"
  const result = Array.isArray(prevValue) ? prevValue : []
  if (direction === "start") {
    result[0] = value ? value : minValue
    if (!result[1]) {
      result[1] = maxValue
    }
  } else {
    if (!result[0]) {
      result[0] = minValue
    }
    result[1] = value ? value : maxValue
  }
  if (!result[0] && !result[1]) {
    return null
  }
  if (result[0] === minValue && result[1] === minValue) {
    result[1] = maxValue
  }
  if (divider) {
    return result.map((value) => {
      const math = parseInt(value) / divider
      if (math < 1) {
        return value
      } else return math.toString()
    })
  }
  return result
}

const getDeveloperIdByName = (name: string | undefined) => {
  if (typeof name === "string" && name.toLowerCase() === "самолет") return samolet
  return a101
}

const prettifyRooms = (array: Array<string>) => {
  return array.map((item) => {
    if (item.toLowerCase() === "студия") return 0
    return parseInt(removeLetters(item))
  })
}
