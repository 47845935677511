import { useEffect, useRef, useState } from "react"
import { toast } from "react-toastify"

import { AudioBells, CurrentClient, useWebrtcInitiator } from "@/entities/call"

import { SocketEvents, useAppDispatch, useAppSelector, useCallCenterSocket } from "@/shared/hooks"
import { ApiErrorInterface, callCenterApi, CallSystemStatuses } from "@/shared/api"
import { useModalsContext } from "@/shared/contexts"

import { setCurrentClient } from "@/shared/store/slices/call"
import { IS_DEV_MODE } from "@/shared/helpers"

interface StartCallEvent extends CurrentClient {
  type: SocketEvents.StartCall
}

interface EndCallEvent {
  type: SocketEvents.EndCall
  call_id: number
  dialog: boolean
  show_message: boolean
}

interface ModelParams {
  recallCreated: () => boolean
}

const Errors = {
  Dialog: "Скорее всего вы находитесь в диалоге",
  StatusAlready: "Вы уже в данном статусе",
  StatusChange: "Ошибка смены статуса",
  UkwEvent: "Unregistered event: ",
}

const Modals = {
  LostRecall: "LostContactRecall",
  NotResponse: "NotResponse",
}

interface ModelStore {
  call: null | CurrentClient
  status: CallSystemStatuses
}

const useModel = ({ recallCreated }: ModelParams) => {
  const INITIAL_CALL_STATUS = useAppSelector((state) => state.call.initialSystemStatus)
  const store = useRef<ModelStore>({ call: null, status: INITIAL_CALL_STATUS })
  const [loading, setLoading] = useState(false)
  const [callCenterStatus, setStatus] = useState<CallSystemStatuses>(INITIAL_CALL_STATUS)
  const dispatch = useAppDispatch()
  const { openModal } = useModalsContext()

  // DEV MODE TRIGGER START
  useEffect(() => {
    if (IS_DEV_MODE) {
      store.current = {
        call: null,
        status: INITIAL_CALL_STATUS,
      }
      setStatus(INITIAL_CALL_STATUS)
    }
  }, [INITIAL_CALL_STATUS])
  // DEV MODE TRIGGER END

  const _dispatchCurrentCall = (data: null | CurrentClient) => {
    store.current.call = data
    dispatch(setCurrentClient(data))
  }

  const _showClientErrorMessage = (message: string) => {
    toast.error(message, { icon: false })
  }

  const _showClientWarnMessage = (message: string) => {
    toast.warning(message, { icon: false })
  }

  const _onCallCenterEvent = async (event: any) => {
    switch (event.type) {
      case SocketEvents.StartCall:
        await _callStartEvent(event)
        break
      case SocketEvents.EndCall:
        await _callEndEvent(event)
        break
      default:
        console.log(Errors.UkwEvent + " " + event.type)
    }
  }

  const _onCallAccepted = async () => {
    await playAudioElementSound(AudioBells.Start)
    await _sendSelectedStatus(CallSystemStatuses.CallInProcess)
  }

  const _navigateToFillingFormStatus = async () => {
    await playAudioElementSound(AudioBells.End)
    killWebRTCConnection()
    await _sendSelectedStatus(CallSystemStatuses.FillingForms)
  }

  const {
    killWebRTCConnection,
    playAudioElementSound,
    stopAudioElementSound,
    setInitialAudioVolumes,
    getAudioElementsVolumes,
    createWebRTCConnectionIfNeeded,
  } = useWebrtcInitiator({ onCallAccepted: _onCallAccepted })

  // call center socket events
  const {} = useCallCenterSocket({ onEvent: _onCallCenterEvent })

  const _sendSelectedStatus = async (status: CallSystemStatuses) => {
    setLoading(true)
    try {
      await callCenterApi.sendStatus({ status })
      store.current.status = status
      setStatus(status)
    } catch (error: ApiErrorInterface | any) {
      const stringError = JSON.stringify(error)
      console.log(stringError)
      toast.error(stringError || Errors.StatusChange, { icon: false })
    } finally {
      setLoading(false)
    }
  }

  const __warnLostRecallSubmit = () => {}

  const __warnLostRecallReset = async () => {
    await _sendSelectedStatus(CallSystemStatuses.NotHere)
    _dispatchCurrentCall(null)
  }

  const __warnLostRecallDecline = async () => {
    await _startWaitingStatus()
    _dispatchCurrentCall(null)
  }

  const _stopAllSounds = () => {
    stopAudioElementSound(AudioBells.CallRequest)
    stopAudioElementSound(AudioBells.End)
    stopAudioElementSound(AudioBells.Start)
  }

  const _callStartEvent = async (event: StartCallEvent) => {
    const { status } = store.current
    if (status !== CallSystemStatuses.Waiting) return
    _stopAllSounds()
    await playAudioElementSound(AudioBells.CallRequest)
    _dispatchCurrentCall(event)
  }

  const _callEndEvent = async (event: EndCallEvent) => {
    _stopAllSounds()
    const { status, call } = store.current

    // Если текущий статус системы не равны статусам "В процессе" или "Готов ожидание"
    if (![CallSystemStatuses.CallInProcess, CallSystemStatuses.Waiting].includes(status)) return null

    // Если текущий диалог пытается перебить другой диалог
    if (call?.call_id !== event.call_id) return null

    // Если диалог был
    if (event.dialog) return await _navigateToFillingFormStatus()

    // Если диалога не было но мы пытались дозвониться до него несколько раз
    if (!event.dialog && event.show_message) {
      await _navigateToFillingFormStatus()
      return openModal(Modals.NotResponse, {
        message: call?.message_text,
        call_id: call?.call_id,
        onDecline: __warnLostRecallDecline,
        onSubmit: __warnLostRecallSubmit,
        onReset: __warnLostRecallReset,
      })
    }

    // Если не было диалога, не было параметра показать сообщение о недозвоне
    _dispatchCurrentCall(null)
    await _startWaitingStatus()
  }

  const _prepareForStatusChanging = () => {
    killWebRTCConnection()
    _dispatchCurrentCall(null)
  }

  // CallSystemStatuses.Education | CallSystemStatuses.NotHere | CallSystemStatuses.Waiting
  const _handleChangeStatus = async (newStatus: CallSystemStatuses) => {
    const { status } = store.current
    if (newStatus === status) {
      return _showClientWarnMessage(Errors.StatusAlready)
    }
    if (status === CallSystemStatuses.CallInProcess) return _showClientErrorMessage(Errors.Dialog)
    setLoading(true)
    _stopAllSounds()
    _prepareForStatusChanging()
    if (newStatus === CallSystemStatuses.Waiting) {
      await _startWaitingStatus()
      return
    }
    _sendSelectedStatus(newStatus).finally()
  }

  const _checkIsClientWasSentToRecall = async () => {
    if (!recallCreated()) {
      return openModal(Modals.LostRecall, {
        onReset: __warnLostRecallReset,
        onSubmit: __warnLostRecallSubmit,
        onDecline: __warnLostRecallDecline,
      })
    }
    await _startWaitingStatus()
    _dispatchCurrentCall(null)
  }

  const _startWaitingStatus = async () => {
    setLoading(true)
    // Проверка на потерянный звонок
    // Ситуация, когда менеджер начал звонить и резко ушел со статуса "Готов"
    // Звонок инициируется но из-за потерянного соединения или сброшеннго клиента из эвента start_call мы теряем данные о контакте
    const lostActiveCall = await __verifyLostActiveCall()
    !!lostActiveCall && _dispatchCurrentCall(lostActiveCall)
    await createWebRTCConnectionIfNeeded()
    await _sendSelectedStatus(CallSystemStatuses.Waiting)
  }

  const __verifyLostActiveCall = (): Promise<CurrentClient | null> => {
    return new Promise(async (resolve) => {
      try {
        const result = await callCenterApi.verifyLostActiveCall()
        resolve(result.data)
      } catch (e) {
        resolve(null)
      }
    })
  }

  useEffect(() => {
    _sendSelectedStatus(INITIAL_CALL_STATUS).finally()
  }, [])

  const confirmFormFilling = () => _checkIsClientWasSentToRecall()

  const endCall = () => {
    killWebRTCConnection()
  }

  return {
    changeStatus: _handleChangeStatus,
    setInitialAudioVolumes,
    playAudioElementSound,
    getAudioElementsVolumes,
    confirmFormFilling,
    loading,
    endCall,
    status: callCenterStatus,
  }
}

export default useModel
