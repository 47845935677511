import cn from "classnames"
import styles from "./index.module.css"
import Logo from "@/shared/assets/phone-72x72.png"
import { IconApi, IconChartArcs, IconCode, IconDoorEnter, IconWalk } from "@tabler/icons-react"
import { IconButton } from "@/shared/ui/components/buttons"
import Cookies from "js-cookie"
import { API_URL } from "@/shared/api/lib"
import { ModalWithEmitter } from "@/shared/ui/layout/ModalWithEmitter"
import { DevModeTools } from "@/widgets/DevModeTools"
import { StatisticsWidget } from "@/widgets/StatisticsWidget"
import { CurrentUserInfo } from "@/widgets/CallWidget/ui/CurrentUserInfo.tsx"

const VITE_INCLUDE_DEV_TOOLS = import.meta.env.VITE_INCLUDE_DEV_TOOLS === "true"

const SideMenu = () => {
  const handleLogout = () => {
    Cookies.remove("access_token", {
      path: "/",
      ...(!window.location.host.includes("localhost") && {
        domain: window.location.host.split(".").slice(-2).join("."),
      }),
    })
    localStorage.removeItem("access_token")
    window.location.reload()
  }

  const handleOpenSwagger = () => {
    // @ts-ignore
    window.open(API_URL + "/v2/docs", "_blank").focus()
  }

  return (
    <div className={cn(styles.Container)}>
      <div className={cn(styles.Logo)}>
        <img src={Logo} />
      </div>
      <div className={styles.MenuButtonsContainer}>
        <ModalWithEmitter emitter={<IconButton icon={<IconWalk />} />}>
          <div className={styles.UserInfo}>
            <CurrentUserInfo />
          </div>
        </ModalWithEmitter>
        <ModalWithEmitter emitter={<IconButton icon={<IconChartArcs />} />}>
          <StatisticsWidget />
        </ModalWithEmitter>
      </div>
      <div className={styles.EmptyVerticalLine}></div>
      <div className={styles.Bottom}>
        {VITE_INCLUDE_DEV_TOOLS && (
          <ModalWithEmitter
            bottom={true}
            emitter={<IconButton icon={<IconCode />} />}>
            <DevModeTools />
          </ModalWithEmitter>
        )}
        {VITE_INCLUDE_DEV_TOOLS && (
          <IconButton
            icon={<IconApi />}
            onClick={handleOpenSwagger}
          />
        )}
        <IconButton
          icon={<IconDoorEnter />}
          onClick={handleLogout}
        />
        <div className={styles.AppInfo}>
          <div>v2.0</div>
          <div>uhome</div>
          <div>calls</div>
        </div>
      </div>
    </div>
  )
}

export default SideMenu
