import Cookies from "js-cookie"
import { AuthTypes, authApi } from "@/shared/api"

export const useAuth = () => {
  const signIn = async (body: AuthTypes.SignInBody) => {
    try {
      const response = await authApi.signIn(body)
      localStorage.setItem("access_token", response.data.payload)
      Cookies.set("access_token", response.data.payload, {
        path: "/",
        ...(!window.location.host.includes("localhost") && {
          domain: window.location.host.split(".").slice(-2).join("."),
        }),
      })
    } catch (error: any) {
      throw new Error(error)
    }
  }

  return { signIn }
}
