import { ApiEndpoint } from "@/shared/api/lib/api-service"
import { api, PREFIXES_WITH_CAMPAIGN } from "@/shared/api/lib"
import { ERequestData, ERequestMethods } from "@/shared/api/lib/api-helpers"

import {
  GetObjectData,
  GetObjectParams,
  UpdateObjectBody,
  UpdateObjectParams,
  GetRelatedObjectsParams,
  RelatedObject,
  Schema,
  GetSchemaParams,
  CreateObjectData,
} from "./types"

const loginEndpoint = new ApiEndpoint(api, {})

const GET_OBJECT = `${PREFIXES_WITH_CAMPAIGN}/object`
const UPDATE_OBJECT = (id: number) => `${PREFIXES_WITH_CAMPAIGN}/object/${id}`
const GET_RELATED_OBJECTS = `${PREFIXES_WITH_CAMPAIGN}/objects/ungrouped-view`
const GET_SCHEMA = `${PREFIXES_WITH_CAMPAIGN}/schema`
const CREATE_OBJECT = `${PREFIXES_WITH_CAMPAIGN}/object`
const CONNECT_RELATED_OBJECT = (root_object_id: number) => `${PREFIXES_WITH_CAMPAIGN}/object/nested/${root_object_id}`

export const objectsApi = {
  getRelatedObjects: (
    params: GetRelatedObjectsParams,
    data: { parent_ids: number[] },
  ): Promise<ERequestData<RelatedObject[]>> =>
    loginEndpoint.request(GET_RELATED_OBJECTS, data, ERequestMethods.POST, params, null),
  getObject: (params: GetObjectParams): Promise<ERequestData<GetObjectData>> =>
    loginEndpoint.request(GET_OBJECT, null, ERequestMethods.GET, params, null),
  getSchema: (params: GetSchemaParams): Promise<ERequestData<Schema>> =>
    loginEndpoint.request(GET_SCHEMA, null, ERequestMethods.GET, params, null),
  updateObject: (params: UpdateObjectParams, body: UpdateObjectBody) =>
    loginEndpoint.request(UPDATE_OBJECT(params.id), body, ERequestMethods.PUT, null, null),
  createObject: (params: GetSchemaParams, body: CreateObjectData) =>
    loginEndpoint.request(CREATE_OBJECT, body, ERequestMethods.POST, params, null),
  connectObjectToRootObject: (params: { related_object_id: number }, root_object_id: number) =>
    loginEndpoint.request(CONNECT_RELATED_OBJECT(root_object_id), null, ERequestMethods.POST, params, null),
}
