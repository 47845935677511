import cn from "classnames"
import styles from "./index.module.css"
import { IconPhoneOff } from "@tabler/icons-react"

interface EndCallButtonProps {
  onClick: () => void
  disabled: boolean
}

export const EndCallButton = ({ onClick, disabled }: EndCallButtonProps) => {
  return (
    <div
      onClick={disabled ? undefined : onClick}
      className={cn(styles.EndCall, disabled && styles.EndCallDisabled)}>
      <IconPhoneOff />
    </div>
  )
}
