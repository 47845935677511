import { ApiEndpoint } from "@/shared/api/lib/api-service"
import { callApi } from "@/shared/api/lib"
import { ERequestData, ERequestMethods } from "@/shared/api/lib/api-helpers"

import type { IStatsUserTasks } from "./types"

const loginEndpoint = new ApiEndpoint(callApi, {})

const USER_TASKS = `/statistic/user-tasks`

export const statisticsApi = {
  getStatsUserTasks: (): Promise<ERequestData<IStatsUserTasks>> =>
    loginEndpoint.request(USER_TASKS, null, ERequestMethods.GET, null, null),
}
