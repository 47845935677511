import { ReactNode, useRef, useState } from "react"
import { AnimatePresence, motion } from "framer-motion"
import styles from "./index.module.css"
import cn from "classnames"

interface MiniModalLayoutProps {
  header: ReactNode
  children: ReactNode
}

const MiniModalLayout = ({ header, children }: MiniModalLayoutProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const headerRef = useRef<HTMLDivElement>(null)

  const handleOpen = () => {
    setIsOpen((prev) => !prev)
  }

  return (
    <div className={cn(styles.Container)}>
      <div
        onClick={handleOpen}
        ref={headerRef}>
        {header}
      </div>
      {isOpen && (
        <div
          onClick={() => setIsOpen(false)}
          className={cn(styles.Backdrop)}></div>
      )}
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 20 }}
            className={cn(styles.Modal)}>
            {children}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}

export default MiniModalLayout
