import { IconArrowNarrowRight, IconPlus } from "@tabler/icons-react"
import styles from "./index.module.css"
import cn from "classnames"
import { AnimatePresence, motion } from "framer-motion"
import { objectsApi, ObjectsTypes } from "@/shared/api"
import { toast } from "react-toastify"
import { useEffect, useRef, useState } from "react"
import { LoadingSpinner } from "@/shared/ui/components/loaders"
import { timeout } from "@/shared/helpers"
import { getFieldByType } from "@/entities/object"

interface CreateNewObjectProps {
  schemaId: number
  rootId: number
  metaName: string
  onCreatedObject: () => Promise<any>
}

interface CreationForm {
  [key: string | number]: ObjectsTypes.ObjectGroupField
}

const INLINE_EDIT_WIDGETS = ["CHECKBOX"]

const CreateNewObject = ({ schemaId, rootId, metaName, onCreatedObject }: CreateNewObjectProps) => {
  const [isVisible, setIsVisible] = useState(false)
  const [loading, setLoading] = useState<boolean>(true)
  const creationSchema = useRef<ObjectsTypes.Schema | null>(null)
  const [form, setForm] = useState<CreationForm>({})

  const fetchCreationSchema = async (schema_id: number) => {
    try {
      setLoading(true)
      const result = await objectsApi.getSchema({ schema_id })
      creationSchema.current = result.data
      if (!creationSchema.current?.fields)
        return toast.error(`Не смогли получить данные для создания объекта схемы ${metaName}`, { icon: false })
      setForm(transformSchemaToEditor(creationSchema.current.fields))
      await timeout(1000)
    } catch (e) {
      console.log(`fetchCreationSchema() Error ${JSON.stringify(e)}`)
    } finally {
      setLoading(false)
    }
  }

  const transformSchemaToEditor = (fields: ObjectsTypes.ObjectGroupField[]) => {
    return fields
      .filter((field) => field.required)
      .reduce(
        (accumulator, item) => ({
          ...accumulator,
          [item.id]: { ...item, values: item.default ? [item.default.toString()] : [] },
        }),
        {},
      )
  }

  useEffect(() => {
    if (schemaId) {
      fetchCreationSchema(schemaId).finally()
    }
  }, [schemaId])

  const onChangeValue = (field_id: number, values: Array<string>) => {
    setForm((prev) => ({
      ...prev,
      [field_id]: {
        ...prev[field_id],
        values,
        error: false,
      },
    }))
  }

  const setFieldError = (field_id: number, error: boolean) => {
    setForm((prev) => ({
      ...prev,
      [field_id]: {
        ...prev[field_id],
        error,
      },
    }))
  }

  const handleSubmit = async () => {
    if (loading) return null
    let error = false
    const values: Array<{ field_id: number; values: Array<string> }> = []
    Object.values(form).forEach((field) => {
      values.push({
        field_id: field.id,
        values: field.values,
      })
      if (!field.values || field.values.length === 0 || !field.values[0]) {
        error = true
        setFieldError(field.id, true)
      }
    })
    if (error) return
    setLoading(true)
    try {
      const result = await objectsApi.createObject({ schema_id: schemaId }, { data: values })
      await objectsApi.connectObjectToRootObject({ related_object_id: result.data.id }, rootId)
      !!onCreatedObject && (await onCreatedObject())
      if (creationSchema.current) {
        setForm(transformSchemaToEditor(creationSchema.current.fields))
      }
      closeModal()
    } catch (e) {
      console.log(`createNewRelatedObject() Error ${JSON.stringify(e)}`)
    } finally {
      setLoading(false)
    }
  }

  const closeModal = () => {
    if (loading) return
    setIsVisible(false)
  }

  const onUpdate = () => {}

  return (
    <div className={cn(styles.Container)}>
      <div
        onClick={loading ? undefined : () => setIsVisible((prev) => !prev)}
        className={cn(styles.HandleButton)}>
        {loading ? (
          <LoadingSpinner
            size={12}
            color={"#999"}
            strokeWidth={3}
          />
        ) : (
          <IconPlus
            size={12}
            stroke={3}
          />
        )}
      </div>
      {isVisible && (
        <div
          onClick={closeModal}
          className={cn(styles.BackDrop)}></div>
      )}
      <AnimatePresence>
        {isVisible && (
          <motion.div
            exit={{ opacity: 0, y: 10 }}
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            className={cn(styles.ModalContainer)}>
            <div
              style={{ opacity: loading ? 0.5 : 1 }}
              className={styles.ModalContainerHeader}>
              <div>{metaName}</div>
              <div>Создание</div>
            </div>
            <div
              style={{ opacity: loading ? 0.5 : 1 }}
              className={styles.ModalContainerForm}>
              {Object.keys(form).map((key) => {
                const field = form[key]
                const values = field.values ? field.values : [""]
                const error = !!field?.error
                return (
                  <div
                    className={cn(
                      styles.FieldContainer,
                      INLINE_EDIT_WIDGETS.includes(field.edit_widget) && styles.Inline,
                    )}
                    key={field.id}>
                    <span className={cn(styles.FieldContainerLabel)}>{field.name}</span>
                    <div className={cn(styles.FieldEditWrapper, error && styles.FieldEditWrapperError)}>
                      {getFieldByType({
                        ...field,
                        values,
                        error,
                        onChange: (values) => onChangeValue(field.id, values),
                        onUpdate,
                      })}
                    </div>
                  </div>
                )
              })}
            </div>
            <div
              style={{ opacity: loading ? 0.5 : 1 }}
              className={cn(styles.Footer)}>
              <span>После создания объекта отредактировать его можно будет только в CRM системе</span>
              <div
                onClick={handleSubmit}
                className={cn(styles.SubmitButton)}>
                {loading ? (
                  <LoadingSpinner
                    size={14}
                    style={{ marginBottom: -1 }}
                    color={"#fff"}
                    strokeWidth={3}
                  />
                ) : (
                  <IconArrowNarrowRight />
                )}
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}

export default CreateNewObject
