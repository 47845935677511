export enum CallSystemStatuses {
  Waiting = "READY",
  Education = "EDUCATION",
  NotHere = "MOVED_AWAY",
  FillingForms = "WAIT_LEAD",
  CallInProcess = "IN_CALL",
}

export interface CallConfig {
  wait_lead_time: number
}
