import cn from "classnames"

import { EditField } from "@/entities/object"

import { ObjectsTypes } from "@/shared/api"
import { TablerIcon } from "@/shared/ui/components/icons"

import styles from "./index.module.css"
import { idsCollection } from "@/entities/uhome"

interface TableRowFieldProps {
  field: ObjectsTypes.ObjectGroupField
  direction?: "horizontally" | "vertically"
  filterKey: string
}

const FilterFieldsIdsCollection = Object.keys(idsCollection)

const TableRowField = (props: TableRowFieldProps) => {
  const { field, filterKey, direction = "horizontally" } = props

  const color = FilterFieldsIdsCollection.includes(field.id.toString()) ? "#1bb097" : undefined

  return (
    <tr
      className={cn(
        styles.TableRow,
        direction === "horizontally" ? styles.TableRowHorizontally : styles.TableRowVertically,
      )}>
      <td
        style={{ color: field.color ? field.color : color }}
        className={cn(styles.TableCellName)}>
        <div className={styles.TableCellIcon}>
          <TablerIcon
            size={15}
            name={field.icon || "point"}
          />
        </div>
        <span>{field.name}:</span>
      </td>
      <td className={styles.TableCellField}>
        <EditField
          filterKey={filterKey}
          filedInfo={field}
        />
      </td>
    </tr>
  )
}

export default TableRowField
