import styles from "./index.module.css"
import { useEffect, useState } from "react"
import { statisticsApi } from "@/shared/api/model/statistics"
import { useAppSelector } from "@/shared/hooks"
import { LoadingSpinner } from "@/shared/ui/components/loaders"

const StatisticsWidget = () => {
  const user = useAppSelector((state) => state.user.user)
  const [data, setData] = useState<any>(null)

  useEffect(() => {
    if (user) {
      setTimeout(() => {
        statisticsApi.getStatsUserTasks().then((res) => setData(res.data))
      }, 500)
    }
  }, [])

  return (
    <div className={styles.Container}>
      <div className={styles.Header}>Статистика</div>
      {!data ? (
        <div className={styles.Loader}>
          <LoadingSpinner
            size={20}
            color={"#444"}
          />
        </div>
      ) : (
        <div className={styles.StatsWrapper}>
          <div className={styles.StatsNum}>
            <div>{data.all_overdue_leads}</div>
            <div>
              Просроченные
              <br />
              за все время
            </div>
          </div>
          <div className={styles.StatsNum}>
            <div>{data.called_today}</div>
            <div>
              Звонков за
              <br />
              сегодня
            </div>
          </div>
          <div className={styles.StatsNum}>
            <div>{data.overdue_today}</div>
            <div>
              Задачи
              <br />
              сегодня
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default StatisticsWidget
