import cn from "classnames"
import styles from "./index.module.css"
import { IconTextSpellcheck } from "@tabler/icons-react"

interface StopEducationButtonProps {
  onClick: () => void
  disabled: boolean
}

export const StopActionButton = ({ onClick, disabled }: StopEducationButtonProps) => {
  return (
    <div
      onClick={disabled ? undefined : onClick}
      className={cn(styles.StopEducationButton)}>
      <IconTextSpellcheck
        size={20}
        color={"#fff"}
      />
    </div>
  )
}
