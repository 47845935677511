import { forwardRef, useEffect, useImperativeHandle, useState } from "react"
import cn from "classnames"
import { motion } from "framer-motion"

import styles from "./index.module.css"
import { UHomeTypes } from "@/shared/api"
import { AnimationVariants, WindowPositions } from "../lib"
import {
  IconBoxAlignBottomLeft,
  IconBoxAlignBottomRight,
  IconBoxAlignTopLeft,
  IconBoxAlignTopRight,
  IconX,
} from "@tabler/icons-react"
import { ComplexInfo } from "./ComplexInfo"

export const Positions = [
  {
    icon: <IconBoxAlignBottomLeft />,
    position: WindowPositions.BottomLeft,
  },
  {
    icon: <IconBoxAlignBottomRight />,
    position: WindowPositions.BottomRight,
  },
  {
    icon: <IconBoxAlignTopLeft />,
    position: WindowPositions.TopLeft,
  },
  {
    icon: <IconBoxAlignTopRight />,
    position: WindowPositions.TopRight,
  },
]

const STORAGE_POSITION_KEY = "_complex_info_window_pos"

const ComplexAdditionalInfoModal = forwardRef((_, parentRef) => {
  const [complex, setComplex] = useState<UHomeTypes.ComplexObject | null>(null)
  const [position, setPosition] = useState(localStorage.getItem(STORAGE_POSITION_KEY) || WindowPositions.BottomRight)
  const [isOpen, setIsOpen] = useState(false)

  useImperativeHandle(
    parentRef,
    () => ({
      show: handleShowModal,
      hide: handleHideModal,
    }),
    [setComplex],
  )

  const handleShowModal = (data: UHomeTypes.ComplexObject) => {
    setComplex(data)
    setIsOpen(true)
  }

  const handleHideModal = () => {
    setIsOpen(false)
    setComplex(null)
  }

  useEffect(() => {
    localStorage.setItem(STORAGE_POSITION_KEY, position)
  }, [position])

  if (!isOpen) return null
  return (
    <motion.div
      style={AnimationVariants[position]}
      initial={{
        opacity: 0,
        y: 20,
      }}
      animate={{
        opacity: 1,
        y: 0,
      }}
      exit={{
        opacity: 0,
        y: 20,
      }}
      className={cn(styles.Container)}>
      <div className={cn(styles.ContainerInner)}>
        <div className={cn(styles.PositionsContainer)}>
          {Positions.map((element) => {
            return (
              <div
                key={element.position}
                className={cn(styles.PositionElement, element.position === position && styles.PositionElementActive)}
                onClick={() => setPosition(element.position)}>
                {element.icon}
              </div>
            )
          })}
        </div>
        <div
          onClick={handleHideModal}
          className={cn(styles.CloseButton)}>
          <IconX size={16} />
        </div>
        <ComplexInfo complex={complex} />
      </div>
    </motion.div>
  )
})

export default ComplexAdditionalInfoModal
