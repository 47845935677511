import { ExtensionsLogsTypes } from "@/shared/api"
import { getTime } from "@/shared/helpers/get-time.ts"
import styles from "./index.module.css"
import cn from "classnames"
import { LOGS_TYPES } from "@/entities/extensionsLogs"

interface LogCardProps {
  logInfo: ExtensionsLogsTypes.GetExtensionsLogsData
}

const LogCard = (props: LogCardProps) => {
  const { logInfo } = props

  const {
    type,
    data: { card, title, body },
    user,
    created_at,
  } = logInfo

  const IS_TELEPHONY = ExtensionsLogsTypes.MESSAGE_TYPES.TELEPHONY === type
  const currentLog = (LOGS_TYPES as any)[type]

  return (
    <div className={styles.logCard}>
      <div
        style={{ backgroundColor: currentLog?.color }}
        className={styles.logCardTypeLine}></div>
      <div className={cn(styles.detail)}>
        <div className={styles.detailTitle}>
          <div>
            {getTitleByType(type, title?.text)} -
            {IS_TELEPHONY && <span className={styles.duration}>{getDuration(body?.text)}</span>}-{" "}
            <span>{user?.name}</span>
          </div>
          <div>{getTime(created_at, "HH:mm")}</div>
        </div>
        {!IS_TELEPHONY && (
          <span
            className={styles.detailText}
            style={{ color: card?.color || undefined }}>
            {body?.text}
          </span>
        )}
      </div>
    </div>
  )
}

export default LogCard

const getTitleByType = (type: string, title: string | null) => {
  switch (type) {
    case ExtensionsLogsTypes.MESSAGE_TYPES.TELEPHONY:
      return "Звонок"
    case ExtensionsLogsTypes.MESSAGE_TYPES.COMMENT:
      return "Комментарий"
    case ExtensionsLogsTypes.MESSAGE_TYPES.MESSAGE:
      return "WhatsApp"
    default:
      return title
  }
}

const getDuration = (text: string | null) => {
  if (!text) return ""
  const collection = text.split(":")
  const result = collection.reverse().splice(0, 3).reverse().join(":")
  return result
}
