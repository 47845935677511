export const ANTD_CONFIG = {
  token: {
    fontSize: 13,
    colorPrimary: "#faad14",
    fontFamily: "Manrope, sans-serif",
  },
}

export const TOAST_CONFIG = {
  fontFamily: 'font-family: "Manrope", sans-serif !important',
  fontSize: 14,
  fontWeight: 600,
}
