import { useState } from "react"
import { AutoComplete as AntdAutoComplete } from "antd"

import styles from "./index.module.css"
import cn from "classnames"
import { IconX } from "@tabler/icons-react"

export type Option = { id: number; value: string }

interface AutocompleteProps {
  options: Option[]
  initialValue: string
  disabled?: boolean
  selected?: string
  onSelect: (value: string) => void
  placeholder?: string
  onBlur?: () => void
  className?: any
  size?: "small" | "middle" | "large"
}

const Autocomplete = (props: AutocompleteProps) => {
  const { options, size, className, initialValue, disabled, selected, onSelect, placeholder, onBlur } = props
  const [inputValue, setInputValue] = useState(initialValue)

  const handleChangeInput = (value: string) => {
    setInputValue(value)
    if (!value) onSelect("")
  }

  const handleBlur = () => {
    onBlur && onBlur()
    if (!selected) {
      if (inputValue) {
        const findOption = options.find((option) => option.value.toLowerCase() === inputValue.toLowerCase())
        if (findOption) {
          setInputValue(findOption.value)
          onSelect(findOption.value)
        } else {
          setInputValue("")
        }
      }
    } else {
      if (inputValue && inputValue !== selected) {
        setInputValue(selected)
      } else if (!inputValue) {
        onSelect("")
      }
    }
  }

  const clear = () => {
    handleChangeInput("")
  }

  return (
    <div className={cn(styles.autocompleteWrapper)}>
      <AntdAutoComplete
        size={size ? size : "small"}
        value={inputValue}
        onBlur={handleBlur}
        options={options}
        disabled={disabled}
        onSelect={onSelect}
        placeholder={placeholder}
        className={cn(styles.autocomplete, className)}
        onChange={handleChangeInput}
        filterOption={(inputValue, option) => option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
      />
      {!!selected && (
        <div
          onClick={clear}
          className={cn(styles.clearButton)}>
          <IconX stroke={3} />
        </div>
      )}
    </div>
  )
}

export default Autocomplete
