import { Checkbox as AntdCheckbox } from "antd"
import type { CheckboxProps as AntdCheckboxProps } from "antd"

interface CheckboxProps {
  checked: boolean
  disabled?: boolean
  onChange: (v: boolean) => void
}

const Checkbox = (props: CheckboxProps) => {
  const { checked, disabled, onChange } = props

  const handleChange: AntdCheckboxProps["onChange"] = (event) => {
    onChange(event.target.checked)
  }

  return (
    <div style={{ paddingLeft: 7 }}>
      <AntdCheckbox
        checked={checked}
        disabled={disabled}
        onChange={handleChange}
      />
    </div>
  )
}

export default Checkbox
