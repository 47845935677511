import { useAppSelector } from "@/shared/hooks"
import styles from "./index.module.css"
import { NestedLeadColumn } from "@/widgets/NestedLeadColumn"
import { NestedObject } from "@/shared/api/model/objects/types.ts"

const NestedLeadObjects = ({ leadId }: any) => {
  const nested = useAppSelector((store) => store.objects.nested)

  const formatSort = (collection: Array<NestedObject> | null) => {
    if (!collection?.length) return []
    const task = collection.filter((item) => item.meta_name === "__task__")[0]
    return [...collection.filter((item) => item.meta_name !== "__task__"), task]
  }

  if (!nested) return null
  return (
    <div className={styles.Container}>
      {formatSort(nested).map((item) => (
        <NestedLeadColumn
          item={item}
          leadId={leadId}
          key={item.meta_name}
        />
      ))}
    </div>
  )
}

export default NestedLeadObjects
