export const WindowPositions = {
  TopLeft: "TOP_LEFT",
  TopRight: "TOP_RIGHT",
  BottomLeft: "BOTTOM_LEFT",
  BottomRight: "BOTTOM_RIGHT",
}

const DEFAULT_EDGES_PADDING = 10

export const AnimationVariants = {
  [WindowPositions.BottomLeft]: {
    bottom: DEFAULT_EDGES_PADDING,
    left: DEFAULT_EDGES_PADDING,
  },
  [WindowPositions.BottomRight]: {
    bottom: DEFAULT_EDGES_PADDING,
    right: DEFAULT_EDGES_PADDING,
  },
  [WindowPositions.TopLeft]: {
    top: DEFAULT_EDGES_PADDING,
    left: DEFAULT_EDGES_PADDING,
  },
  [WindowPositions.TopRight]: {
    top: DEFAULT_EDGES_PADDING,
    right: DEFAULT_EDGES_PADDING,
  },
}
