import { ApiEndpoint } from "@/shared/api/lib/api-service"
import { api, PREFIXES_WITHOUT_CAMPAIGN } from "@/shared/api/lib"
import { ERequestData, ERequestMethods } from "@/shared/api/lib/api-helpers"

import type { User, SignInBody } from "./types"

const loginEndpoint = new ApiEndpoint(api, {})

const VERIFY_USER = `${PREFIXES_WITHOUT_CAMPAIGN}/auth/verify`
const LOGIN = `${PREFIXES_WITHOUT_CAMPAIGN}/auth/login`

export const userApi = {
  verify: (): Promise<ERequestData<User>> => loginEndpoint.request(VERIFY_USER, null, ERequestMethods.GET, null, null),
  signIn: (body: SignInBody): Promise<any> => loginEndpoint.request(LOGIN, body, ERequestMethods.POST, null, null),
}
