import { CSSProperties } from "react"
import styles from "./index.module.css"
import cn from "classnames"

interface SpinnerProps {
  size: number
  style?: CSSProperties
  className?: string
  color?: string
  strokeWidth?: number
}

const LoadingSpinner = ({ size, style, className, color, strokeWidth = 3 }: SpinnerProps) => {
  return (
    <div {...{ style, className }}>
      <svg
        {...{
          style: { width: size, height: size },
          className: cn(styles.loadingSpinner),
          viewBox: `0 0 ${size * 2} ${size * 2}`,
        }}>
        <circle
          {...{
            className: cn(styles.loadingSpinnerPath),
            stroke: color ? color : "#d3d3d3",
            cx: size,
            cy: size,
            r: size - (1 / 5) * size,
            fill: "none",
            strokeWidth,
          }}></circle>
      </svg>
    </div>
  )
}

export default LoadingSpinner
