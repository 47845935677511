import Cookies from "js-cookie"
import axios, { InternalAxiosRequestConfig } from "axios"

import { Api } from "./api-service"

export const API_URL = import.meta.env.VITE_API_HOST + import.meta.env.VITE_API_PATH

const VERSION_V2_PREFIX = "/v2"
const CAMPAIGN_ID_PREFIX = `/campaign_id=1`
export const PREFIXES_WITH_CAMPAIGN = VERSION_V2_PREFIX + CAMPAIGN_ID_PREFIX
export const PREFIXES_WITHOUT_CAMPAIGN = VERSION_V2_PREFIX

const AXIOS_INSTANCE_CONFIG = {
  baseURL: API_URL,
  paramsSerializer: {
    indexes: null,
  },
}

const CALL_INSTANCE_CONFIG = {
  baseURL: import.meta.env.VITE_CALL_CENTER_API,
  paramsSerializer: {
    indexes: null,
  },
}

const UHOME_INSTANCE_CONFIG = {
  baseURL: import.meta.env.VITE_UHOME_API,
  paramsSerializer: {
    indexes: null,
  },
}

const basedClient = axios.create(AXIOS_INSTANCE_CONFIG)
const callClient = axios.create(CALL_INSTANCE_CONFIG)
const uHomeClient = axios.create(UHOME_INSTANCE_CONFIG)

basedClient.interceptors.request.use(
  (config) => getAxiosClientConfig(config),
  (error) => {
    return Promise.reject(error)
  },
)

callClient.interceptors.request.use(
  (config) => getAxiosClientConfig(config),
  (error) => {
    return Promise.reject(error)
  },
)

const getAxiosClientConfig = (config: InternalAxiosRequestConfig, contentType?: string) => {
  const accessToken__Cookie = Cookies.get("access_token")
  const accessToken__LocalStorage = localStorage.getItem("access_token")

  if (!!accessToken__Cookie || !!accessToken__LocalStorage) {
    const token = accessToken__LocalStorage || accessToken__Cookie
    config.headers.Authorization = `Bearer ${token}`
  }
  if (contentType) {
    config.headers["Content-Type"] = contentType
  }
  return config
}

const api = new Api(basedClient)
const callApi = new Api(callClient)
const uHomeApiEndpoint = new Api(uHomeClient)

export { api, callApi, uHomeApiEndpoint }
