import type { ChangeEvent } from "react"
import { Input, Tag, InputRef } from "antd"
import { TweenOneGroup } from "rc-tween-one"
import { useEffect, useRef, useState } from "react"

import { TablerIcon } from "@/shared/ui/components/icons"

import styles from "./index.module.css"

interface TagListProps {
  tags: string[]
  onChange: (value: string[]) => void
}

const TagList = (props: TagListProps) => {
  const { tags, onChange } = props

  const inputRef = useRef<InputRef>(null)

  const [inputValue, setInputValue] = useState("")
  const [inputVisible, setInputVisible] = useState(false)

  const handleClose = (removedTag: string) => {
    const newTags = tags.filter((tag) => tag !== removedTag)
    onChange(newTags)
  }

  const showInput = () => {
    setInputVisible(true)
  }

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value)
  }

  const handleInputConfirm = () => {
    if (inputValue && tags.indexOf(inputValue) === -1) {
      onChange([...tags, inputValue])
    }
    setInputVisible(false)
    setInputValue("")
  }

  useEffect(() => {
    if (inputVisible) {
      inputRef.current?.focus()
    }
  }, [inputVisible])

  return (
    <div className={styles.TagListContainer}>
      <TweenOneGroup
        appear={false}
        enter={{ scale: 0.8, opacity: 0, type: "from", duration: 100 }}
        leave={{ opacity: 0, width: 0, scale: 0, duration: 200 }}
        className={styles.TagList}>
        {tags.map((tag) => (
          <Tag
            key={tag}
            closable
            onClose={(e) => {
              e.preventDefault()
              handleClose(tag)
            }}>
            {tag}
          </Tag>
        ))}
      </TweenOneGroup>
      {inputVisible ? (
        <Input
          ref={inputRef}
          type="text"
          size="small"
          value={inputValue}
          className={styles.TagInputVisible}
          onChange={handleInputChange}
          onPressEnter={handleInputConfirm}
        />
      ) : (
        <Tag
          onClick={showInput}
          className={styles.TagInput}>
          <TablerIcon
            name="plus"
            color="#67717B"
            size={12}
          />
          Новый тег
        </Tag>
      )}
    </div>
  )
}

export default TagList
