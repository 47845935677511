import { ApiEndpoint } from "@/shared/api/lib/api-service"
import { uHomeApiEndpoint } from "@/shared/api/lib"
import { ERequestData, ERequestMethods } from "@/shared/api/lib/api-helpers"

import type { ComplexObject, ComplexesFilterPayload } from "./types"

const loginEndpoint = new ApiEndpoint(uHomeApiEndpoint, {})

const GET_COMPLEXES = "/search/new/complex/v2"

export const uHomeApi = {
  getComplexes: (payload: ComplexesFilterPayload): Promise<ERequestData<ComplexObject[]>> =>
    loginEndpoint.request(GET_COMPLEXES, payload, ERequestMethods.POST, null, null),
}
