import styles from "./index.module.css"
import { ObjectsTypes } from "@/shared/api"
import { motion } from "framer-motion"
import { useEffect, useMemo, useState } from "react"
import { formatDate } from "@/shared/helpers/format-date.ts"

const initialState = {
  title: "__title__",
  date: "__deadline_to__",
  stage: "__stage__",
  // status: "__deadline_status__",
  priority: "__priority__",
}

const TaskObject = ({ item, schema_id }: { item: ObjectsTypes.RelatedObject; schema_id: number }) => {
  const [data, setData] = useState(initialState)

  const fieldsCollection = useMemo(() => {
    return item.fields.map((row) => row[0])
  }, [item])

  const getValueByMetaName = (meta_name: string) =>
    fieldsCollection.filter((field) => field.meta_name === meta_name && field.schema_id === schema_id)?.[0]?.values[0]

  useEffect(() => {
    if (fieldsCollection) {
      setData(throwValues())
    }
  }, [fieldsCollection])

  const throwValues = () => {
    let values = {}
    Object.keys(initialState).forEach((key) => {
      //@ts-ignore
      values[key] = getValueByMetaName(initialState[key])
    })
    return values as any
  }

  return (
    <motion.div
      initial={{ height: 0, opacity: 0 }}
      animate={{ height: "auto", opacity: 1 }}
      className={styles.Card}>
      <div className={styles.Title}>{data?.title}</div>
      <div className={styles.Date}>{formatDate(data?.date)}</div>
      <div>{data?.priority}</div>
      <div>{data?.stage}</div>
    </motion.div>
  )
}

export default TaskObject
