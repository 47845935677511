import { objectsApi, ObjectsTypes } from "@/shared/api"
import { toast } from "react-toastify"

export const useObjects = () => {
  const getObject = async (params: ObjectsTypes.GetObjectParams) => {
    try {
      const response = await objectsApi.getObject(params)
      const mainGroup = {
        name: "Основная информация",
        fields: Object.values(response.data.main_element),
        icon: null,
        position: "LEFT",
        schema_id: null,
      }
      return {
        id: response.data.id,
        objects: [mainGroup, ...response.data.groups],
        nested: response.data.nested_objects,
      }
    } catch (error: any) {
      toast.error(JSON.stringify(error), { icon: false })
      throw new Error(error)
    }
  }

  const updateObject = async (params: ObjectsTypes.UpdateObjectParams, body: ObjectsTypes.UpdateObjectBody) => {
    try {
      //@ts-ignore
      await objectsApi.updateObject(params, body)
    } catch (error: any) {
      toast.error(JSON.stringify(error), { icon: false })
      throw new Error(error)
    }
  }

  return { getObject, updateObject }
}
