import { objectsApi, ObjectsTypes } from "@/shared/api"
import { Fragment, useEffect, useRef, useState } from "react"
import { ContactObject, FixationObject, TaskObject, useObjects } from "@/entities/object"
import styles from "./index.module.css"
import CreateNewObject from "@/widgets/CreateNewObject"
import { motion } from "framer-motion"
import cn from "classnames"
import { IconChevronDown } from "@tabler/icons-react"
import { useAppSelector } from "@/shared/hooks"

interface ColumnProps {
  item: ObjectsTypes.NestedObject
  leadId: number | undefined
}

interface ChildItem extends ObjectsTypes.RelatedObject {
  active?: boolean
}

const SHEMAS_METANAMES = {
  CONTACT: "__client__",
  FIXATION: "__fixation__",
  TASK: "__task__",
}

const initialMeta = {
  main_phone_field_id: -1,
}

const MAIN_PHONE_FIELD_METANAME = "__is_main_phone__"

const NestedLeadColumn = ({ item, leadId }: ColumnProps) => {
  const trigger = useAppSelector((state) => state.objects.nestedMetaNames[item.meta_name])
  const [_, setLoading] = useState(true)
  const [children, setChildren] = useState<ChildItem[]>([])
  const ALLOWED_SCHEMAS = Object.values(SHEMAS_METANAMES)
  const meta = useRef<any>(initialMeta)
  const { updateObject } = useObjects()
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    if (leadId && ALLOWED_SCHEMAS.includes(item.meta_name)) {
      fetchChildren(leadId).finally()
    }
  }, [leadId, trigger])

  const fetchChildren = async (parent_id: number) => {
    try {
      setLoading(true)
      const params: ObjectsTypes.GetRelatedObjectsParams = {
        schema_id: item.schema_id,
        page: 0,
        view_type: "CARD",
        limit: 50,
        is_desc: true,
      }
      const data = {
        parent_ids: [parent_id],
      }
      const result = await objectsApi.getRelatedObjects(params, data)
      let collection = result.data
      if (item.meta_name === SHEMAS_METANAMES.CONTACT) {
        collection = transformContactElements(collection)
      }
      setChildren(collection)
    } catch (e) {
      console.log(JSON.stringify(e))
    } finally {
      setLoading(false)
    }
  }

  const transformContactElements = (related: ChildItem[]) => {
    return related.map((item) => {
      const fields = []
      for (let row of item.fields) {
        for (let e of row) fields.push(e)
      }
      const mainPhoneField = fields.filter((field) => field.meta_name === MAIN_PHONE_FIELD_METANAME)?.[0]
      meta.current.main_phone_field_id = mainPhoneField.id
      const active = JSON.parse(mainPhoneField.values[0] || "false") || false
      return { ...item, active }
    })
  }

  const onCreatedObject = () => {
    return new Promise((resolve) => {
      if (leadId && ALLOWED_SCHEMAS.includes(item.meta_name)) {
        fetchChildren(leadId).finally(() => {
          resolve(() => {})
        })
      } else {
        resolve(() => {})
      }
    })
  }

  const onItemClick = async (child: ChildItem) => {
    setChildren((prev) =>
      prev.map((item) => ({ ...item, active: !!item.active && item.id === child.id ? false : item.id === child.id })),
    )
    const payload = {
      data: [{ field_id: meta.current.main_phone_field_id, values: [JSON.stringify(!child?.active)] }],
    }
    try {
      await updateObject({ id: child.id }, payload)
    } catch (e) {
      console.log(JSON.stringify(e))
    }
  }

  const handleExpand = () => {
    setIsOpen((prev) => !prev)
  }

  if (!ALLOWED_SCHEMAS.includes(item.meta_name)) return null
  return (
    <div className={styles.Column}>
      <div className={styles.ColumnTitle}>
        {leadId && item.meta_name !== SHEMAS_METANAMES.TASK && (
          <CreateNewObject
            onCreatedObject={onCreatedObject}
            schemaId={item.schema_id}
            rootId={leadId}
            metaName={item.meta_name}
          />
        )}
        <div
          onClick={handleExpand}
          className={cn(styles.Count, styles.Pointer)}>
          {children.length}
        </div>
        <span
          onClick={handleExpand}
          className={cn(styles.Pointer)}>
          {item.name}
        </span>
        <motion.div
          onClick={handleExpand}
          animate={{ rotateZ: isOpen ? 0 : 180 }}
          className={cn(styles.Chevron, styles.Pointer)}>
          <IconChevronDown strokeWidth={3} />
        </motion.div>
      </div>
      <motion.div
        initial={{ height: 0, opacity: 0 }}
        animate={{ height: isOpen ? "auto" : 0, opacity: isOpen ? 1 : 0 }}
        className={styles.CardsWrapper}>
        {children.length ? (
          children.map((child, index) => {
            //@ts-ignore
            return (
              <Fragment key={index}>
                {ObjectsElements(child, item.schema_id, () => onItemClick(child))[item.meta_name]}
              </Fragment>
            )
          })
        ) : (
          <span className={styles.NoChildren}>Нет</span>
        )}
      </motion.div>
    </div>
  )
}

const ObjectsElements = (data: ChildItem, schema_id: number, onClick: () => void) => ({
  __fixation__: <FixationObject item={data} />,
  __client__: (
    <ContactObject
      item={data}
      onClick={onClick}
    />
  ),
  __task__: (
    <TaskObject
      schema_id={schema_id}
      item={data}
    />
  ),
})

export default NestedLeadColumn
