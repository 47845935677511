import { createSlice } from "@reduxjs/toolkit"
import { CurrentClient } from "@/entities/call"
import { CallSystemStatuses } from "@/shared/api"

interface InitialState {
  client: CurrentClient | null
  forms: {
    proj: string
    forms: string
    operator: string
    msisdn: string
    dialer_id: string
    call_id: string
    stage: string
    name: string
  } | null
  initialSystemStatus: CallSystemStatuses
  sendToRecallEnabledStatuses: Array<CallSystemStatuses>
  restartWaitingStatusTimeout: number
}

const initialState: InitialState = {
  client: null,
  forms: null,
  sendToRecallEnabledStatuses: [CallSystemStatuses.FillingForms, CallSystemStatuses.CallInProcess],
  initialSystemStatus: CallSystemStatuses.NotHere,
  restartWaitingStatusTimeout: 1000 * 60 * 10,
}

const HARDCODED_FORMS_URL =
  "https://cdn2.caltat.com/forms/estate_tst_new_dialog.html?proj=uhome_dial&forms=dumaet&operator={{Оператор.Имя}}&msisdn={{DNIS}}&dialer_id=137&call_id={{CALL_ID}}"

const callSlice = createSlice({
  name: "call",
  initialState,
  reducers: {
    setCurrentClient: (state, { payload }) => {
      if (!payload) {
        state.client = null
        state.forms = null
      } else {
        const searchParams = new URL(!!payload?.lead_url ? payload.lead_url : HARDCODED_FORMS_URL).searchParams
        const entries = searchParams.entries()
        state.forms = paramsToObject(entries) as any
        state.client = payload
      }
    },
    setRestartStatusTimeout: (state, { payload }: { payload: number }) => {
      state.restartWaitingStatusTimeout = payload
    },
    _devSetInitialSystemStatus: (state, { payload }) => {
      state.initialSystemStatus = payload
    },
    _devSetClient: (state, { payload }) => {
      state.client = payload
    },
    _devSetForm: (state, { payload }) => {
      state.forms = payload
    },
  },
})

export default callSlice.reducer
export const { setCurrentClient, setRestartStatusTimeout, _devSetInitialSystemStatus, _devSetClient, _devSetForm } =
  callSlice.actions

function paramsToObject(entries: any) {
  const result = {}
  for (const [key, value] of entries) {
    //@ts-ignore
    result[key] = value
  }
  return result
}
