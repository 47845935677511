import { useEffect, useState } from "react"
import { addListenerToMediaDevicesPermission } from "@/shared/helpers"

const initialState = {
  microphone: "granted",
}

type ChangedPermissionResult = {
  [key in keyof typeof initialState]: string
}

type MediaPermissions = typeof initialState

export const useMediaPermissions = (onDisabledMedia: () => void, onAllowedMedia?: () => void) => {
  const [permissions, setPermissions] = useState({ ...initialState })
  const [inited, init] = useState(false)

  useEffect(() => {
    addListenerToMediaDevicesPermission(getChangedPermission).then()
  }, [])

  const getChangedPermission = (value: ChangedPermissionResult) => {
    window.location.reload()
    setPermissions((prev) => ({
      ...prev,
      ...value,
    }))
  }

  useEffect(() => {
    if (inited) {
      const searchResult = searchForDeniedMediaPermission(permissions)
      if (searchResult) {
        !!onDisabledMedia && onDisabledMedia()
      } else {
        !!onAllowedMedia && onAllowedMedia()
      }
    }
    init(true)
  }, [permissions])

  const searchForDeniedMediaPermission = (permissions: MediaPermissions) => {
    let result = false
    Object.keys(permissions).forEach((permission) => {
      //@ts-ignore
      const state = permissions[permission]
      if (state !== "granted") {
        result = true
      }
    })
    return result
  }
}
