import type { RouteObject } from "react-router-dom"
import { createBrowserRouter, Navigate } from "react-router-dom"

import { MainPage } from "@/pages/MainPage"
import { AuthPage } from "@/pages/AuthPage"

import { ProtectedLayout } from "@/entities/user"
import { ErrorBoundary } from "@/shared/ui/layout/ErrorBoundary"

const routes: RouteObject[] = [
  {
    path: "/",
    errorElement: <ErrorBoundary />,
    element: <ProtectedLayout />,
    children: [
      {
        index: true,
        errorElement: <ErrorBoundary />,
        element: <MainPage />,
      },
      {
        path: "/auth",
        errorElement: <ErrorBoundary />,
        element: <AuthPage />,
      },
    ],
  },
  {
    path: "/*",
    element: <Navigate to="/" />,
  },
]

export const router = createBrowserRouter(routes)
