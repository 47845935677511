/**
 * @route POST /api/dev/v2/campaign_id={campaign_id}/extensions/messages/temp
 * @route POST /api/dev/v2/campaign_id={campaign_id}/extensions/messages
 * */
export const MESSAGE_TYPES = {
  UPDATE: "UPDATE",
  CREATE: "CREATE",
  COMMENT: "COMMENT",
  TELEPHONY: "TELEPHONY",
  MESSAGE: "MESSAGE",
  NO_TYPE: "NO_TYPE",
  ONLY_FOR_ADMIN: "ONLY_FOR_ADMIN",
}

export const MessagesTypesTransriptions = {
  UPDATE: "Обновления",
  CREATE: "Создание",
  COMMENT: "Комментарии",
  TELEPHONY: "Телефония",
  MESSAGE: "WhatsApp",
  NO_TYPE: "Без типа",
  ONLY_FOR_ADMIN: "Администратор",
}

type ValueOf<T> = T[keyof T]

export type MessageTypesEnum = ValueOf<typeof MESSAGE_TYPES>

export interface GetExtensionsLogsParams {
  object_id: number
  last_at?: string
  page?: number
  limit?: number
}

interface GetMessagesFilterElement {
  field_id: -1
  values: Array<MessageTypesEnum>
}

export type GetMessagesUsedCursorData = Array<GetMessagesFilterElement>

/**
 * Если не использовать параметр @last_at, запрос отдаст список в обратном порядке
 */
export interface GetMessagesUsedCursorParams {
  object_id: number
  last_at?: string
  limit?: number
  direction?: MessagesDirection
}

export enum MessagesDirection {
  Down = "UP",
  Up = "DOWN",
}

export interface GetExtensionsLogsData {
  id: number
  object_id: number
  data: {
    icon: {
      position: string | null
      name: string | null
      color: string | null
      backgroundColor: string | null
    }
    card: {
      backgroundColor: string | null
      color: string | null
    }
    title: {
      text: string | null
      comment: string | null
      tag: string | null
    }
    body: {
      text: string | null
      comment: string | null
      tag: string | null
    }
    attachments: {
      img: string | null
      audio: string | null
      video: string | null
      document: string | null
      location: string | null
    }
  }
  user: {
    id: number
    name: string
    email: string
    phone: string
    image_url: string
    created_at: string
  }
  type: MessageTypesEnum
  created_at: string
}

/**
 * @route POST /api/dev/v2/campaign_id={campaign_id}/extensions/log/create-comment
 * */
export interface CreateCommentLogParams {
  object_id: number
}
export interface CreateCommentLogBody {
  text: string
  only_for_admin: boolean
}
export interface CreateCommentLogData {
  id: number
}
