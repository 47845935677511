import styles from "./index.module.css"
import { useRouteError } from "react-router-dom"
import * as Sentry from "@sentry/react"
import { useEffect } from "react"

const ErrorBoundary = () => {
  let error = useRouteError()

  useEffect(() => {
    Sentry.captureException(error)
    console.error(error)
  }, [])

  return <div className={styles.Container}>{JSON.stringify(error)}</div>
}

export default ErrorBoundary
