import { ModalDefaultLayout } from "@/shared/contexts"
import LostContactRecallContent from "./LostContactRecallContent"

const LostContactRecall = () => {
  return (
    <ModalDefaultLayout>
      <LostContactRecallContent />
    </ModalDefaultLayout>
  )
}

export default LostContactRecall
