import { CallSystemStatuses } from "@/shared/api"

export const StatusConfig = {
  [CallSystemStatuses.FillingForms]: {
    name: "Заполнение лида",
    color: "#de7b09",
    backgroundColor: "#fff",
  },
  [CallSystemStatuses.Waiting]: {
    name: "Готов (Ожидание)",
    color: "#9a9a9a",
    backgroundColor: "#fff",
  },
  [CallSystemStatuses.NotHere]: {
    name: "Не на месте",
    color: "#d00f2f",
    backgroundColor: "#93493422",
  },
  [CallSystemStatuses.Education]: {
    name: "Обучение",
    color: "#770cd0",
    backgroundColor: "#fff",
  },
  [CallSystemStatuses.CallInProcess]: {
    name: "Идёт разговор",
    color: "#65c00b",
    backgroundColor: "#fff",
  },
}

export enum AudioBells {
  Start = "Start",
  End = "End",
  CallRequest = "CallRequest",
}

export interface CurrentClient {
  call_id: number
  schema_id: number
  phone: string
  call_count: number
  message_text: string
  crm_object_stage: any
  client_name: string
  client_id: number
  crm_object_id: number
  lead_url: string
}
