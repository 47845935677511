import { CurrentClient } from "@/entities/call"
import { TextInput } from "@/shared/ui/components/inputs"
import { useEffect, useState } from "react"
import { objectsApi } from "@/shared/api"
import { ObjectGroupField } from "@/shared/api/model/objects/types.ts"
import { ConfigProvider } from "antd"

interface EditClientNameProps {
  contact: CurrentClient | null
}

export const EditClientName = ({ contact }: EditClientNameProps) => {
  const [nameField, setNameField] = useState<ObjectGroupField | null>(null)

  useEffect(() => {
    if (contact?.client_id) {
      objectsApi.getObject({ id: contact.client_id }).then((result) => {
        setNameField(result.data.main_element.title)
      })
    }
  }, [contact?.client_id])

  const handleUpdateValue = async () => {
    if (!nameField || !contact?.client_id) return
    const { id, values } = nameField
    const data = [
      {
        field_id: id,
        values: values,
      },
    ]
    try {
      await objectsApi.updateObject({ id: contact.client_id }, { data })
    } catch (e) {
      console.error(e)
    }
  }

  const handleValue = (value: string) => {
    setNameField((prev) => {
      if (!prev) return prev
      return {
        ...prev,
        values: [value],
      }
    })
  }

  if (!contact || !nameField) return "Узнаём имя.."
  return (
    <div>
      <ConfigProvider
        theme={{
          token: {
            colorBgContainer: "transparent",
          },
        }}>
        <TextInput
          onBlur={handleUpdateValue}
          style={{
            padding: 0,
            margin: 0,
            fontSize: 20,
          }}
          placeholder={"Укажите имя"}
          value={nameField?.values[0]}
          onChange={handleValue}
        />
      </ConfigProvider>
    </div>
  )
}
