import { extensionsLogsApi } from "@/shared/api"
import type { ExtensionsLogsTypes } from "@/shared/api"
import { GetMessagesUsedCursorData, GetMessagesUsedCursorParams } from "@/shared/api/model/extensionsLogs/types.ts"

export const useExtensionsLogs = () => {
  const getMessagesCursorUsed = async (
    params: GetMessagesUsedCursorParams,
    data: GetMessagesUsedCursorData,
    signal?: AbortSignal,
  ) => {
    try {
      const response = await extensionsLogsApi.getLogsByCursor(params, data, signal)
      return response.data
    } catch (error) {
      throw new Error(JSON.stringify(error))
    }
  }

  const createCommentLog = async (
    params: ExtensionsLogsTypes.CreateCommentLogParams,
    body: ExtensionsLogsTypes.CreateCommentLogBody,
  ) => {
    try {
      const response = await extensionsLogsApi.createCommentLog(params, body)
      return response.data
    } catch (error: any) {
      throw new Error(error)
    }
  }

  return { createCommentLog, getMessagesCursorUsed }
}
