import { MiniModalLayout } from "@/shared/ui/layout"
import { IconSettings2, IconVolume } from "@tabler/icons-react"
import styles from "./index.module.css"
import { IconButton } from "@/shared/ui/components/buttons"
import { AudioBells } from "@/entities/call"
import cn from "classnames"
import { Slider } from "antd"
import { useEffect, useState } from "react"

const RANGE = [1, 20]

interface ChangeEdgesVolumeWidgetProps {
  playAudioBell: (direction: AudioBells) => Promise<any>
  changeInitialAudioVolumes: (direction: AudioBells | "Stream", value: number) => void
  getVolumes: (value: number[]) => any
}

const ChangeEdgesVolumeWidget = (props: ChangeEdgesVolumeWidgetProps) => {
  const { playAudioBell, changeInitialAudioVolumes, getVolumes } = props
  const [values, setValues] = useState({
    [AudioBells.End]: 1,
    [AudioBells.Start]: 1,
    [AudioBells.CallRequest]: 1,
    Stream: 1,
  })

  const onControlClick = async (direction: AudioBells) => {
    await playAudioBell(direction)
  }

  const onChangeSlider = (direction: AudioBells | "Stream", value: number) => {
    changeInitialAudioVolumes(direction, interpolateVolumeValue(value))
    setValues((prev) => ({
      ...prev,
      [direction]: value,
    }))
  }

  const interpolateVolumeValue = (value: number) => {
    return ((100 / RANGE[1]) * value) / 100
  }

  useEffect(() => {
    setValues(getVolumes(RANGE))
  }, [])

  return (
    <MiniModalLayout
      header={
        <IconButton
          icon={<IconSettings2 />}
          disabled={false}
        />
      }>
      <div className={cn(styles.Container)}>
        <div className={cn(styles.Title)}>Настройка аудио</div>
        <div>
          <div className={cn(styles.Label)}>Громкость дозвона</div>
          <div className={cn(styles.Controls)}>
            <Slider
              style={{ flex: 1 }}
              min={RANGE[0]}
              max={RANGE[1]}
              onChange={(value) => onChangeSlider(AudioBells.CallRequest, value)}
              value={values[AudioBells.CallRequest]}
            />
            <IconButton
              onClick={() => onControlClick(AudioBells.CallRequest)}
              icon={<IconVolume />}
              disabled={false}
            />
          </div>
          <div>Громкость начала звонка</div>
          <div className={cn(styles.Controls)}>
            <Slider
              style={{ flex: 1 }}
              min={RANGE[0]}
              max={RANGE[1]}
              onChange={(value) => onChangeSlider(AudioBells.Start, value)}
              value={values[AudioBells.Start]}
            />
            <IconButton
              onClick={() => onControlClick(AudioBells.Start)}
              icon={<IconVolume />}
              disabled={false}
            />
          </div>
          <div>Громкость конца звонка</div>
          <div className={cn(styles.Controls)}>
            <Slider
              style={{ flex: 1 }}
              min={RANGE[0]}
              max={RANGE[1]}
              onChange={(value) => onChangeSlider(AudioBells.End, value)}
              value={values[AudioBells.End]}
            />
            <IconButton
              onClick={() => onControlClick(AudioBells.End)}
              icon={<IconVolume />}
              disabled={false}
            />
          </div>
        </div>
      </div>
    </MiniModalLayout>
  )
}

export default ChangeEdgesVolumeWidget
