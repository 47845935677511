import styles from "./index.module.css"
import { motion } from "framer-motion"
import { forwardRef, ReactNode, useImperativeHandle, useState } from "react"
import { IconX } from "@tabler/icons-react"

interface ModalTemplateProps {
  style?: any
  closeDisabled?: boolean
  children: ReactNode
}

const ModalTemplate = forwardRef(({ style, children, closeDisabled }: ModalTemplateProps, parentRef) => {
  const [isOpen, setIsOpen] = useState(false)

  useImperativeHandle(
    parentRef,
    () => ({
      show: () => handleOpenModal(),
      hide: () => handleCloseModal(),
    }),
    [],
  )

  const handleOpenModal = () => {
    setIsOpen(true)
  }

  const handleCloseModal = () => {
    setIsOpen(false)
  }

  if (!isOpen) return null
  return (
    <motion.div
      variants={BackDropVariants}
      initial={"hide"}
      animate={isOpen ? "show" : "hide"}
      className={styles.Backdrop}>
      <motion.div
        variants={ModalVariants}
        initial={"hide"}
        animate={isOpen ? "show" : "hide"}
        style={style}
        className={styles.Modal}>
        {!closeDisabled && (
          <div
            onClick={handleCloseModal}
            className={styles.ModalCloseButton}>
            <IconX size={18} />
          </div>
        )}
        {children}
      </motion.div>
    </motion.div>
  )
})

const BackDropVariants = {
  show: {
    transition: {
      opacity: {
        duration: 0.1,
        delay: 0,
      },
      zIndex: {
        duration: 0,
        delay: 0,
      },
    },
    opacity: 1,
    zIndex: 99999,
  },
  hide: {
    transition: {
      opacity: {
        duration: 0.3,
        delay: 0,
      },
      zIndex: {
        duration: 0.3,
        delay: 0,
      },
    },
    opacity: 0,
    zIndex: -1,
  },
}

const ModalVariants = {
  show: {
    transition: {
      scale: {
        delay: 0.2,
      },
      opacity: {
        delay: 0.2,
      },
    },
    scale: 1,
    opacity: 1,
  },
  hide: {
    transition: {
      scale: {
        delay: 0.2,
      },
      opacity: {
        delay: 0.2,
      },
    },
    scale: 0.9,
    opacity: 0,
  },
}

export default ModalTemplate
