import * as allIcons from "@tabler/icons-react"

interface IconProps {
  name: string
  size?: number
  color?: string
  stroke?: number
}

const kebabToCamel = <T extends keyof typeof allIcons>(s: string): T => {
  const strCamel = s.replace(/-./g, (x) => x[1].toUpperCase())
  return (strCamel.slice(0, 1).toUpperCase() + strCamel.slice(1)) as T
}

const TablerIcon = (props: IconProps) => {
  const { name, size = 24, color = "currentColor", stroke = 2 } = props

  const Icon = allIcons[kebabToCamel(`icon-${name}`)] as any // TODO

  return (
    <Icon
      size={size}
      color={color}
      stroke={stroke}
    />
  )
}

export default TablerIcon
