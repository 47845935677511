import styles from "./index.module.css"
import { useAppSelector } from "@/shared/hooks"
import { cloneElement, ReactElement } from "react"
import cn from "classnames"
import { IconDatabase } from "@tabler/icons-react"

interface DefaultLeadLayout {
  width: number
  children: ReactElement
  description: {
    title: string
    func: Array<string>
    label: string
  }
}

const DefaultLeadLayout = ({ children, width, description }: DefaultLeadLayout) => {
  const contact = useAppSelector((state) => state.call.client)

  if (!contact)
    return (
      <div
        className={cn(styles.Container)}
        style={{ width }}>
        <div className={styles.Label}>{description.label}</div>
        <div className={styles.Description}>{description.title}</div>
        <div className={styles.Functions}>
          <h4>Функции виджета:</h4>
          {description.func.map((func, index) => (
            <span key={index}>-{func}</span>
          ))}
        </div>
        <div>
          <IconDatabase
            color={"#cbc8cc"}
            size={40}
            stroke={2}
          />
        </div>
        <div>Пусто (Empty)</div>
      </div>
    )
  return (
    <div style={{ width }}>
      {cloneElement(children, { contact, leadId: contact.crm_object_id, callId: contact.call_id })}
    </div>
  )
}

export default DefaultLeadLayout
