import { useImperativeHandle, useLayoutEffect, useRef, useState } from "react"

import { useModalsContext } from "@/shared/contexts"
import { ModalDefault } from "./ModalDefault"

interface ModalDefaultLayoutProps {
  children: JSX.Element
  hasBackDrop?: boolean
}

type ChangeModalState = {
  displayName: string
  params: any
} | null

const ModalDefaultLayout = (props: ModalDefaultLayoutProps) => {
  const { children, hasBackDrop = true } = props
  const [isOpen, setIsOpen] = useState(false)
  const [isShow, setIsShow] = useState(false)
  const imperativeModalRef = useRef<any>(null)
  const modalParams = useRef<object | null>(null)
  const changeModalData = useRef<ChangeModalState>(null)

  const { initializeModal, openModal } = useModalsContext()

  const handleCloseModal = () => {
    setIsOpen(false)
  }

  const handleShowModal = (params: any) => {
    modalParams.current = params
    setIsOpen(true)
    setIsShow(true)
  }

  const onModalHide = () => {
    setIsShow(false)
    if (changeModalData?.current?.displayName) {
      const { displayName, params } = changeModalData.current
      setTimeout(() => {
        openModal(displayName, params)
        changeModalData.current = null
      })
    }
  }

  const handleChangeModal = (displayName: string, params: any) => {
    handleCloseModal()
    changeModalData.current = { displayName, params }
  }

  useLayoutEffect(() => {
    initializeModal(children.type.displayName, imperativeModalRef)
  }, [])

  useImperativeHandle(
    imperativeModalRef,
    () => ({
      show: handleShowModal,
      hide: handleCloseModal,
    }),
    [],
  )

  if (!isShow) {
    return null
  }
  return (
    <ModalDefault
      params={modalParams.current}
      isOpen={isOpen}
      hasBackDrop={hasBackDrop}
      closeModal={handleCloseModal}
      onModalHide={onModalHide}
      changeModal={handleChangeModal}>
      {children}
    </ModalDefault>
  )
}

export default ModalDefaultLayout
