import * as JsSIP from "jssip"
import { DisconnectEvent, RegisteredEvent, RTCSessionEvent, UAEventMap, UnRegisteredEvent } from "jssip/lib/UA"
import { RTCSession, RTCSessionEventMap } from "jssip/lib/RTCSession"
import { useEffect, useRef, useState } from "react"
import { getContactFromEvent, useWebRTCAudioElements } from "@/entities/call"
import { callCenterApi } from "@/shared/api"
import { timeout } from "@/shared/helpers"

interface JsSipInterface extends JsSIP.UA {}

interface RTCAddStreamEvent {
  stream: any
}

const JsSipConfig = {
  uri: import.meta.env.VITE_JSSIP_URI,
  password: import.meta.env.VITE_JSSIP_PASSWORD,
  sockets: [new JsSIP.WebSocketInterface(import.meta.env.VITE_JSSIP_SOCKET_FIRST)],
}

const INCOME_CALL_MEDIA_CONSTRAINTS = {
  audio: true,
  video: false,
}

const JS_SIP_LISTENERS: { [key: string]: keyof UAEventMap } = {
  NEW_RTC_SESSION: "newRTCSession",
  REGISTERED: "registered",
  DISCONNECTED: "disconnected",
  UNREGISTERED: "unregistered",
  REGISTRATION_FAILED: "registrationFailed",
  REGISTRATION_EXPIRED: "registrationExpiring",
  CONNECTING: "connecting",
  CONNECTED: "connected",
}

const JS_SIP_SESSION_LISTENERS: { [key: string]: keyof RTCSessionEventMap } = {
  CONFIRMED: "confirmed",
  ENDED: "ended",
  FAILED: "failed",
  PEER_CONNECTION: "peerconnection",
  ACCEPTED: "accepted",
}

const EXTERNAL_EVENTS = {
  ADD_STREAM: "addstream",
  INCOMING: "incoming",
}

export const WEBRTC_LOCAL_STATUSES = {
  KILLED: "KILLED",
  KILLING_WAITING: "KILLING_IN_PROCESS",
  CONNECTED: "CONNECTED",
  CONNECTING_WAITING: "CONNECTING_IN_PROCESS",
  IN_SESSION: "IN_SESSION",
  IN_SESSION_WAITING: "IN_SESSION_WAITING",
}

interface WebRTCInitiator {
  onCallAccepted: () => void
}

export const useWebrtcInitiator = ({ onCallAccepted }: WebRTCInitiator) => {
  const [status, setStatus] = useState(WEBRTC_LOCAL_STATUSES.KILLED)
  const webRTCConnection = useRef<null | JsSipInterface>(null)
  const incomeCallSession = useRef<null | RTCSession>(null)

  const { setSessionAudioStream, playSound, getAudioElementsVolumes, setInitialAudioVolumes, stopSound } =
    useWebRTCAudioElements()

  const _performWebRTCConnection = async () => {
    _killCurrentWebRTCCallSession()
    if (webRTCConnection.current) {
      return
    }
    setStatus(WEBRTC_LOCAL_STATUSES.CONNECTING_WAITING)
    await timeout(1600)
    return new Promise((resolve) => {
      webRTCConnection.current = new JsSIP.UA(JsSipConfig)
      webRTCConnection.current.on(JS_SIP_LISTENERS.NEW_RTC_SESSION, _onWebRTCNewRTCSession)
      webRTCConnection.current.on(JS_SIP_LISTENERS.REGISTERED, (event: RegisteredEvent) =>
        _onWebRTCRegistered(event, resolve),
      )
      webRTCConnection.current.on(JS_SIP_LISTENERS.UNREGISTERED, _onWebRTCUnRegistered)
      webRTCConnection.current.on(JS_SIP_LISTENERS.DISCONNECTED, _onWebRTCDisconnected)
      webRTCConnection.current?.start()
    })
  }

  const _onWebRTCUnRegistered = (_: UnRegisteredEvent) => {
    setStatus(WEBRTC_LOCAL_STATUSES.KILLED)
  }

  const _onWebRTCDisconnected = (_: DisconnectEvent) => {
    setStatus(WEBRTC_LOCAL_STATUSES.KILLED)
  }

  const _onWebRTCRegistered = async (event: RegisteredEvent, callback: (value: unknown) => void) => {
    try {
      const contact = getContactFromEvent(event, webRTCConnection.current)
      await callCenterApi.activateContactUri({ contact_uri: contact })
    } catch (error) {
      console.warn(error)
    } finally {
      setStatus(WEBRTC_LOCAL_STATUSES.CONNECTED)
      !!callback && callback(undefined)
    }
  }

  const _onWebRTCNewRTCSession = (event: RTCSessionEvent) => {
    setStatus(WEBRTC_LOCAL_STATUSES.IN_SESSION_WAITING)
    _killCurrentWebRTCCallSession()
    incomeCallSession.current = event.session
    if (incomeCallSession.current.direction === EXTERNAL_EVENTS.INCOMING) {
      _performIncomeCallSession().finally()
      incomeCallSession.current.on(JS_SIP_SESSION_LISTENERS.CONFIRMED, _onSessionConfirmed)
      incomeCallSession.current.on(JS_SIP_SESSION_LISTENERS.ACCEPTED, _onSessionAccepted)
      incomeCallSession.current.on(JS_SIP_SESSION_LISTENERS.FAILED, _onSessionFailed)
      incomeCallSession.current.on(JS_SIP_SESSION_LISTENERS.ENDED, _onSessionEnded)
      incomeCallSession.current.on(JS_SIP_SESSION_LISTENERS.PEER_CONNECTION, _onSessionPeerConnection)
    }
  }

  const _onSessionAccepted = () => {}

  const _onSessionConfirmed = () => {}

  const _onSessionPeerConnection = () => {}

  const _onSessionFailed = () => {
    setStatus(WEBRTC_LOCAL_STATUSES.CONNECTED)
  }

  const _onSessionEnded = async () => {
    setStatus(WEBRTC_LOCAL_STATUSES.CONNECTED)
    _killWebRTCConnection()
  }

  const _performIncomeCallSession = async () => {
    incomeCallSession.current?.answer({ mediaConstraints: INCOME_CALL_MEDIA_CONSTRAINTS })
    incomeCallSession.current?.connection?.addEventListener(
      EXTERNAL_EVENTS.ADD_STREAM,
      _onPerformedIncomeCallSessionAddStream,
    )
  }

  const _onPerformedIncomeCallSessionAddStream = async (event: RTCAddStreamEvent | any) => {
    setSessionAudioStream(event.stream)
    setStatus(WEBRTC_LOCAL_STATUSES.IN_SESSION)
    const protocol_id = (incomeCallSession.current as any)?._request?.call_id
    try {
      if (!!protocol_id) {
        await callCenterApi.sendVerifyProtocolId({ protocol_id })
      }
    } catch (e) {}
    onCallAccepted()
  }

  const _killWebRTCConnection = () => {
    if (!webRTCConnection.current) return
    setStatus(WEBRTC_LOCAL_STATUSES.KILLING_WAITING)
    _killCurrentWebRTCCallSession()
    webRTCConnection.current?.terminateSessions()
    webRTCConnection.current?.unregister()
    webRTCConnection.current?.stop()
    webRTCConnection.current = null
  }

  const _killCurrentWebRTCCallSession = () => {
    setSessionAudioStream(null)
    if (incomeCallSession.current?.isEstablished()) {
      incomeCallSession.current?.terminate()
      incomeCallSession.current = null
    }
  }

  useEffect(() => {
    console.log(`%cWEB_RTC_STATUS: ${status}`, "color: Orange")
  }, [status])

  /**
   * Ниже приведены методы для public API
   */
  const killWebRTCConnection = () => _killWebRTCConnection()

  const createWebRTCConnectionIfNeeded = () => _performWebRTCConnection()

  return {
    setInitialAudioVolumes: setInitialAudioVolumes,
    playAudioElementSound: playSound,
    stopAudioElementSound: stopSound,
    getAudioElementsVolumes,
    createWebRTCConnectionIfNeeded,
    webRTCStatus: status,
    killWebRTCConnection,
  }
}

// const initialCallConfig = {
//   wait_lead_time: 10000,
// }

// try {
//   const result = await callCenterApi.getCallConfig()
//   callConfig.current.wait_lead_time = result.data.wait_lead_time
// } catch (e) {
// }
