import { formatDate } from "@/shared/helpers/format-date.ts"
import styles from "./index.module.css"

interface DateTagProps {
  createdAt: string
}

const DateTag = (props: DateTagProps) => {
  const { createdAt } = props

  return (
    <div className={styles.date}>
      <span>{formatDate(createdAt)}</span>
    </div>
  )
}

export default DateTag
