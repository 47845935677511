import styles from "./index.module.css"
import { IconCopy, IconCup, IconPencil, IconPhoneCall, IconSchool } from "@tabler/icons-react"
import { CircleLoader } from "@/shared/ui/components/loaders"
import cn from "classnames"
import { CallSystemStatuses } from "@/shared/api"
import { useAppSelector } from "@/shared/hooks"
import { CurrentClient } from "@/entities/call"
import { copyToClipBoard, removeLetters, phoneFormatter } from "@/shared/helpers"
import { toast } from "react-toastify"
import { EditClientName } from "./EditClientName"
import { useEffect } from "react"
import * as Sentry from "@sentry/react"

interface CallStatusNodeProps {
  status: CallSystemStatuses
  onClientIncome: () => void
}

const ICON_CONFIG = {
  SIZE: 30,
  COLOR: "#666",
}

export const CallStatusNode = ({ status, onClientIncome }: CallStatusNodeProps) => {
  const client = useAppSelector((state) => state.call.client)

  useEffect(() => {
    if (client) {
      !!onClientIncome && onClientIncome()
    }
  }, [client])

  const userPhone = (replaceText: string) => (client ? <TextToCopy text={phoneFormatter(client.phone)} /> : replaceText)

  const getContent = (status: CallSystemStatuses, client: CurrentClient | null) => {
    switch (status) {
      case CallSystemStatuses.NotHere:
        return {
          jsx: (
            <IconCup
              color={ICON_CONFIG.COLOR}
              size={ICON_CONFIG.SIZE}
            />
          ),
          backgroundColor: null,
          title: "Нет на месте",
          description: "Пью кофе",
        }
      case CallSystemStatuses.Waiting:
        return {
          jsx: <CircleLoader />,
          backgroundColor: null,
          title: client?.client_name || "Поиск задач",
          description: userPhone("Очень сильно стараемся..."),
        }
      case CallSystemStatuses.CallInProcess:
        return {
          jsx: (
            <IconPhoneCall
              size={ICON_CONFIG.SIZE - 4}
              color={"#fff"}
            />
          ),
          backgroundColor: "rgba(9,183,27,0.85)",
          title: <EditClientName contact={client} />,
          description: userPhone("Идёт разговор"),
        }
      case CallSystemStatuses.Education:
        return {
          jsx: (
            <IconSchool
              color={ICON_CONFIG.COLOR}
              size={ICON_CONFIG.SIZE}
            />
          ),
          backgroundColor: null,
          title: "На обучении",
          description: "Получаю знания",
        }
      case CallSystemStatuses.FillingForms:
        return {
          jsx: (
            <IconPencil
              color={ICON_CONFIG.COLOR}
              size={ICON_CONFIG.SIZE}
            />
          ),
          backgroundColor: null,
          title: <EditClientName contact={client} />,
          description: userPhone("Записываю информацию"),
        }
      default:
        return {
          jsx: <CircleLoader />,
          backgroundColor: null,
          title: "Поиск клиента остановлен",
          description: "Не понимаем что происходит",
        }
    }
  }

  const currentContent = () => {
    try {
      return getContent(status, client)
    } catch (e) {
      Sentry.captureException(JSON.stringify(e))
      return {
        jsx: "Error",
        title: "Something Wrong",
        description: "Be careful, we gonna fix it",
        backgroundColor: "#f1f1f1",
      }
    }
  }

  return (
    <div className={cn(styles.NodeWrapper)}>
      <div
        className={cn(styles.ClientContainer)}
        style={{ backgroundColor: currentContent().backgroundColor || "#0001" }}>
        {currentContent().jsx}
      </div>
      <div>
        <div className={cn(styles.ClientName)}>{currentContent().title}</div>
        <div className={cn(styles.CallStatus)}>{currentContent().description}</div>
      </div>
    </div>
  )
}

const TextToCopy = ({ text }: { text: string }) => {
  const onTextCopyClick = async () => {
    await copyToClipBoard(removeLetters(text))
    toast.info("Номер успешно скопирован", { icon: false })
  }

  return (
    <div
      onClick={onTextCopyClick}
      className={styles.TextCopyContainer}>
      <div>{text}</div>
      <IconCopy size={16} />
    </div>
  )
}
