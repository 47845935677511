import { useEffect, useRef } from "react"
import cn from "classnames"

import { DefaultLeadLayout } from "@/shared/ui/layout"
import { WIDGETS_DESCRIPTIONS } from "@/shared/root"
import { useAppSelector } from "@/shared/hooks"

import { LeadObjectEditorWidget } from "@/widgets/LeadObjectEditorWidget"
import { SocketConnectionWidget } from "@/widgets/SocketConnectionWidget"
import { StatusDependedWidget } from "@/widgets/StatusDependedWidget"
import { NestedLeadObjects } from "@/widgets/NestedLeadObjects"
import { LeadLogsWidget } from "@/widgets/LeadLogsWidget"
import { CallWidget } from "@/widgets/CallWidget"
import { SideMenu } from "@/widgets/SideMenu"

import styles from "./index.module.css"

const MainPage = () => {
  const messagesContainerRef = useRef<any>(null)
  const socketConnection = useAppSelector((state) => state.app.isSocketConnected)
  const lastCallWidgetHeight = useRef(0)

  const recalculateHeightOfMessages = (changedHeight: number) => {
    if (messagesContainerRef.current) {
      lastCallWidgetHeight.current = changedHeight
      messagesContainerRef.current.calculateHeight(changedHeight)
    }
  }

  const onWindowResize = () => {
    recalculateHeightOfMessages(lastCallWidgetHeight.current)
  }

  const addWindowResizeListener = () => {
    window.addEventListener("resize", onWindowResize)
  }

  const removeWindowResizeListener = () => {
    window.removeEventListener("resize", onWindowResize)
  }

  useEffect(() => {
    addWindowResizeListener()
    return () => {
      removeWindowResizeListener()
    }
  }, [])

  return (
    <div>
      <SocketConnectionWidget show={!socketConnection} />
      {!socketConnection && <div className={cn(styles.DisabledConnectionCurtain)}></div>}
      <div className={cn(styles.Container)}>
        <SideMenu />
        <div className={cn(styles.Column, styles.FlexColumn)}>
          <CallWidget onHeightChanged={recalculateHeightOfMessages} />
          <DefaultLeadLayout
            description={WIDGETS_DESCRIPTIONS.HISTORY}
            width={400}>
            <LeadLogsWidget
              ref={messagesContainerRef}
              leadId={-1}
            />
          </DefaultLeadLayout>
        </div>
        <div className={cn(styles.Column, styles.ColumnScrollable)}>
          <DefaultLeadLayout
            description={WIDGETS_DESCRIPTIONS.NESTED}
            width={450}>
            <NestedLeadObjects />
          </DefaultLeadLayout>
          <DefaultLeadLayout
            description={WIDGETS_DESCRIPTIONS.LEAD}
            width={450}>
            <LeadObjectEditorWidget leadId={-1} />
          </DefaultLeadLayout>
        </div>
        <div className={cn(styles.ScriptContainer)}>
          <StatusDependedWidget />
        </div>
      </div>
    </div>
  )
}

export default MainPage
