import { DatePicker } from "antd"
import dayjs from "dayjs"
import customParseFormat from "dayjs/plugin/customParseFormat"
dayjs.extend(customParseFormat)
import locale from "antd/es/date-picker/locale/ru_RU"
import "dayjs/locale/ru"

const dateFormat = "DD.MM.YYYY"

interface DateSelectProps {
  onChange: (value: string) => void
  value: string | null
}

const DateSelect = (props: DateSelectProps) => {
  const { onChange, value } = props

  const handleChangeDate = (value: any) => {
    if (!value) {
      onChange("")
      return
    }
    const result = new Date(value).toISOString()
    onChange(result)
  }

  return (
    <div>
      <DatePicker
        value={value === null ? undefined : dayjs(value)}
        locale={locale}
        size={"small"}
        mode={"date"}
        onChange={handleChangeDate}
        format={dateFormat}
      />
    </div>
  )
}

export default DateSelect
