import Ajv from "ajv"
import { ERequestKind, errorService, responseService } from "./api-helpers"

const ajv = new Ajv()

class Api {
  private client: any
  constructor(client: any) {
    this.client = client
    this.client.interceptors.response.use(
      (response: any) => {
        return response
      },
      (e: any) => {
        return Promise.reject(e.response)
      },
    )
  }

  async request(url: string, data = null, method = "get", params = null, signal = null) {
    try {
      return await this.client({ url, data, method, params, signal })
    } catch (e) {
      return Promise.reject(e)
    }
  }
}

class ApiEndpoint {
  private api: any
  private schema: any
  private validate: any
  constructor(api: any, schema: object) {
    this.api = api
    this.schema = schema
    this.validate = ajv.compile(this.schema)
  }

  validateResult(response: any) {
    return this.validate(response)
  }

  transferResult(response: any): { kind: ERequestKind; data: any } {
    if (this.validateResult(response)) {
      return responseService(ERequestKind.SUCCESS, response)
    }
    return responseService(ERequestKind.INVALID, null)
  }

  request = (
    url: string,
    data: object | null = {},
    method: string = "get",
    params: object | null = {},
    signal: any,
  ): any => {
    return new Promise((resolve, reject) => {
      this.api
        .request(url, data, method, params, signal)
        .then((response: any) => {
          resolve(this.transferResult(response.data))
        })
        .catch((e: any) => {
          reject(errorService(e?.status, e?.data))
        })
        .finally(() => {})
    })
  }
}

export { Api, ApiEndpoint }
