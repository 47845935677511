export const formatPrice = (price: string) => {
  if (!price) {
    return ""
  }
  let value = price.toString()
  value = "" + value.replace(/\D/g, "")
  if (value.length > 1 && value[0] === "0") {
    value = value.slice(1)
  }
  return (value + "")
    .split("")
    .reverse()
    .join("")
    .replace(/(\d{3})/g, "$1 ")
    .split("")
    .reverse()
    .join("")
    .replace(/^ /, "")
}
