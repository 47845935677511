export const getMMSS = (value: number) => {
  let sec_num = value
  let hours: any = Math.floor(sec_num / 3600)
  let minutes: any = Math.floor((sec_num - hours * 3600) / 60)
  let seconds: any = (sec_num - hours * 3600 - minutes * 60).toFixed(0)

  if (minutes < 10) {
    minutes = "0" + minutes
  }
  if (seconds < 10) {
    seconds = "0" + seconds
  }
  return minutes + ":" + seconds
}
