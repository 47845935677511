export interface ComplexFlatPricing {
  rooms: number
  area: [number, number]
  price: [number, number]
}

export interface ComplexObject {
  id: number
  name: string
  address: string
  address_yandex: string
  longitude: number
  latitude: number
  images: string[]
  minio_images: string[]
  directions: Direction[]
  city: City
  building_type: Building[]
  ready_year: null
  ready_quarter: null
  metro: string
  metro_color: string
  time_on_foot: number
  time_on_car: number
  developer: {
    id: number
    name: string
    logo_small: string | null
    logo_large: string | null
    description: string
    is_active: boolean
    founding_date: number
  }
  new_description: string
  ready_year_from: number
  ready_year_to: number
  apartments_count: number
  flats_count: number
  original_url: null | string
  flats: ComplexFlatPricing[]
}

type Direction = {
  id: number
  name: string
}

type City = {
  id: number
  name: string
}

// const a101 = {
//   type: "developer",
//   value: 128,
// }
//
// const samolet = {
//   type: "developer",
//   value: 1,
// }

// Местоположение direction 1188301

// parking?: Parking[]
// building_type?: Building[]

export interface ComplexesFilterPayload {
  filter: {
    rooms?: number[] // 1188302
    area?: Array<string> // [1188347, 1188348]
    price?: Array<string> // [1188340, 1188341]
    floor?: Array<string> // [1188345, 1188346]
    ceiling_height?: Array<string> // [1188349, 1188350]
    renovation?: Renovation[] // 1188342
    ready_time?: {
      quarter: number
      year: number // 1188303
    }
    search_field?: {
      type: string
      value: number[] | string[] // 1188343
    }[]
    page: number
  }
  limit: boolean
}

export const RenovationTypes = {
  WhiteBox: "whitebox",
  Without: "without",
} as const

export type Renovation = (typeof RenovationTypes)[keyof typeof RenovationTypes]

export const ParkingTypes = {
  UnderGround: "underground",
  Guest: "guest",
} as const

export type Parking = (typeof ParkingTypes)[keyof typeof ParkingTypes]

export const BuildingTypes = {
  Brick: "brick",
  Monolith: "monolith",
} as const

export type Building = (typeof BuildingTypes)[keyof typeof BuildingTypes]

export const BuildingTranscriptions = {
  monolith: "Монолит",
  brick: "Кирпич",
}
