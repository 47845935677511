import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react"
import { IconAlien, IconArrowNarrowRight } from "@tabler/icons-react"
import { motion } from "framer-motion"
import { toast } from "react-toastify"
import { DatePicker } from "antd"
import cn from "classnames"
import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"
import timezone from "dayjs/plugin/timezone"
dayjs.extend(utc)
dayjs.extend(timezone)

import { triggerNestedObjectsFetchUpdate } from "@/shared/store/slices/objects"
import { Autocomplete } from "@/shared/ui/components/autocompletes"
import { useAppDispatch, useAppSelector } from "@/shared/hooks"
import { LoadingSpinner } from "@/shared/ui/components/loaders"
import { callCenterApi, objectsApi } from "@/shared/api"
import { phoneFormatter } from "@/shared/helpers"

import { MoreButton } from "./MoreButton"
import styles from "./index.module.css"

import locale from "antd/es/date-picker/locale/ru_RU"
const dateFormat = "DD.MM.YYYY HH:mm"
const SEND_ERROR = "Не смогли отправить на перезвон, обратитесь к руководителю"

const getDateHourLater = () => {
  const now = dayjs()
  return now.add(1, "hour")
}

const initialChoices = {
  type: {
    meta_name: "__type__",
    data: [],
  },
  priority: {
    meta_name: "__priority__",
    data: [],
  },
}

const initialValuesState = {
  title: "",
  description: "",
  type: "",
  priority: "",
}

interface SuccessSent {
  phone: string
  client_name: string
  recall_date: string
  call_id: number
}

interface SendToRecallWidgetProps {
  enabled: boolean
}

const SendToRecallWidget = forwardRef((props: SendToRecallWidgetProps, parentRef) => {
  const { enabled } = props
  const contact = useAppSelector((state) => state.call.client)
  const nested = useAppSelector((state) => state.objects.nested)
  const [loading, setLoading] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [isPickerOpen, setIsPickerOpen] = useState<boolean>(false)
  const [choices, setChoices] = useState(initialChoices)
  const [values, setValues] = useState(initialValuesState)
  const [successSentData, setSuccessSentData] = useState<null | SuccessSent>(null)
  const [date, setDate] = useState(getDateHourLater())
  const datePickerRef = useRef<any>(null)
  const dispatch = useAppDispatch()

  const filled_count = Object.values(values).filter((item) => !!item).length + 1

  useEffect(() => {
    if (nested) {
      const schema = nested.filter((item) => item.meta_name === "__task__")?.[0]
      if (!schema) return
      fetchCreationSchema(schema.schema_id).finally()
    }
  }, [nested])

  const fetchCreationSchema = async (schema_id: number) => {
    try {
      const result = await objectsApi.getSchema({ schema_id })
      Object.keys(initialChoices).forEach((key) => {
        const ourMetaName = (initialChoices as any)[key].meta_name
        const choice = result.data.fields.filter((field) => field.meta_name === ourMetaName)[0].choice
        setChoices((prev) => ({
          ...prev,
          [key]: {
            ...(prev as any)[key],
            data: choice.map((item) => ({ id: item.id, value: item.name })),
          },
        }))
      })
    } catch (e) {
      console.log(JSON.stringify(e))
    }
  }

  const handleChangeValue = (key: string, value: string) => {
    setValues((prev) => ({
      ...prev,
      [key]:
        ["title", "description"].includes(key) && !!value
          ? value[0].toUpperCase() + value.slice(1, value.length)
          : value,
    }))
  }

  const autoHeight = (event: any) => {
    const elem = event.target
    elem.style.height = "1px"
    elem.style.height = `${elem.scrollHeight}px`
  }

  const handleSubmit = async () => {
    if (!contact || loading) return
    setLoading(true)
    try {
      const d1 = dayjs.tz(date, "Europe/Moscow")
      const payload = {
        recall_date: d1.format(),
        call_id: contact.call_id,
        ...values,
      }
      await callCenterApi.sendToRecall(payload)
      setSuccessSentData({
        ...payload,
        phone: contact.phone,
        client_name: contact.client_name,
      })
      setTimeout(() => {
        dispatch(triggerNestedObjectsFetchUpdate("__task__"))
      }, 2000)
    } catch (e) {
      toast.error(SEND_ERROR, { icon: false })
    } finally {
      setLoading(false)
    }
  }

  useImperativeHandle(
    parentRef,
    () => ({
      focus: () => setIsPickerOpen(true),
      isRecallWasSent: () => (!contact ? true : !!successSentData?.recall_date && successSentData.call_id),
    }),
    [contact, successSentData],
  )

  useEffect(() => {
    setSuccessSentData(null)
    setDate(getDateHourLater())
    setLoading(false)
    setIsPickerOpen(false)
    setValues(initialValuesState)
    setChoices(initialChoices)
  }, [contact?.call_id])

  if (!contact || !enabled) {
    return null
  }
  if (successSentData?.phone && successSentData.call_id) {
    return (
      <div className={cn(styles.RecallSuccessContainer)}>
        <div className={cn(styles.RecallIcons)}>
          <IconAlien />
        </div>
        <div className={cn(styles.RecallInfo)}>
          <div>
            CallID: {successSentData.call_id} | Recall Date: {successSentData.recall_date}
          </div>
          <div>Контакт {phoneFormatter(successSentData.phone)} был отправлен на перезвон</div>
        </div>
      </div>
    )
  }
  return (
    <motion.div
      initial={{ height: 0, opacity: 0 }}
      animate={{ height: "auto", opacity: 1 }}
      className={cn(styles.Container)}
      style={{ opacity: loading ? 0.6 : 1 }}>
      <div className={cn(styles.Header)}>Перезвонить клиенту</div>
      <div className={cn(styles.FormContainer)}>
        <DatePicker
          onOpenChange={setIsPickerOpen}
          open={isPickerOpen}
          ref={datePickerRef}
          size={"middle"}
          minuteStep={15}
          showTime={true}
          locale={locale}
          disabled={loading}
          format={dateFormat}
          className={cn(styles.DatePicker)}
          value={date}
          onChange={setDate}
        />
        <MoreButton
          active={loading}
          count={filled_count}
          onClick={() => (loading ? undefined : setIsOpen((prev) => !prev))}
        />
      </div>
      <motion.div
        initial={"close"}
        variants={AnimationVariants}
        animate={isOpen ? "open" : "close"}>
        <div className={cn(styles.Form)}>
          <div className={styles.FormRow}>
            {choices.priority.data.length > 0 && (
              <div className={styles.AutoComplete}>
                <div className={styles.AutoCompleteLabel}>Приоритет</div>
                <Autocomplete
                  size={"middle"}
                  disabled={loading}
                  className={styles.AutoCompleteInput}
                  initialValue={""}
                  selected={values.priority}
                  placeholder={"Выбрать"}
                  options={choices.priority.data}
                  onSelect={(values) => handleChangeValue("priority", values)}
                />
              </div>
            )}
            {choices.type.data.length > 0 && (
              <div className={styles.AutoComplete}>
                <div className={styles.AutoCompleteLabel}>Тип задачи</div>
                <Autocomplete
                  size={"middle"}
                  disabled={loading}
                  className={styles.AutoCompleteInput}
                  initialValue={""}
                  selected={values.type}
                  placeholder={"Выбрать"}
                  options={choices.type.data}
                  onSelect={(values) => handleChangeValue("type", values)}
                />
              </div>
            )}
          </div>
          <textarea
            disabled={loading}
            rows={1}
            maxLength={100}
            value={values.title}
            onInput={autoHeight}
            onChange={(e) => handleChangeValue("title", e.target.value)}
            className={cn(styles.TextArea, styles.TextAreaBold)}
            placeholder={"Введите текст (название задачи)"}
          />
        </div>
      </motion.div>
      <div
        onClick={!loading ? handleSubmit : undefined}
        className={styles.SubmitButton}>
        {loading ? (
          <LoadingSpinner
            color={"#fff"}
            size={20}
            style={{ marginBottom: -5 }}
          />
        ) : (
          <IconArrowNarrowRight />
        )}
      </div>
    </motion.div>
  )
})

const AnimationVariants = {
  open: { opacity: 1, height: "auto" },
  close: { opacity: 0, height: 0 },
}

export default SendToRecallWidget
