import { useEffect, useState } from "react"
import { Outlet, useNavigate } from "react-router-dom"

import { PermissionsModal } from "@/widgets/PermissionsModal"

import { useUser } from "@/entities/user"

import { useAppDispatch } from "@/shared/hooks"
import { setUser } from "@/shared/store/slices/user"

const ProtectedLayout = () => {
  const { verifyUser } = useUser()
  const dispatch = useAppDispatch()
  const [isPermissionsAllowed, setIsPermissionsAllowed] = useState(false)
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate()

  useEffect(() => {
    verifyUser()
      .then((result) => {
        navigate("/")
        dispatch(setUser(result.data))
      })
      .catch(() => {
        dispatch(setUser(null))
        navigate("/auth")
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])

  const onDeclinePermissions = () => {
    setIsPermissionsAllowed(false)
  }

  const onSuccessPermission = () => {
    setIsPermissionsAllowed(true)
  }

  if (!isPermissionsAllowed) {
    return (
      <div>
        <PermissionsModal
          onSuccess={onSuccessPermission}
          onDecline={onDeclinePermissions}
        />
      </div>
    )
  }

  if (loading) {
    return null
  }

  return <Outlet />
}

export default ProtectedLayout
