import { useAppSelector } from "@/shared/hooks"
import styles from "./index.module.css"
import cn from "classnames"

export const CurrentUserInfo = () => {
  const user = useAppSelector((state) => state.user.user)
  return (
    <div className={cn(styles.CurrentUserInfo)}>
      <div>{user?.name}</div>
      <div>{user?.email}</div>
    </div>
  )
}
