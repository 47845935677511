import { useState } from "react"
import styles from "./index.module.css"
import cn from "classnames"
import { ScriptDialogWidget } from "@/widgets/ScriptDialogWIdget"
import { ComplexSearcherWidget } from "@/widgets/ComplexSearcherWidget"
import { IconBuildingWarehouse, IconLetterA } from "@tabler/icons-react"
import { LoadingSpinner } from "@/shared/ui/components/loaders"
import { useAppSelector } from "@/shared/hooks"

const IconSize = 15

const DependedWidgets = {
  Script: "SCRIPT",
  Searcher: "SEARCHER",
}

const Descriptions = {
  Script: {
    iconJsx: <IconLetterA size={IconSize} />,
    name: "Скрипт",
  },
  Searcher: {
    iconJsx: <IconBuildingWarehouse size={IconSize} />,
    name: "Комплексы",
  },
}

const initialLoadingStatus = {
  [DependedWidgets.Script]: false,
  [DependedWidgets.Searcher]: false,
}

type WidgetState = (typeof DependedWidgets)[keyof typeof DependedWidgets]

const StatusDependedWidget = () => {
  const contact = useAppSelector((state) => state.call.client)
  const [currentWidget, setWidget] = useState<WidgetState>(DependedWidgets.Script)
  const [loading, setLoading] = useState(initialLoadingStatus)

  if (!contact) return null
  return (
    <div className={cn(styles.Container)}>
      <div className={cn(styles.TabsContainer)}>
        {Object.keys(DependedWidgets).map((key) => {
          const value = (DependedWidgets as any)[key]
          const pretty = (Descriptions as any)[key]
          return (
            <div
              onClick={() => setWidget(value)}
              key={key}
              className={cn(styles.TabElement, currentWidget === value && styles.TabElementActive)}>
              {!loading[value] ? (
                pretty.iconJsx
              ) : (
                <LoadingSpinner
                  color={"#fff"}
                  style={{ marginBottom: -3 }}
                  size={14}
                />
              )}
              <span>{pretty.name}</span>
            </div>
          )
        })}
      </div>
      <div className={cn(styles.Content)}>
        <div
          className={cn(styles.AbsoluteHover, currentWidget !== DependedWidgets.Script && styles.AbsoluteHoverHidden)}>
          <ScriptDialogWidget />
        </div>
        {currentWidget === DependedWidgets.Searcher && (
          <ComplexSearcherWidget
            onEndLoading={() => {
              setLoading((prev) => ({
                ...prev,
                [DependedWidgets.Searcher]: false,
              }))
            }}
            onStartLoading={() =>
              setLoading((prev) => ({
                ...prev,
                [DependedWidgets.Searcher]: true,
              }))
            }
          />
        )}
      </div>
    </div>
  )
}

export default StatusDependedWidget
