export { useExtensionsLogs } from "./model/useExtensionsLogs"
export { default as LogCard } from "./ui/LogCard/LogCard"
export { default as DateTag } from "./ui/DateTag/DateTag"

export const LOGS_TYPES = {
  CREATE: {
    backgroundColor: "transparent",
    color: "#216e98",

    text: "Создание",
  },
  TELEPHONY: {
    backgroundColor: "#ebffed",
    color: "#04b40c",
    text: "Звонок",
  },
  UPDATE: {
    backgroundColor: "transparent",
    color: "#666",
    text: "Обновление",
  },
  COMMENT: {
    backgroundColor: "transparent",
    color: "#111",
    text: "Коментарий",
  },
  MESSAGE: {
    backgroundColor: "transparent",
    color: "#e1c617",
    text: "Сообщение",
  },
}
