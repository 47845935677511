import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { NestedObject, ObjectGroup } from "@/shared/api/model/objects/types"

interface ObjectsState {
  id: number | null
  groups: ObjectGroup[]
  nested: NestedObject[] | null
  nestedMetaNames: {
    __task__: boolean
    __fixation__: boolean
    __client__: boolean
  }
}

const initialState: ObjectsState = {
  id: null,
  groups: [],
  nested: null,
  nestedMetaNames: {
    __task__: false,
    __fixation__: false,
    __client__: false,
  },
}

const objectsSlice = createSlice({
  name: "objects",
  initialState,
  reducers: {
    setGroups: (state, action: PayloadAction<{ id: number; objects: ObjectGroup[]; nested: any }>) => {
      state.id = action.payload.id
      state.nested = action.payload.nested
      state.groups = action.payload.objects
    },
    triggerNestedObjectsFetchUpdate: (state, { payload }) => {
      //@ts-ignore
      state.nestedMetaNames[payload] = !state.nestedMetaNames[payload]
    },
  },
})

export default objectsSlice.reducer

export const { setGroups, triggerNestedObjectsFetchUpdate } = objectsSlice.actions
