import { IconAlertCircle } from "@tabler/icons-react"
import cn from "classnames"
import { toast } from "react-toastify"

import styles from "./index.module.css"
import { useState } from "react"
import { timeout } from "@/shared/helpers"
import { LoadingSpinner } from "@/shared/ui/components/loaders"
import { callCenterApi } from "@/shared/api"

const NotResponseContent = ({ modal: { params, closeModal } }: any) => {
  const [loading, setLoading] = useState(false)

  const handleSubmitModal = async () => {
    params?.onSubmit()
    closeModal()
  }

  const handleResetModal = async () => {
    await handleCloseTask(params?.onReset)
  }

  const handleDeclineModal = async () => {
    await handleCloseTask(params?.onDecline)
  }

  const handleCloseTask = async (callBack: Function) => {
    if (!params?.call_id) return toast.error("Call ID отсутствует, обратитесь к администратору.", { icon: false })
    try {
      setLoading(true)
      await timeout(400)
      await callCenterApi.closeTask({ call_id: params.call_id })
      !!callBack && callBack()
    } catch (e) {
      console.log(JSON.stringify(e))
    } finally {
      setLoading(false)
      closeModal()
    }
  }

  return (
    <div
      className={cn(styles.Container)}
      style={{ opacity: loading ? 0.7 : 1 }}>
      <div className={cn(styles.IconContainer)}>
        <IconAlertCircle size={30} />
      </div>
      <h2>Не дозвонились несколько раз</h2>
      {params?.message && <p>{params.message}</p>}
      <div className={cn(styles.Controls)}>
        <div
          onClick={loading ? undefined : handleDeclineModal}
          className={cn(styles.Button, styles.ButtonAccent)}>
          <div>Завершить попытку</div>
          {loading && (
            <div>
              <LoadingSpinner
                size={16}
                style={{ marginBottom: -4 }}
                color={"#fff"}
              />
            </div>
          )}
        </div>
        <div
          onClick={loading ? undefined : handleSubmitModal}
          className={cn(styles.Button)}>
          <div>Отправить на перезвон</div>
        </div>
      </div>
      <div
        className={cn(styles.OnReset)}
        onClick={loading ? undefined : handleResetModal}>
        Завершить и уйти на перерыв
      </div>
    </div>
  )
}

NotResponseContent.displayName = "NotResponse"

export default NotResponseContent
