import styles from "./index.module.css"
import cn from "classnames"
import { motion } from "framer-motion"

interface AppLogoProps {
  size?: number
}

const AppLogo = ({ size }: AppLogoProps) => {
  return (
    <motion.div
      initial={{ scale: 0.8, opacity: 0 }}
      animate={{ scale: 1, opacity: 1 }}
      className={cn(styles.Logo)}
      style={{ width: size ? size : 90 }}>
      <div>Call</div>
      <div>Center</div>
    </motion.div>
  )
}

export default AppLogo
