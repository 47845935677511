import cn from "classnames"
import styles from "./index.module.css"
import { UHomeTypes } from "@/shared/api"
import { AreaAndPrice } from "@/entities/uhome/ui/ComplexRow/ui/AreaAndPrice.tsx"
import { IconInfoCircle } from "@tabler/icons-react"

interface ComplexRowProps {
  complex: UHomeTypes.ComplexObject
  onOpenMore: () => void
}

const COLUMNS_LIMIT = 5
const UHOME_COMPLEX_URL = "https://uhome.me/search/complex/"

const getYandexMapLinkByCoords = (latitude: number, longitude: number) => {
  return `https://yandex.ru/maps/213/moscow/?ll=${longitude}%2C${latitude}&mode=search&sll=${longitude}%2C${latitude}0&text=${latitude}0%2C${longitude}&z=16.54`
}

const ComplexRow = (props: ComplexRowProps) => {
  const { complex, onOpenMore } = props

  return (
    <tr className={cn(styles.Row)}>
      <td>
        <div className={cn(styles.ComplexMain)}>
          <div className={cn(styles.LogoDeveloperInner)}>
            {!!complex.developer?.logo_large && (
              <img
                src={complex.developer.logo_large}
                alt={complex.developer.name}
              />
            )}
          </div>
          <div className={cn(styles.Names)}>
            <div>
              <div className={cn(styles.CityDirection)}>
                <span>{complex.city.name}</span> • {complex.directions.map((item) => item.name).join(", ")}
              </div>
              <div>
                <a
                  href={UHOME_COMPLEX_URL + complex.id}
                  target={"_blank"}>
                  ЖК {complex.name}
                </a>
              </div>
            </div>
            <div
              onClick={onOpenMore}
              className={cn(styles.MoreInfo)}>
              <IconInfoCircle />
            </div>
          </div>
        </div>
      </td>
      {Array.from(Array(COLUMNS_LIMIT)).map((_, index) => {
        const flat = complex.flats?.[index]
        return (
          <AreaAndPrice
            key={index}
            flat={flat}
          />
        )
      })}
      <td>
        {!!complex.metro ? (
          <div className={cn(styles.Metro)}>
            <div
              className={cn(styles.MetroCircle)}
              style={{ backgroundColor: `#${complex.metro_color}` }}></div>
            <span>
              <a
                href={getYandexMapLinkByCoords(complex.latitude, complex.longitude)}
                target={"_blank"}>
                {complex.metro}
              </a>
            </span>
          </div>
        ) : (
          "-"
        )}
      </td>
    </tr>
  )
}

export default ComplexRow
